import React, { useContext, useEffect } from 'react'
import CacheReport from './redeemComponents/cacheReport'
import TagReport from './redeemComponents/tagReport'
import { PlayerStatusContext } from '../reuseComponents/playerStatusContext';
import { useAuth } from '../auth';

function RedeemCode() {
    const { isAlive, setAlive } = useContext(PlayerStatusContext);
    const { loggedInFetch } = useAuth();

    useEffect(() => {
        loggedInFetch('/deathCheck', 'report')
        .then(resp => resp.json())
        .then(resp => {
            setAlive(!resp.isDead)
        })
    },
    //eslint-disable-next-line
    [])

    return isAlive ? <CacheReport /> : <TagReport />
}

export default RedeemCode