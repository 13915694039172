import React, { useState, useEffect } from 'react';
import { withCookies } from "react-cookie";
import { Route, Switch } from "react-router-dom";
import TopBar from './reuseComponents/topbar'
import AdminBar from './reuseComponents/adminBar'
import Footer from './reuseComponents/footer'
import Home from "./home/home";
import Profile from './profile/profile';
import CASLanding from './cas/casLanding';
import BylawsPage from './bylaws/bylawsPage';
import ConstitutionPage from './constitution/constitutionPage';
import RulesPage from './rules/rulesPage';
import RulesPresentationPage from './rulesPresentation/rulesPresentationPage';
import CacheManagement from './admin/cacheManagement/cacheManagement';
import GameManagement from './admin/gameManagement/gameManagement';
import MissionManagement from './admin/eventManagement/missionManagement';
import UserManagement from './admin/userManagement/userManagement';
import ContactAdmins from './contact/contactAdmins';
import Missions from './missions/missions';
import Registration from './registration/registration';
import Scoreboard from './scoreboard/scoreboard';
import Squads from './squads/squads';
import RedeemCode from './redeemCode/redeemCode';
import CreateProfile from './profile/createProfile';
import { PlayerStatusProvider } from './reuseComponents/playerStatusContext';
import { useAdminCheck, useRegistrationCheck } from './auth';
import { GameStatusProvider } from './reuseComponents/gameStatusContext';
import PrivacyPolicy from './privacy/privacyPolicy';
import configData from "./config.json"

function App(props) {
  const [isAlive, setAlive] = useState(true)
  const [isAdmin, setAdmin] = useState(false)
  const [newUserCookie, setNewUserCookie] = useState('');
  const [isRegistered, setRegistered] = useState(false);
  const [gameStartTime, setGameStart] = useState(new Date());
  const [gameEndTime, setGameEnd] = useState(new Date());
  const [gameStatus, setGameStatus] = useState('postGame');

  const updateGameStatus = () => {
    fetch(new URL('/isItGameTime', backendBaseURL))
    .then(resp => resp.json())
    .then(resp => {
      const start = new Date(resp.startTime)
      const end = new Date(resp.endTime)
      setGameStart(start)
      setGameEnd(end)
      const current = new Date();
      if (current < start) {
        setGameStatus('preGame')
      } else if (current > end) {
        setGameStatus('postGame')
      } else {
        setGameStatus('midGame')
      }
    })
  }

  useEffect(() => {
    updateGameStatus()
  },
  //eslint-disable-next-line
  [])

  useAdminCheck(setAdmin);
  useRegistrationCheck(setRegistered);

  return (
    <GameStatusProvider value={{ gameStartTime, gameEndTime, gameStatus, updateGameStatus }}>
      <PlayerStatusProvider value={{
        isAlive,
        setAlive,
        isAdmin,
        setAdmin,
        newUserCookie,
        setNewUserCookie,
        isRegistered,
        setRegistered}}>
        <div className='containingDiv'>
            <div className='content'>
                <TopBar />
                {isAdmin &&
                  <AdminBar />
                }
                <Switch>
                    <Route path="/login/cas/:component" component={CASLanding} />
                    <Route path="/admin/game" component={GameManagement} />
                    <Route path="/admin/caches" component={CacheManagement} />
                    <Route path="/admin/missions" component={MissionManagement} />
                    <Route path="/admin/users" component={UserManagement} />
                    <Route path="/register" component={Registration} />
                    <Route path="/report" component={RedeemCode} />
                    <Route path="/contact" component={ContactAdmins} />
                    <Route path="/missions" component={Missions} />
                    <Route path="/scoreboard" component={Scoreboard} />
                    <Route path="/squads" component={Squads} />
                    <Route path="/profile/create" component={CreateProfile} />
                    <Route path="/profile" component={Profile} />
                    <Route path="/rules" component={RulesPage} />
                    <Route path="/constitution" component={ConstitutionPage} />
                    <Route path="/rulesPresentation" component={RulesPresentationPage} />
                    <Route path="/bylaws" component={BylawsPage} />
                    <Route path="/privacy" component={PrivacyPolicy} />
                    <Route path="/home" component={Home} />
                    <Route path="/" component={Home} />
                </Switch>
            </div>
            <Footer className='footer'/>
        </div>
      </PlayerStatusProvider>
    </GameStatusProvider>
  );
}

export default withCookies(App);

//API exposed on port 8000. This is the way that I can serve over HTTPS and fix CORS errors
export var backendBaseURL = configData.backend_url
export var frontendBaseURL = window.location.origin + '/'
export var recaptcha_key = '6LeJZ1QdAAAAAGaYmMLjFXsEnBowDdKZIE7Oo0TM'
