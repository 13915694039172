import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Modal, Button, Select, MenuItem, Grid, FormControl, InputLabel, Typography } from '@material-ui/core';

function getModalStyle() {
    return {
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
    };
}

const useStyles = makeStyles(theme => ({
    paper: {
        position: 'absolute',
        width: '50%',
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        textAlign: 'center',
    },
    input: {
        marginTop: '10px',
        marginBottom: '10px',
        margin: 'auto',
        width: '50%',
        maxWidth: 500,
        background: 'white'
    },
    submit: {
        marginLeft: '30px',
    },
    buttonContainer: {
        marginTop: 10,
    },
}));

function ResolutionModal({ event, isDeleting, onSubmit, onClose, onDelete, eventName }) {
    const classes = useStyles();
    const [modalStyle] = useState(getModalStyle);
    const [outcome, setOutcome] = useState('');

    const handleClose = () => {
        onClose()
    };
    
    const handleSubmit = () => {
        let data = {
            eventId: event.id,
            outcome: outcome
        }
        onSubmit(data);
    }
    
    const handleDelete = () => {
        let data = {
            eventId: event.id
        }
        onDelete(data);
    }

    return (
        <div>
            {!isDeleting && <>
                <Modal
                    open={!!event}
                    onClose={handleClose}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description">
                    <div style={modalStyle} className={classes.paper}>
                        <Typography>Resolving <strong>{eventName}</strong></Typography>
                        <FormControl className={classes.input}>
                            <InputLabel id="outcomeLabel">Mission Outcome</InputLabel>
                            <Select
                                labelId="outcomeLabel"
                                id="outcome"
                                variant="outlined"
                                value={outcome}
                                onChange={e => setOutcome(e.target.value)}
                                inputProps={{ style: { background: 'white', fontFamily: '"Roboto", sans-serif' } }}
                                placeholder="Outcome">
                                <MenuItem value="">None</MenuItem>
                                <MenuItem value="HUMANS_SUPER">Humans super win</MenuItem>
                                <MenuItem value="HUMANS">Humans</MenuItem>
                                <MenuItem value="TIE">Tie</MenuItem>
                                <MenuItem value="ZOMBIES">Zombies</MenuItem>
                                <MenuItem value="ZOMBIES_SUPER">Zombies super win</MenuItem>
                            </Select>    
                        </FormControl> 
                        <Grid container alignItems="center" className={classes.buttonContainer}>
                            <Grid item>
                                <Button variant="contained" color="default" className={classes.close} onClick={() => handleClose()}>Close</Button>
                            </Grid>
                            <Grid item>
                                <Button variant="contained" color="primary" className={classes.submit} onClick={() => handleSubmit()}>Submit</Button>
                            </Grid>
                        </Grid>
                        
                    </div>
                </Modal>
            </>}
            {isDeleting && <>
                <Modal
                    open={!!event}
                    onClose={handleClose}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description">
                    <div style={modalStyle} className={classes.paper}> 
                        <Typography>Are you sure you want to delete <strong>{eventName}</strong>?</Typography>
                        <Grid item className={classes.buttonContainer}>
                            <Button variant="contained" color="default" className={classes.close} onClick={() => handleClose()}>Cancel</Button>
                            <Button variant="contained" color="primary" className={classes.submit} onClick={() => handleDelete()}>Delete</Button>
                        </Grid>
                    </div>
                </Modal>
            </>}
        </div>
        
        
    );
}

export default ResolutionModal
