import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useAuth } from '../../../../auth';
import { useToasts } from 'react-toast-notifications';
import { Typography, TextField, Button } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { DesktopDateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import DateFnsUtils from '@date-io/date-fns';

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        textAlign: 'center',
    },
    title: {
        marginTop: 10,
        color: 'white',
    },
    input: {
        marginTop: '10px',
        marginBottom: '10px',
        margin: 'auto',
        width: '90%',
        maxWidth: 500,
        background: 'white'
    },
    date: {
        marginTop: '20px',
        marginBottom: '20px',
        marginLeft: 10,
        marginRight: 10,
    },
    save: {
        marginTop: 10,
        marginBottom: 20,
        marginLeft: 25,
        marginRight: 25,
        backgroundColor: theme.palette.success.main,
        '&:hover':{
            backgroundColor: theme.palette.success.dark,
        },
    },
    cancel: {
        marginTop: 10,
        marginBottom: 20,
        marginLeft: 25,
        marginRight: 25,
    },
}));

function MissionCreation(props) {
    const { adminFetch } = useAuth();
    const classes = useStyles();
    const { addToast } = useToasts();

    const [name, setName] = useState(props.event.name ? props.event.name : '');
    const [location, setLocation] = useState(props.event.location ? props.event.location : '');
    const [startDate, handleStartDateChange] = useState(props.event.startDate ? props.event.startDate : new Date());
    const [endDate, handleEndDateChange] = useState(props.event.endDate ? props.event.endDate : new Date());
    const [gracePeriod, setGracePeriod] = useState(props.event.gracePeriod ? props.event.gracePeriod : new Date());
    const [humanStartLocation, setHumanLoc] = useState(props.event.humanStartLocation ? props.event.humanStartLocation : '');
    const [zombieStartLocation, setZombieLoc] = useState(props.event.zombieStartLocation ? props.event.zombieStartLocation : '');
    const [deadHumansLocation, setDeadLoc] = useState(props.event.deadHumansLocation ? props.event.deadHumansLocation : '');
    const [mapLink, setMapLink] = useState(props.event.mapLink ? props.event.mapLink : '');
    const [prePointsJoke, setPreJoke] = useState(props.event.prePointsJoke ? props.event.prePointsJoke : '');
    const [postPointsJoke, setPostJoke] = useState(props.event.postPointsJoke ? props.event.postPointsJoke : '');

    const [neededAdmins, setNeededAdmins] = useState(props.event.neededAdmins ? props.event.neededAdmins : '');
    const [neededEquipment, setNeededEquip] = useState(props.event.neededEquipment ? props.event.neededEquipment : '');
    const [adminInfo, setAdminInfo] = useState(props.event.adminInfo ? props.event.adminInfo : '');
    const [adminMapLink, setAdminMap] = useState(props.event.adminMapLink ? props.event.adminMapLink : '');
    const [storyDesc, setStoryDesc] = useState(props.event.storyDesc ? props.event.storyDesc : '');
    const [boundariesDesc, setBoundDesc] = useState(props.event.boundariesDesc ? props.event.boundariesDesc : '');
    const [rulesDesc, setRulesDesc] = useState(props.event.rulesDesc ? props.event.rulesDesc : '');
    const [humanGoals, setHumanGoal] = useState(props.event.humanGoals ? props.event.humanGoals : '');
    const [zombieGoals, setZombieGoal] = useState(props.event.zombieGoals ? props.event.zombieGoals : '');
    const [winConditions, setWinConditions] = useState(props.event.winConditions ? props.event.winConditions : '');
    const [humanSuperRewards, setHumanSuperRewards] = useState(props.event.humanSuperRewards ? props.event.humanSuperRewards : '');
    const [humanRewards, setHumanRewards] = useState(props.event.humanRewards ? props.event.humanRewards : '');
    const [tieRewards, setTieRewards] = useState(props.event.tieRewards ? props.event.tieRewards : '');
    const [zombieRewards, setZombieRewards] = useState(props.event.zombieRewards ? props.event.zombieRewards : '');
    const [zombieSuperRewards, setZombieSuperRewards] = useState(props.event.zombieSuperRewards ? props.event.zombieSuperRewards : '');
    const [winningPoints, setWinningPoints] = useState(props.event.winningPoints ? props.event.winningPoints : 0);
    const [tyingPoints, setTyingPoints] = useState(props.event.tyingPoints ? props.event.tyingPoints : 0);
    const [losingPoints, setLosingPoints] = useState(props.event.losingPoints ? props.event.losingPoints : 0);
    const [humanWinMsg, setHumanWinMsg] = useState(props.event.humanWinMsg ? props.event.humanWinMsg : '');
    const [tieMsg, setTieMsg] = useState(props.event.tieMsg ? props.event.tieMsg : '');
    const [zombieWinMsg, setZombieWinMsg] = useState(props.event.zombieWinMsg ? props.event.zombieWinMsg : '');

    const submitEvent = () => {
        const data = {
            name,
            location,
            startDate: startDate.getTime(),
            endDate: endDate.getTime(),
            gracePeriod: gracePeriod.getTime(),
            humanStartLocation,
            zombieStartLocation,
            deadHumansLocation,
            mapLink,
            prePointsJoke,
            postPointsJoke,
            neededAdmins,
            neededEquipment,
            adminInfo,
            adminMapLink,
            storyDesc,
            boundariesDesc,
            rulesDesc,
            humanGoals,
            zombieGoals,
            winConditions,
            humanSuperRewards,
            humanRewards,
            tieRewards,
            zombieRewards,
            zombieSuperRewards,
            winningPoints,
            tyingPoints,
            losingPoints,
            humanWinMsg,
            tieMsg,
            zombieWinMsg,
        }
        adminFetch('/admin/events/mission-update', '/home', {}, 'POST', {updates: data, eventId: props.event.id})
        .then(resp => {
            if (resp.ok) {
                addToast('Event has been saved for the current game', { appearance: 'success', autoDismiss: 'true'})
                props.onUpdate()
            } else {
                addToast('Something Went Wrong. This event was not properly saved', { appearance: 'error', autoDismiss: 'true'})
            }
        })
    }

    return (
        <div>
            <Typography variant='h4' className={classes.title}>Mission Editor</Typography>
            <TextField className={classes.input}
                variant="outlined"
                multiline
                value={name}
                onChange={e => setName(e.target.value)}
                inputProps={{ style: { background: 'white', fontFamily: '"Roboto", sans-serif' } }}
                label="Name"/>
            <TextField className={classes.input}
                variant="outlined"
                multiline
                value={location}
                onChange={e => setLocation(e.target.value)}
                inputProps={{ style: { background: 'white', fontFamily: '"Roboto", sans-serif' } }}
                label="Location Description"/>
            <br />
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DesktopDateTimePicker label="Start Date and Time" value={startDate} className={classes.date} onChange={(newValue) => {handleStartDateChange(newValue);}} renderInput={(params) => <TextField {...params} />}/>
                <DesktopDateTimePicker label="End Date and Time" value={endDate} className={classes.date} onChange={(newValue) => {handleEndDateChange(newValue);}} renderInput={(params) => <TextField {...params} />}/>
                <DesktopDateTimePicker label="Start Grace Period" value={gracePeriod} className={classes.date} onChange={(newValue) => {setGracePeriod(newValue);}} renderInput={(params) => <TextField {...params} />}/>
            </LocalizationProvider>
            </MuiPickersUtilsProvider>
            {/* <br /> */}
            {/* <Button variant="contained" color="default" onClick={() => props.onAttendance()}>Take Attendance</Button> */}
            <br />
            <TextField className={classes.input}
                variant="outlined"
                multiline
                value={humanStartLocation}
                onChange={e => setHumanLoc(e.target.value)}
                inputProps={{ style: { background: 'white', fontFamily: '"Roboto", sans-serif' } }}
                label="Human Start Location"/>
            <TextField className={classes.input}
                variant="outlined"
                multiline
                value={zombieStartLocation}
                onChange={e => setZombieLoc(e.target.value)}
                inputProps={{ style: { background: 'white', fontFamily: '"Roboto", sans-serif' } }}
                label="Zombie Start Location"/>
            <TextField className={classes.input}
                variant="outlined"
                multiline
                value={deadHumansLocation}
                onChange={e => setDeadLoc(e.target.value)}
                inputProps={{ style: { background: 'white', fontFamily: '"Roboto", sans-serif' } }}
                label="Dead Human Location"/>
            <TextField className={classes.input}
                variant="outlined"
                multiline
                value={mapLink}
                onChange={e => setMapLink(e.target.value)}
                inputProps={{ style: { background: 'white', fontFamily: '"Roboto", sans-serif' } }}
                label="Map Link - Make sure to use embedding url"/>
            <TextField className={classes.input}
                variant="outlined"
                multiline
                value={prePointsJoke}
                onChange={e => setPreJoke(e.target.value)}
                inputProps={{ style: { background: 'white', fontFamily: '"Roboto", sans-serif' } }}
                label="Pre Points Joke"/>
            <TextField className={classes.input}
                variant="outlined"
                multiline
                value={postPointsJoke}
                onChange={e => setPostJoke(e.target.value)}
                inputProps={{ style: { background: 'white', fontFamily: '"Roboto", sans-serif' } }}
                label="Post Points Joke"/>


            <TextField className={classes.input}
                variant="outlined"
                multiline
                value={neededAdmins}
                onChange={e => setNeededAdmins(e.target.value)}
                inputProps={{ style: { background: 'white', fontFamily: '"Roboto", sans-serif' } }}
                label="Needed Admins"/>
            <TextField className={classes.input}
                variant="outlined"
                multiline
                value={neededEquipment}
                onChange={e => setNeededEquip(e.target.value)}
                inputProps={{ style: { background: 'white', fontFamily: '"Roboto", sans-serif' } }}
                label="Needed Equipment"/>
            <TextField className={classes.input}
                variant="outlined"
                multiline
                value={adminMapLink}
                onChange={e => setAdminMap(e.target.value)}
                inputProps={{ style: { background: 'white', fontFamily: '"Roboto", sans-serif' } }}
                label="Admin Map Link"/>
            <TextField className={classes.input}
                variant="outlined"
                multiline
                value={adminInfo}
                onChange={e => setAdminInfo(e.target.value)}
                inputProps={{ style: { background: 'white', fontFamily: '"Roboto", sans-serif' } }}
                label="Admin Information"/>
            <TextField className={classes.input}
                variant="outlined"
                multiline
                value={storyDesc}
                onChange={e => setStoryDesc(e.target.value)}
                inputProps={{ style: { background: 'white', fontFamily: '"Roboto", sans-serif' } }}
                label="Story Description"/>
            <TextField className={classes.input}
                variant="outlined"
                multiline
                value={boundariesDesc}
                onChange={e => setBoundDesc(e.target.value)}
                inputProps={{ style: { background: 'white', fontFamily: '"Roboto", sans-serif' } }}
                label="Boundaries"/>
            <TextField className={classes.input}
                variant="outlined"
                multiline
                value={rulesDesc}
                onChange={e => setRulesDesc(e.target.value)}
                inputProps={{ style: { background: 'white', fontFamily: '"Roboto", sans-serif' } }}
                label="Rules"/>
            <TextField className={classes.input}
                variant="outlined"
                multiline
                value={humanGoals}
                onChange={e => setHumanGoal(e.target.value)}
                inputProps={{ style: { background: 'white', fontFamily: '"Roboto", sans-serif' } }}
                label="Human Goals"/>
            <TextField className={classes.input}
                variant="outlined"
                multiline
                value={zombieGoals}
                onChange={e => setZombieGoal(e.target.value)}
                inputProps={{ style: { background: 'white', fontFamily: '"Roboto", sans-serif' } }}
                label="Zombie Goals"/>
            <TextField className={classes.input}
                variant="outlined"
                multiline
                value={winConditions}
                onChange={e => setWinConditions(e.target.value)}
                inputProps={{ style: { background: 'white', fontFamily: '"Roboto", sans-serif' } }}
                label="Win Conditions"/>
            <TextField className={classes.input}
                variant="outlined"
                multiline
                value={humanSuperRewards}
                onChange={e => setHumanSuperRewards(e.target.value)}
                inputProps={{ style: { background: 'white', fontFamily: '"Roboto", sans-serif' } }}
                label="Human Superwin Rewards"/>
            <TextField className={classes.input}
                variant="outlined"
                multiline
                value={humanRewards}
                onChange={e => setHumanRewards(e.target.value)}
                inputProps={{ style: { background: 'white', fontFamily: '"Roboto", sans-serif' } }}
                label="Human Rewards"/>
            <TextField className={classes.input}
                variant="outlined"
                multiline
                value={tieRewards}
                onChange={e => setTieRewards(e.target.value)}
                inputProps={{ style: { background: 'white', fontFamily: '"Roboto", sans-serif' } }}
                label="Tie Rewards"/>
            <TextField className={classes.input}
                variant="outlined"
                multiline
                value={zombieRewards}
                onChange={e => setZombieRewards(e.target.value)}
                inputProps={{ style: { background: 'white', fontFamily: '"Roboto", sans-serif' } }}
                label="Zombie Rewards"/>
            <TextField className={classes.input}
                variant="outlined"
                multiline
                value={zombieSuperRewards}
                onChange={e => setZombieSuperRewards(e.target.value)}
                inputProps={{ style: { background: 'white', fontFamily: '"Roboto", sans-serif' } }}
                label="Zombie Superwin Rewards"/>
            <TextField className={classes.input}
                variant="outlined"
                multiline
                value={winningPoints}
                onChange={e => setWinningPoints(e.target.value)}
                inputProps={{ style: { background: 'white', fontFamily: '"Roboto", sans-serif' } }}
                label="Winning Points"/>
            <TextField className={classes.input}
                variant="outlined"
                multiline
                value={tyingPoints}
                onChange={e => setTyingPoints(e.target.value)}
                inputProps={{ style: { background: 'white', fontFamily: '"Roboto", sans-serif' } }}
                label="Tying Points"/>
            <TextField className={classes.input}
                variant="outlined"
                multiline
                value={losingPoints}
                onChange={e => setLosingPoints(e.target.value)}
                inputProps={{ style: { background: 'white', fontFamily: '"Roboto", sans-serif' } }}
                label="Losing Points"/>
            <TextField className={classes.input}
                variant="outlined"
                multiline
                value={humanWinMsg}
                onChange={e => setHumanWinMsg(e.target.value)}
                inputProps={{ style: { background: 'white', fontFamily: '"Roboto", sans-serif' } }}
                label="Human Win Message"/>
            <TextField className={classes.input}
                variant="outlined"
                multiline
                value={tieMsg}
                onChange={e => setTieMsg(e.target.value)}
                inputProps={{ style: { background: 'white', fontFamily: '"Roboto", sans-serif' } }}
                label="Tie Message"/>
            <TextField className={classes.input}
                variant="outlined"
                multiline
                value={zombieWinMsg}
                onChange={e => setZombieWinMsg(e.target.value)}
                inputProps={{ style: { background: 'white', fontFamily: '"Roboto", sans-serif' } }}
                label="Zombie Win Message"/>
            <br />
            <Button variant="contained" color="secondary" className={classes.cancel} onClick={() =>{props.onClosing()}}>Back to All Events</Button>
            <Button variant="contained" className={classes.save} onClick={() =>{submitEvent()}}>Save Changes</Button>
        </div>
    );
}

export default MissionCreation