import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Grid, Card } from '@material-ui/core';
import WarningIcon from '@material-ui/icons/Warning';

const useStyles = makeStyles(theme => ({
    notification: {
        textAlign: 'left',
        width: '90%',
        margin: 'auto',
        background: 'white',
        marginBottom: '20px',
    },
    iconGrid: {
        textAlign: 'center',
    },
    icon: {
        paddingTop: '5px',
        fontSize: '60px',
    },
    heading: {
        paddingTop: '5px',
        paddingLeft: '10px',
    },
    content: {
        paddingLeft: '10px',
        lineHeight: 'normal',
    },
    date: {
        color: 'grey',
        textAlign: 'right',
        marginRight: '20px',
    }
}));

function NotificationCard(props) {
    const classes = useStyles();
    return (
        <Card className={classes.notification}>
            <Grid container spacing={0}>
                <Grid item xs={2} className={classes.iconGrid}>
                    <WarningIcon className={classes.icon}/>
                </Grid>
                <Grid item xs={10}>
                    <Typography variant='body1' className={classes.heading}><strong>{props.title}</strong></Typography>
                    <Typography variant='body1' className={classes.content}>{props.content}</Typography>
                    <Typography variant='body1' className={classes.date}>{props.date}</Typography>
                </Grid>
            </Grid>
        </Card>
    );

}

export default NotificationCard;