import React from 'react';
import { Grid, Accordion, AccordionDetails, AccordionSummary, Typography, Button, Select, MenuItem, FormControl, InputLabel } from '@material-ui/core';
import WarningIcon from '@material-ui/icons/Warning';
import { KeyboardArrowDown } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import { useAuth } from '../../../auth';
import { useToasts } from 'react-toast-notifications';

const useStyles = makeStyles(theme => ({
    summary: {
        'flex-direction': 'row-reverse'
    }
}));

function UserEntry({user, squads, onUpdate, createInfraction, confirmRemoval, ozsAssigned, selectOZ, deselectOZ}) {
    const classes = useStyles();
    const { adminFetch } = useAuth();
    const { addToast } = useToasts();

    const handleDeleteNickname = () => {
        adminFetch('/admin/players/remove-nickname', '/home', {}, 'POST', { userId: user.userId })
        .then(resp => {
            if (resp.ok) {
                addToast('Player nickname deleted', { appearance: 'success', autoDismiss: true })
                onUpdate()
            } else {
                addToast('Error while deleting nickname', { appearance: 'error', autoDismiss: true })
            }
        })
    }


    const handleWaiver = () => {
        //return () => {
            adminFetch('/admin/players/waiver', '/home', {}, 'POST', { playerId: user.playerId })
            .then(resp => {
                if (resp.ok) {
                    addToast('Waiver submission recorded.', { appearance: 'success', autoDismiss: true })
                    onUpdate()
                } else {
                    addToast('Unable to record waiver submission.', { appearance: 'error', autoDismiss: true })
                }
            })
        //}
    }

    const handleRulesQuiz = () => {
        //return () => {
            adminFetch('/admin/players/rules-quiz', '/home', {}, 'POST', { playerId: user.playerId })
            .then(resp => {
                if (resp.ok) {
                    addToast('Rules quiz recorded.', { appearance: 'success', autoDismiss: true })
                    onUpdate()
                } else {
                    addToast('Unable to record rules quiz.', { appearance: 'error', autoDismiss: true })
                }
            })
        //}
    }

    const handleRemoveFromSquad = () => {
        //return () => {
            adminFetch('/admin/players/squad-leave', '/home', {}, 'POST', { playerId: user.playerId })
            .then(resp => {
                if (resp.ok) {
                    addToast('Player removed from squad', { appearance: 'success', autoDismiss: true })
                    onUpdate()
                } else {
                    addToast('Unable to remove player from squad.', { appearance: 'error', autoDismiss: true })
                }
            })
        //}
    }

    const handleJoinSquad = (event) => {
        adminFetch('/admin/players/squad-join', '/home', {}, 'POST', { playerId: user.playerId, squadId: event.target.value })
        .then(resp => {
            if (resp.ok) {
                addToast('Player added to squad.', { appearance: 'success', autoDismiss: true })
                onUpdate()
            } else {
                addToast('Unable to add player to squad.', { appearance: 'error', autoDismiss: true })
            }
        })
    }

    const handlePromote = () => {
        //return () => {
            adminFetch('/admin/players/make-admin', '/home', {}, 'POST', { userId: user.userId })
            .then(resp => {
                if (resp.ok) {
                    addToast('User promoted successfully.', { appearance: 'success', autoDismiss: true })
                    onUpdate()
                } else {
                    addToast('Promotion failed. They\'re bad luck, better not add them.', { appearance: 'error', autoDismiss: true })
                }
            })
        //}
    }

    const handleDemote = () => {
       // return () => {
	  adminFetch('/admin/players/remove-admin', '/home', {}, 'POST', { userId: user.userId })
            .then(resp => {
                if (resp.ok) {
                    addToast('User demoted successfully.', { appearance: 'success', autoDismiss: true })
                    onUpdate()
                } else {
                    addToast('Demotion failed. The website has decided they get to stay.', { appearance: 'error', autoDismiss: true })
                }
            })
	//}
    }

    const handleProposeOZ = (event) => {
        event.stopPropagation();
        selectOZ(user);
    }

    const handleRemoveOZ = (event) => {
        event.stopPropagation();
        deselectOZ(user);
    }

    const handleGiveInfraction = () => {
        createInfraction(user);
    }

    const handleRemovePlayer = () => {
        confirmRemoval(user);
    }

    const handleForgiveCheckIns = () => {
        adminFetch('/admin/players/checkin-forgive', '/home', {}, 'POST', { 'playerId': user.playerId })
        .then(resp => {
            if (resp.ok) {
                addToast('Missing check-ins forgiven', { appearance: 'success', autoDismiss: true })
                onUpdate()
            } else {
                addToast('Error attempting to resolve check-ins', { appearance: 'error', autoDismiss: true })
            }
        })
    }

    const handlePunishCheckIns = () => {
        adminFetch('/admin/players/checkin-punish', '/home', {}, 'POST', { 'userId': user.userId })
        .then(resp => {
            if (resp.ok) {
                addToast('Warning message sent to player', { appearance: 'success', autoDismiss: true })
                onUpdate()
            } else {
                addToast('Error attempting to resolve check-ins', { appearance: 'error', autoDismiss: true })
            }
        })
    }

    const needsAttention = user => !(user.isRegistered && user.hasWaiver && !user.missedCheckIns.length && user.hasRulesAcknowledgement)

    function MakeSummary() {
        return (
            <React.Fragment>
                <AccordionSummary className={classes.summary} expandIcon={<KeyboardArrowDown />} IconButtonProps={{edge: 'start'}}>
                    {!!needsAttention(user) &&
                        <WarningIcon />
                    }
                    <Grid container>
                        <Grid container item xs>
                            <Grid item xs={12}>
                                <Typography>
                                    Name:
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography>
                                    {user.name}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid container item xs>
                            <Grid item xs={12}>
                                <Typography>
                                    Case ID:
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography>
                                    {user.caseId}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid container item xs>
                            <Grid item xs={12}>
                                <Typography>
                                    Tag Code:
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography>
                                    {user.tagCode}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid container item xs>
                            <Grid item xs={12}>
                                <Typography>
                                    Affiliation:
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography>
                                    {user.affiliation}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid container item xs>
                            <Grid item xs={12}>
                                <Typography>
                                    Wants OZ?:
                                </Typography>
                            </Grid>
                            {!!(user.wantsOz && !ozsAssigned) &&
                            <>
                                <Grid item xs={6}>
                                    <Button disabled={user.isOz} variant='contained' onClick={handleProposeOZ} onFocus={(e) => e.stopPropagation()}>
                                        Consider for OZ
                                    </Button>
                                </Grid>
                                <Grid item xs={6}>
                                    <Button disabled={!user.isOz} variant='contained' onClick={handleRemoveOZ} onFocus={(e) => e.stopPropagation()}>
                                        Remove for OZ
                                    </Button>
                                </Grid>
                            </>
                            }
                            {!(user.wantsOz && !ozsAssigned) &&
                                <Grid item xs={12}>
                                    <Typography>
                                        {user.wantsOz ? "Yes" : "No"}
                                    </Typography>
                                </Grid>
                            }
                        </Grid>
                    </Grid>
                </AccordionSummary>
            </React.Fragment>
        )
    }

    function MakeInfractions({infractions}) {
        return (
            <React.Fragment>
                { infractions.map((infraction, i) => {
                    var header = `Infraction #${infractions.length - i}`
                    var lines = infraction.split("\n")
                    return (
                        <div key={i}>
                            <Grid item xs={12}>
                                <Typography>
                                    {header}
                                </Typography>
                            </Grid>
                            <Grid container item xs={12}>
                                <Grid item xs={6}>
                                    <Typography>
                                        {lines[1]}
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography>
                                        {lines[0]}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography>
                                    {lines[2]}
                                </Typography>
                            </Grid>
                        </div>
                    )}
                )}
            </React.Fragment>
        )
    }

    function MakeDetails() {
        return (
            <React.Fragment>
                <AccordionDetails>
                    <Grid container>
                        <Grid container item xs={6}>
                            <Grid container item xs={12}>
                                {!!user.name.includes("\"") &&
                                    <Grid item xs={12}>
                                        <Button variant='contained' onClick={handleDeleteNickname}>Remove player nickname</Button>
                                    </Grid>
                                }
                                <Grid item xs={12}>
                                    <Typography>
                                        Emergency Contact Info:
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography>
                                        Name:
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography>
                                        {user.contactName}
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography>
                                        Relationship:
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography>
                                        {user.contactRelationship}
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography>
                                        Phone #:
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography>
                                        {user.contactPhone}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container item xs={12}>
                                <Grid item xs={12}>
                                    Player Status:
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography>
                                        Registration:
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography>
                                        {user.isRegistered ? "Completed" : "Incomplete"}
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography>
                                        Waiver:
                                    </Typography>
                                </Grid>
                                {!!user.hasWaiver &&
                                    <Grid item xs={6}>
                                        <Typography>
                                            Completed
                                        </Typography>
                                    </Grid>
                                }
                                {!user.hasWaiver &&
                                <>
                                    <Grid item xs={3}>
                                        <Typography>
                                            Incomplete
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Button variant='contained' onClick={handleWaiver}>
                                            Record Waiver
                                        </Button>
                                    </Grid>
                                </>
                                }
                                <Grid item xs={6}>
                                    <Typography>
                                        Rules Credit:
                                    </Typography>
                                </Grid>
                                {!!user.hasRulesAcknowledgement &&
                                    <Grid item xs={6}>
                                        <Typography>
                                            Completed
                                        </Typography>
                                    </Grid>
                                }
                                {!user.hasRulesAcknowledgement &&
                                <>
                                    <Grid item xs={3}>
                                        <Typography>
                                            Incomplete
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Button variant='contained' onClick={handleRulesQuiz}>
                                            Record Rules Quiz
                                        </Button>
                                    </Grid>
                                </>
                                }
                                <Grid item xs={6}>
                                    <Typography>
                                        Off-Campus Check Ins:
                                    </Typography>
                                </Grid>
                                {!!user.missedCheckIns.length &&
                                    <Grid container item xs={6}>
                                        <Grid item xs={12}>
                                            <Typography>Missed Days: </Typography>
                                            {user.missedCheckIns.map((date, i) => {
                                                return (
                                                    <Typography key={i}>{" " + new Date(date).toLocaleDateString()}</Typography>
                                                )
                                            }
                                            )}
                                        </Grid>
                                        <Grid item xs={6}>
                                        <Button variant='contained' onClick={handlePunishCheckIns}>Send Warning</Button>
                                        </Grid>
                                        <Grid item xs={6}>
                                        <Button variant='contained' onClick={handleForgiveCheckIns}>Forgive</Button>
                                        </Grid>
                                    </Grid>
                                }
                                {!user.missedCheckIns.length &&
                                    <Grid item xs={6}>
                                        <Typography>
                                            OK
                                        </Typography>
                                    </Grid>
                                }
                                <Grid item xs={6}>
                                    <Typography>
                                        Squad:
                                    </Typography>
                                </Grid>
                                {!!user.squadId && <>
                                    <Grid item xs={3}>
                                        {squads.length ? squads.filter(squad => squad.id === user.squadId)[0].name : "Loading"}
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Button variant='contained' onClick={handleRemoveFromSquad}>
                                            Remove From Squad
                                        </Button>
                                    </Grid>
                                </>}
                                {!user.squadId &&
                                    <Grid item xs={6}>
                                        <FormControl>
                                            <InputLabel>Assign To Squad</InputLabel>
                                            <Select value="" onChange={handleJoinSquad}>
                                                <MenuItem value="">
                                                    Assign to Squad
                                                </MenuItem>
                                                {squads.map((squad, i) => {
                                                    return (
                                                        <MenuItem key={i} value={squad.id}>
                                                            {squad.name}
                                                        </MenuItem>
                                                    )
                                                }
                                                )}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                }
                            </Grid>
                        </Grid>
                        <Grid container item xs={6}>
                            <Grid container item xs={12}>
                                <Grid item xs={12}>
                                    Infractions
                                </Grid>
                                <Grid item xs={12}>
                                    <MakeInfractions infractions={user.infractions} />
                                </Grid>
                                <Grid item xs={6}>
                                    <Button variant='contained' onClick={handleGiveInfraction}>
                                        Add New Infraction
                                    </Button>
                                </Grid>
                                <Grid item xs={6}>
                                    <Button variant='contained' onClick={handleRemovePlayer}>
                                        Remove From Game
                                    </Button>
                                </Grid>
                                <Grid item xs={12}>
                                    Admin Switch
                                </Grid>
                                <Grid item xs={6}>
                                    <Button disabled={user.isAdmin}variant='contained' onClick={handlePromote}>
                                        Make Player Admin
                                    </Button>
                                </Grid>
                                <Grid item xs={6}>
                                    <Button disabled={!user.isAdmin}variant='contained' onClick={handleDemote}>
                                        Demote Admin
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </AccordionDetails>
            </React.Fragment>
        )
    }

    return (
        <Accordion>
            <MakeSummary />
            <MakeDetails />
        </Accordion>
    )
}

export default UserEntry
