import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useToasts } from 'react-toast-notifications';
import { Button, TextField, Typography, Grid } from '@material-ui/core';
import { useAuth } from '../../../auth';
import PlayerCard from '../../../reuseComponents/personCard.js';

const useStyles = makeStyles(theme => ({
    root: {
        textAlign: 'center',
    },
    input: {
        marginTop: '10px',
        marginBottom: '10px',
        margin: 'auto',
        width: '50%',
        maxWidth: 500,
        background: 'white'
    },
    title: {
        color: 'white',
    },
    submit: {
        marginTop: '20px',
        marginLeft: '10px',
    },
    close: {
        marginTop: '10px',
    },
    boxTitle: {
        padding: '20px',
        color: 'white',
    },
    pannel: {
        width: '95%',
        marginTop: '10px',
        margin: 'auto',
        background: theme.palette.primary.main,
        textAlign: 'center',
        overflow: 'auto'
    },
    removeAttendancePannel: {
        textAlign: 'center',
        width: '95%',
        marginTop: '10px',
        background: theme.palette.secondary.main,
        overflow: 'auto',
        padding: '10px'
    },
}));

function Attendance({ event, onComplete}) {
    const classes = useStyles();
    const { adminFetch } = useAuth();
    const { addToast } = useToasts();
    const [humans, setHumans] = useState([]);
    const [zombies, setZombies] = useState([]);
    const [players, setPlayers] = useState([]);
    const [playerId, setPlayerId] = useState('');
    const [removePlayerId, setRemovePlayerId] = useState('');

    useEffect(() => {
        adminFetch('/admin/events/get-attendance', '/home', {}, 'POST', { eventId: event.id })
        .then(attResp => attResp.json())
        .then(attResp => {
            if (attResp.humanList) {
                setHumans(attResp.humanList)
            } else {setHumans([]) }
            if (attResp.zombieList) {
                setZombies(attResp.zombieList)
            } else { setZombies([]) }
            if (attResp.playerList) {
                setPlayers(attResp.playerList)
            } else { setPlayers([]) }
        })
    },
    //eslint-disable-next-line
    [])
    
    const submitName = (isPlayerBeingAdded) => {
        if (playerId !== '' || removePlayerId !== '') {
            adminFetch('/admin/events/attendance', '/home', {}, 'POST', {
                eventId: event.id,
                playerCaseId: playerId !== '' ? playerId : removePlayerId,
                isPlayerBeingAdded: isPlayerBeingAdded,
            })
            .then(resp => resp.json())
            .then(resp => {
                if (!resp.errorMessage) {
                    if (isPlayerBeingAdded) {
                        addToast(playerId + '\'s Attendance Taken', { appearance: 'success', autoDismiss: 'true'});
                        setPlayerId('');
                    } else {
                        addToast(removePlayerId + '\'s Attendance Removed', {appearance: 'success', autoDismiss: 'ture'});
                        setRemovePlayerId('');
                    }
                    adminFetch('/admin/events/get-attendance', '/home', {}, 'POST', { eventId: event.id })
                    .then(attResp => attResp.json())
                    .then(attResp => {
                        if (attResp.humanList) {
                            setHumans(attResp.humanList)
                        } else {setHumans([]) }
                        if (attResp.zombieList) {
                            setZombies(attResp.zombieList)
                        } else { setZombies([]) }
                        if (attResp.playerList) {
                            setPlayers(attResp.playerList)
                        } else { setPlayers([]) }
                    })
                } else {
                    addToast(resp.errorMessage, { appearance: 'error', autoDismiss: 'true'});
                }
            })
        }
    }

    return (
        <div>
            {!event.resolved && <>
                <div className={classes.root}>
                    <Typography variant='h3' className={classes.title}>Take Attendance for {event.name}</Typography>
                    <TextField className={classes.input}
                        variant="outlined"
                        value={playerId}
                        onChange={e => setPlayerId(e.target.value)}
                        inputProps={{ style: { background: 'white', fontFamily: '"Roboto", sans-serif' } }}
                        onKeyPress={(e) => {
                            if (e.key === "Enter") {
                                submitName(true)
                            }
                        }}
                        placeholder="Enter Case Id"/>
                    <Button variant="contained" color="primary" className={classes.submit} onClick={() => submitName(true)}>Submit</Button>
                </div>
            </>}
            <div className={classes.root}>
                <Button variant="contained" color="default" className={classes.close} onClick={() => onComplete(false)}>Back</Button> 
            </div>
            <div>
                {event.category===0 &&
                    <Grid className={classes.pannel}>
                        {!players.length &&
                            <Typography variant='h4' className={classes.boxTitle}>No Attendees{event.resolved ? "" : " Yet"}</Typography>
                        }
                        {!!players.length &&
                            <Grid className={classes.pannel}>
                                <Typography variant='h4' className={classes.boxTitle}>Attending Players</Typography>
                                <Grid container>
                                    { players.map(function(attendee, index) {
                                        return (
                                            <Grid item key={index} xs={12} sm={6} md={4} lg={3}>
                                                <PlayerCard person={attendee}/>
                                            </Grid>
                                    )})}
                                </Grid>
                            </Grid>
                        }
                    </Grid>
                }
                {(event.category===1 || event.category===2) &&
                    <Grid className={classes.pannel}>
                        {!humans.length &&
                            <Grid item xs={12} className={classes.pannel}>
                                <Typography variant='h4' className={classes.boxTitle}>No Humans{event.resolved ? "" : " Yet"}</Typography>
                            </Grid>
                        }
                        {!!humans.length &&
                            <Grid item xs={12} className={classes.pannel}>
                                <Typography variant='h4' className={classes.boxTitle}>Attending Humans</Typography>
                                <Grid container>
                                    { humans.map(function(attendee, index) {
                                        return (
                                            <Grid item key={index} xs={12} sm={6} md={4} lg={3}>
                                                <PlayerCard person={attendee}/>
                                            </Grid>
                                    )})}
                                </Grid>
                            </Grid>
                        }
                    </Grid>
                }
                {event.category===1 &&
                    <Grid className={classes.pannel}>
                        {!zombies.length &&
                                <Typography variant='h4' className={classes.boxTitle}>No Zombies{event.resolved ? "" : " Yet"}</Typography>
                        }
                        {!!zombies.length &&
                            <Grid className={classes.pannel}>
                                <Typography variant='h4' className={classes.boxTitle}>Attending Zombies</Typography>
                                <Grid container>
                                    { zombies.map(function(attendee, index) {
                                        return (
                                            <Grid item key={index} xs={12} sm={6} md={4} lg={3}>
                                                <PlayerCard person={attendee}/>
                                            </Grid>
                                    )})}
                                </Grid>
                            </Grid>
                        }
                    </Grid>
                }
            </div>
            {!event.resolved &&<>
                <div className={classes.root}>
                    <Typography variant='h3' className={classes.title}>REMOVE Attendance for {event.name}</Typography>
                    <TextField className={classes.input}
                        variant="outlined"
                        value={removePlayerId}
                        onChange={e => setRemovePlayerId(e.target.value)}
                        inputProps={{ style: { background: 'white', fontFamily: '"Roboto", sans-serif' } }}
                        onKeyPress={(e) => {
                            if (e.key === "Enter") {
                                submitName(false)
                            }
                        }}
                        placeholder="Enter Case Id of the player to be REMOVED"/>
                    <Button variant="contained" color="primary" className={classes.submit} onClick={() => submitName(false)}>Remove Player From Event</Button>
                </div>
            </>}
        </div>
    );
}

export default Attendance
