import React, { useEffect } from 'react'
import { backendBaseURL, frontendBaseURL } from './App'
import { withCookies, useCookies } from 'react-cookie';
import { Redirect, useHistory } from 'react-router-dom';
import { useContext } from 'react';
import { PlayerStatusContext } from './reuseComponents/playerStatusContext';

export const cookieName = 'HvZ-Credentials'

export function withAuth(Component) {
    return withCookies(class AuthInjector extends React.Component {
        static contextType = PlayerStatusContext
        constructor(props) {
            super(props);
            this.state = {};
        }

        render = () => {
            const operations = {
                logout: createLogout(route => this.setState({logoutRedirect: route}), this.props.cookies.remove, this.context.setAdmin, this.context.setRegistered),
                isLoggedIn: createIsLoggedIn(this.props.allCookies),
                forceLogin: createForceLogin(url => this.setState({forceLogin: url})),
                loggedInFetch: createLoggedInFetch(url => this.setState({forceLogin: url}), this.props.allCookies),
                adminFetch: createAdminFetch(
                    url => this.setState({forceLogin: url}),
                    route => this.setState({adminRedirect: route}),
                    this.props.allCookies
                ),
            }
            if (this.state.forceLogin) {
                window.location.assign(this.state.forceLogin);
            }
            if (this.state.adminRedirect) {
                return <Redirect to={this.state.adminRedirect} />
            }
            if (this.state.logoutRedirect) {
                return <Redirect to={this.state.logoutRedirect} />
            }

            return <Component {...this.props} auth={operations}>{this.props.children}</Component>
        }
    })
}

export function useAuth() {
    const [cookies, , removeCookie] = useCookies([cookieName]);
    const history = useHistory();
    const { setAdmin, setRegistered } = useContext(PlayerStatusContext);

    const operations = {
        logout: createLogout(route => history.push(route), removeCookie, setAdmin, setRegistered),
        isLoggedIn: createIsLoggedIn(cookies),
        forceLogin: createForceLogin(url => window.location.assign(url)),
        loggedInFetch: createLoggedInFetch(url => window.location.assign(url), cookies),
        adminFetch: createAdminFetch(
            url => window.location.assign(url),
            route => history.push(route),
            cookies
        )
    }
    return operations
}

export function useAdminCheck(setAdmin) {
    const [cookies, ,] = useCookies([cookieName]);

    useEffect(() => {
        const url = prepareFetchURL('amIAnAdmin?', {})
        const options = prepareFetchOptions('GET', undefined, cookies)
        fetch(url, options)
        .then(resp => {
            if (resp.ok) {
                setAdmin(true)
            }
        })
    },
    //eslint-disable-next-line
    [])
}

export function useRegistrationCheck(setRegistration) {
    const [cookies, ,] = useCookies([cookieName]);

    useEffect(() => {
        const url = prepareFetchURL('/registrationCheck', {})
        const options = prepareFetchOptions('GET', undefined, cookies)
        fetch(url, options)
        .then(resp => {
            if (resp.ok) {
                setRegistration(true)
            }
        })
    },
    //eslint-disable-next-line
    [])
}

export function useProfileCreate(cookieValue) {
    return profileData => {
        const url = prepareFetchURL('/profile/new', {})
        let options = {method: 'POST', headers: new Headers()}
        options.headers.append(cookieName, cookieValue)
        options.headers.append('Content-Type', 'application/json')
        options.body = JSON.stringify({userInfo: profileData});
        return fetch(url, options)
    }
}

function createLogout(callback, removeCookie, setAdmin, setRegistered) {
    return () => {
        removeCookie(cookieName, {path: '/'});
        setAdmin(false);
        setRegistered(false)
        callback('/home');
    }
}

function createIsLoggedIn(cookies) {
    return () => !!cookies[cookieName]
}

function createForceLogin(callback) {
    return (componentRoute) => {
        if (componentRoute.charAt(0) === '/') {
            componentRoute = componentRoute.substring(1);
        }
        const serviceURL = encodeURIComponent(frontendBaseURL + 'login/cas/' + componentRoute);
        callback('http://login.case.edu/cas/login?service=' + serviceURL);
    }
}

function createLoggedInFetch(loginCallback, cookies) {
    return (backendRoute, onFailRoute='/home', params={}, method='GET', body) => {
        const url = prepareFetchURL(backendRoute, params);
        const options = prepareFetchOptions(method, body, cookies);
        if (onFailRoute.charAt(0) !== '/') {
            onFailRoute = '/' + onFailRoute;
        }

        return new Promise((resolve, rejct) => {
            fetch(url, options).then(resp => {
                if (resp.status === 401)  { //unauthorized
                    createForceLogin(loginCallback)(onFailRoute);
                } else {
                    resolve(resp);
                }
            })
        });
    }
}

function createAdminFetch(loginCallback, forbiddenCallback, cookies) {
    return (backendRoute, onFailRoute='home', params={}, method='GET', body) => {
        const url = prepareFetchURL(backendRoute, params);
        const options = prepareFetchOptions(method, body, cookies);
        if (onFailRoute.charAt(0) !== '/') {
            onFailRoute = '/' + onFailRoute;
        }
    
        return new Promise((resolve, reject) => {
            fetch(url, options).then(resp => {
                if (resp.status === 403)  { //forbidden
                    forbiddenCallback(onFailRoute);
                } else if (resp.status === 401) { //unauthorized
                    createForceLogin(loginCallback)(onFailRoute);
                } else {
                    resolve(resp);
                }
            })
        });
    }
}

function prepareFetchURL(backendRoute, params) {
    if (backendRoute.charAt(0) === '/') {
        backendRoute = backendRoute.substring(1);
    }
    let url = new URL(backendRoute, backendBaseURL);
    url.search = new URLSearchParams(params).toString()
    return url;
}

function prepareFetchOptions(method, body, cookies) {
    let options = {}
    options.method = method
    options.headers = new Headers();
    options.headers.append(cookieName, cookies[cookieName])
    options.headers.append('Content-Type', 'application/json')
    if (!!body) {
        options.body = JSON.stringify(body);
    }
    return options
}