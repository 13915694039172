import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import facebookLogo from '../img/appFooterFacebook.png';
import { Button } from '@material-ui/core';
import { Link } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  container: {
    marginTop: '25px',
    display: 'flex',
    backgroundColor: theme.palette.primary.light,
    height: '100px'
  },
  grid: {
    backgroundColor: theme.palette.primary.light,
  },
  gridItem: {
    margin: '15px',
  },
  icon: {
    marginLeft: '25px',
    marginTop: '10px',
    marginBottom: '10px',
    width: 50,
    height: 50,
    display:'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.palette.secondary.light,
    marginRight: theme.spacing(1),
    '&:hover': {
      backgroundColor: theme.palette.warning.light,
    },
  },
  link: {
    marginTop: '18px',
  },
}));

function Footer() {
  const classes = useStyles();

  return (
      <div className={classes.container}>
        <Grid container className={classes.grid}>
          <Grid item className={classes.gridItem}>
            <a href="https://www.facebook.com/CaseHvZ/" className={classes.icon}>
              <img src={facebookLogo} alt="Facebook" />
            </a>
          </Grid>
          <Grid item className={classes.gridItem}>
            <Button component={Link} to="/privacy" color="inherit" className={classes.link}>Privacy Policy</Button>
          </Grid>
        </Grid>
      </div>
  );
}

export default Footer