import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Box } from '@material-ui/core';
import MissionResultCard from './cards/missionResultCard';

const useStyles = makeStyles(theme => ({
    pannel: {
        width: '95%',
        marginTop: '10px',
        margin: 'auto',
        background: theme.palette.primary.main,
        textAlign: 'center',
        height: '600px',
        overflow: 'auto'
    },
    boxTitle: {
        color: 'white',
        padding: '10px',
    },
}));

function PastMissions({ summaries }) {
    const classes = useStyles();
    return (
        <Box className={classes.pannel}>
            <Typography variant='h6' className={classes.boxTitle}>Event Results</Typography>
            { !summaries.length && <>
                <br/>
                <Typography className={classes.boxTitle}>No Results Yet</Typography>
                </>
            }
            { summaries.map(function(summary, index) {
                return <MissionResultCard key={index} summary={summary} />
            })}
        </Box>
    );

}

export default PastMissions;