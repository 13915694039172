import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import Container from "@material-ui/core/Container";

const useStyles = makeStyles(theme => ({
    container: {
        textAlign:'center',
        paddingTop: '10px'
    },
    rulesPresentation: {
        height: '600px',
        width: '100%',
  },
}));

function RulesPresentation() {
    const classes = useStyles();
    return (
        <Container className={classes.container}>
            <iframe className={classes.rulesPresentation} title="HvZ Rules Presentation" src="https://docs.google.com/presentation/d/e/2PACX-1vQFovsHL-_IiVsBp_-z_GsZOMkH_3kZmGTUnC1ZamNNIv6bdIke5r8V-OBGVnAMjFIekX6EkpZiXmQK/embed?start=false&loop=false&delayms=3000"></iframe>
        </Container>
    );

}

export default RulesPresentation;
