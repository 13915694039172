import React from 'react';
import { geolocated } from 'react-geolocated';
import { Button, Typography } from '@material-ui/core';
import { useToasts } from 'react-toast-notifications';
import { useAuth } from '../../auth'

function OffCampusCheckIn({ coords, isGeolocationAvailable, isGeolocationEnabled, positionError, onUpdate }) {
    const { addToast } = useToasts();
    const { loggedInFetch } = useAuth();
    
    const onCheckIn = () => {
        const location = {
            latitude: coords.latitude,
            longitude: coords.longitude
        }
        loggedInFetch('/offCampusCheckIn', '/home', {}, 'POST', { location })
        .then(resp => resp.json())
        .then(resp => {
            if (resp.ok && resp.success) {
                addToast('Check-in successful', { appearance: 'success', autoDismiss: 'true' })
                onUpdate()
            } else {
                addToast('Check-in unsuccessful, try again', { appearance: 'error', autoDismiss: 'true' })
            }
        })
    }

    return (
        <>
            <Typography color='primary'>
                Off-Campus Check-In:
            </Typography>
            {!isGeolocationAvailable && 
            <div>
                <Typography color='primary'>
                    Location services not available in this browser.
                </Typography>
            </div>}
            {!!isGeolocationAvailable && 
            <div>
                {!isGeolocationEnabled && 
                <Typography color='primary'>
                    Location services must be enabled in your browser.
                </Typography>}
                {!!isGeolocationEnabled && 
                <Button variant="contained" color="secondary" onClick={onCheckIn} disabled={!(isGeolocationAvailable && isGeolocationEnabled)}>
                    Check in
                </Button>}
            </div>} 
        </>
    )
}

export default geolocated({
    positionOptions: {
        enableHighAccuracy: false
    },
    userDecisionTimeout: 10000
})(OffCampusCheckIn);
