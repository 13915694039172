import React from 'react';
import queryString from 'query-string';
import { Redirect } from 'react-router-dom';
import { withCookies } from 'react-cookie';
import { withToastManager } from 'react-toast-notifications';
import { backendBaseURL, frontendBaseURL } from '../App';
import { cookieName, withAuth } from '../auth';
import { PlayerStatusContext } from '../reuseComponents/playerStatusContext';

class CASLanding extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            redirect: false,
        }
        this.targetComponent = props.match.params.component;
    }

    componentDidMount = () => {
        let serviceURL = frontendBaseURL + 'login/cas/' + this.targetComponent;
        let requestUrl = new URL('login', backendBaseURL);
        requestUrl.search = new URLSearchParams({ticket: urlTicket(), service: serviceURL})
        fetch(requestUrl)
        .then(resp => {
            if (resp.status === 401) { //something went wrong
                this.props.toastManager.add('Login rejected, please try again.', { appearance: 'error', autoDismiss: 'true'})
                this.props.auth.logout();
                this.targetComponent = 'home';
                return null;
            } else if (resp.status !== 200) {
                this.props.toastManager.add('Unknown authentication error, please try again.', { appearance: 'error', autoDismiss: 'true'})
                this.props.auth.logout();
                this.targetComponent = 'home';
                return null;
            } else {
                return resp.json();
            }
        })
        .then(resp => {
            if (resp) {
                if (!resp.userExists) {
                    this.context.setNewUserCookie(resp.cookie)
                    this.targetComponent = 'profile/create'
                } else {
                    this.props.cookies.set(cookieName, resp.cookie, {path: '/'});
                }
                this.context.setAlive(resp.userIsAlive)
                this.context.setAdmin(resp.userIsAdmin)
                this.context.setRegistered(resp.userIsRegistered)
            }
            this.setState({redirect: true})
        })
    }

    render = () => {
        if (this.state.redirect) {
            return <Redirect to={'/' + this.targetComponent} />
        }

        return <div>Please wait, logging in...</div>
    }
}
CASLanding.contextType = PlayerStatusContext

function urlTicket() {
    let params = window.location.search;
    return queryString.parse(params).ticket;
}

export default withAuth(withToastManager(withCookies(CASLanding)));