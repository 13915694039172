import React, { useState, useEffect } from 'react';
import { useAuth } from '../../auth';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Grid, Button, Modal } from '@material-ui/core';
import CreateNewGame from './gameComponents/createNewGame';
import EditGame from './gameComponents/editGame';
import GamesList from './gameComponents/gamesList';

const useStyles = makeStyles(theme => ({
    container:{
        textAlign: 'center',
        marginTop: 10,
        marginBottom: 10,        
    },
    root: {
        flexGrow: 1,
        textAlign: 'center',
    },
    paper: {
        position: 'absolute',
        width: 400,
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
    title: {
        flexGrow: 1,
        color: 'white',
    },
    input: {
        marginTop: '20px',
        marginBottom: '20px',
        margin: 'auto',
        width: '90%',
        maxWidth: 500,
        background: 'white'
    },
    create: {
        marginBottom: '20px',
    },
    pannel: {
        width: '95%',
        marginTop: '10px',
        margin: 'auto',
        background: theme.palette.primary.main,
        textAlign: 'center',
        maxHeight: '650px',
        overflow: 'auto',
        paddingBottom: 5,
    },
    notification: {
        textAlign: 'center',
        width: '90%',
        margin: 'auto',
        background: 'white',
        marginTop: '15px',
        marginBottom: '15px',
        paddingBottom: 5,
    },
    heading: {
        paddingTop: '5px',
    },
    content: {
        lineHeight: 'normal',
    },
    setActive: {
        backgroundColor: theme.palette.warning.main,
        '&:hover':{
            backgroundColor: theme.palette.warning.dark,
        }
    },
    date: {
        marginTop: 15,
        marginBottom: 15, 
        marginLeft: 2,
        marginRight: 2,       
    },
}));

function GameManagement(props) {
    const classes = useStyles();
    const { adminFetch } = useAuth();
    const [games, setGames] = useState([]);
    const [currentGame, setCurrentGame] = useState(null);
    const [createModal, setCreateModal] = useState(false);

    const updateGames = () => {
        adminFetch('/admin/games', '/home', {})
        .then(resp => resp.json())
        .then(resp => {
            if (resp.currentGame) {
                resp.currentGame.startDate = new Date(resp.currentGame.startDate)
                resp.currentGame.endDate = new Date(resp.currentGame.endDate)
                setCurrentGame(resp.currentGame)
            }
            setGames(resp.gameList.reverse().map(game => {
                game.startDate = new Date(game.startDate)
                game.endDate = new Date(game.endDate)
                return game;
            }))
        })
    }

    useEffect(() => {
        updateGames();
    },
    //eslint-disable-next-line
    [])

    const handleModalClose = () => {
        updateGames();
        setCreateModal(false);
    };

    return(
    <div className={classes.container}>
        <Button variant="contained" color="primary" onClick={() => setCreateModal(true)}>Create New Game</Button>
        <Grid container className={classes.root}>
            <Grid item xs={12} sm={6} md={8}>
                <Typography variant="h4" className={classes.title}>Current Game Settings</Typography>
                {!!currentGame &&
                    <EditGame game={currentGame} key={currentGame.id} onSuccess={updateGames}/>
                }
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
                <Typography variant="h4" className={classes.title}>All Games</Typography>
                <GamesList games={games} onSuccess={updateGames} />
            </Grid>
            <Modal
                open={createModal}
                onClose={handleModalClose}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description">
                <div>
                    <CreateNewGame callback={handleModalClose} />
                </div>
            </Modal>
        </Grid>
    </div>);
}

export default GameManagement