import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Modal, Typography, TextField, Button } from '@material-ui/core';
import { useToasts } from 'react-toast-notifications';
import { useAuth } from '../../auth';

function getModalStyle() {
    return {
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
    };
}

const useStyles = makeStyles(theme => ({
    paper: {
        position: 'absolute',
        width: '75%',
        maxWidth: 600,
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        textAlign: 'center',
    },
    input: {
        marginTop: '10px',
        marginBottom: '10px',
        margin: 'auto',
        width: '90%',
        maxWidth: 500,
        background: 'white'
    },
}))

function CreateSquadModal({ visible, onClose, onUpdate }) {
    const classes = useStyles();
    const [modalStyle] = useState(getModalStyle);
    const [name, setName] = useState('');
    const { loggedInFetch } = useAuth();
    const { addToast } = useToasts();

    const onCreate = () => {
        loggedInFetch('/squads/new', '/squads', {}, 'POST', {squadName: name})
        .then(resp => {
            if (resp.ok) {
                onUpdate();
                onClose();
                addToast('Successfully created ' + name + '!', {appearance: 'success', autoDismiss: true})
            } else {
                addToast('Unable to create a squad', {appearance: 'error', autoDismiss: true});
            }
        })
    }

    return (
        <Modal open={visible} onClose={onClose}>
            <div style={modalStyle} className={classes.paper}>
                <Typography variant='h3'>Create a new squad</Typography>
                <TextField
                    className={classes.input}
                    variant="outlined"
                    required
                    value={name}
                    error={!name}
                    onChange={e => setName(e.target.value)}
                    inputProps={{ style: { background: 'white', fontFamily: '"Roboto", sans-serif' } }}
                    label="Squad Name"/>
                <Button variant='contained' color="secondary" disabled={!name} onClick={onCreate}>Create</Button>
            </div>
        </Modal>
    )
}

export default CreateSquadModal