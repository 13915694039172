import React, { useState } from 'react';
import { makeStyles, Typography, TextField, Button } from '@material-ui/core';

function getModalStyle() {
    return {
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
    };
}

const useStyles = makeStyles(theme => ({
    paper: {
        position: 'absolute',
        minWidth: 600,
        maxWidth: '50%',
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
    input: {
        marginTop: '20px',
        marginBottom: '20px',
        margin: 'auto',
        width: '90%',
        maxWidth: 500,
        background: 'white'
    },
}))

function ConfirmRemoval({ user, callback, toRemove }) {
    const [modalStyle] = useState(getModalStyle);
    const classes = useStyles();
    const [name, setName] = useState('');

    const onRemove = () => {
        toRemove(user.playerId);
        callback();
    }

    return (
        <div style={modalStyle} className={classes.paper}>
            <Typography variant="h4">Remove {user.name}</Typography>
            <Typography>
                You are about to remove this user from the game.
                This action cannot be reversed.
                To ensure this is the user you wish to permanently remove them from the game,
                type their name, including the nickname and pronouns, into the box below before clicking submit.
            </Typography>
            <TextField
                className={classes.input}
                variant="outlined"
                value={name}
                onChange={e => setName(e.target.value)}
                inputProps={{ style: { background: 'white', fontFamily: '"Roboto", sans-serif' } }}
                placeholder="Player Name"/>
            <Button variant="contained" disabled={name !== user.name} onClick={onRemove} color="primary">Remove</Button>
        </div>
    )
}

export default ConfirmRemoval