import React, { useEffect } from 'react'
import { Typography, Box, Button, TextField, Grid, InputAdornment, Tooltip } from '@material-ui/core'
import InfoIcon from '@material-ui/icons/Info';
import { makeStyles } from '@material-ui/core/styles';
import { useState } from 'react';
import { useToasts } from 'react-toast-notifications';
import ReCAPTCHA from "react-google-recaptcha";
import { backendBaseURL, recaptcha_key } from '../App';

const useStyles = makeStyles(theme => ({
    inputContainer: {
        background: theme.palette.primary.main,
        width: '60%',
        minWidth: '500px',
        margin: 'auto',
        textAlign: 'center'
    },
    description: {
        paddingTop: '20px',
        color: 'white',
        margin: 'auto',
        width: '90%',
        paddingBottom: '25px',
    },
    submit: {
        marginBottom: '25px',
        fontSize: '20px',
        width: '50%',
        maxWidth: 300,
    },
    field: {
        background: '#FFFFFF',
        borderRadius: 5,
        width: '50%',
        minWidth: '300px',
        maxWidth: '350px',
        margin: 'auto',
        textAlign: 'center',
        marginBottom: '20px',
    }
}));

function EmailBox(props) {
    const classes = useStyles();
    const [playerName, setName] = useState('');
    const [captchaToken, setCaptchaToken] = useState('');
    const [feedbackText, setMessage] = useState('');

    const { addToast } = useToasts();

    // const recaptchaRef = React.createRef();

    const allFormsValid = () => {
        return playerName.length <= 30 && feedbackText.length <= 1000 && feedbackText.length > 0;
    }
    const [disableButton, setDisableButton] = useState(allFormsValid);
    //eslint-disable-next-line
    useEffect(() => setDisableButton(!allFormsValid()), [allFormsValid()])

    //Recaptcha Checking
    function captchaTokenGen(value) {
        setCaptchaToken(value)
    }

    const onSubmit = () => {
        if (captchaToken) {
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ playerName: playerName, feedbackText: feedbackText })
            };
            var requestURL = backendBaseURL + '/contact-admins/submit';

            fetch(requestURL, requestOptions)
                .then(resp => {
                    if (resp.ok) {
                        addToast('Message sent successfully!', { appearance: 'success', autoDismiss: 'true' })
                        setMessage('');
                    } else {
                        addToast('There was an error sending your message', { appearance: 'error', autoDismiss: 'true' })
                    }
                })
        }
        else {
            addToast('There seems to be suspicious Network Activity from your device, please try again later', { appearance: 'error', autoDismiss: 'true' })
        }


    }

    const tooltipText = "You may leave this field blank if you wish to leave anonymous feedback."

    return (
        <div>
            <Box className={classes.inputContainer}>
                <Grid container direction='column' justify="center" alignItems="center">
                    <Typography variant='h6' className={classes.description}>
                        This form will submit an email to the members of Core for CWRU Humans vs Zombies.
                        Please provide a descriptive message and we will respond at our earliest convenince.
                    </Typography>
                    <TextField
                        className={classes.field}
                        label="Name"
                        value={playerName}
                        onChange={e => setName(e.target.value)}
                        error={playerName.length > 30}
                        helperText={playerName.length > 30 ? 'Name too long' : ''}
                        InputProps={{
                            endAdornment:
                                <InputAdornment className={classes.icon} position='start'>
                                    <Tooltip title={tooltipText} placement="right">
                                        <InfoIcon />
                                    </Tooltip>
                                </InputAdornment>
                        }} />
                    <TextField
                        className={classes.field}
                        label="Message"
                        multiline
                        rows={5}
                        value={feedbackText}
                        onChange={e => setMessage(e.target.value)}
                        error={feedbackText.length > 1000}
                        helperText={feedbackText.length > 1000 ? 'Message too long' : ''} />

                    <ReCAPTCHA sitekey={recaptcha_key} onChange={captchaTokenGen} />

                    <Button
                        className={classes.submit}
                        variant='contained'
                        color='secondary'
                        disabled={disableButton}
                        onClick={onSubmit}>
                        Submit
                    </Button>
                </Grid>

            </Box>
        </div>

    );
}

export default EmailBox