import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useToasts } from 'react-toast-notifications';
import { Modal, FormControl, InputLabel, Select, MenuItem, Button, TextField } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { DesktopDateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import DateFnsUtils from '@date-io/date-fns';
import { useAuth } from '../../../auth';

function getModalStyle() {
    return {
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
    };
}

const useStyles = makeStyles(theme => ({
    paper: {
        position: 'absolute',
        width: '75%',
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        textAlign: 'center',
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 200,
    },
    title: {
        flexGrow: 1,
        color: 'white',
    },
    input: {
        marginTop: '10px',
        marginBottom: '10px',
        margin: 'auto',
        width: '90%',
        maxWidth: 500,
        background: 'white'
    },
    submit: {
        marginTop: '10px',
    },
    date: {
        marginLeft: 10,
        marginRight: 10,
    }
}));

function EventCreationModal({ visible, onClosing}) {
    const classes = useStyles();
    const { addToast } = useToasts();
    const { adminFetch } = useAuth();
    const [modalStyle] = useState(getModalStyle);
    const [eventType, setType] = useState('(Select Event Type)');
    const [name, setName] = useState('');
    const [startDate, handleStartDateChange] = useState(new Date());
    const [endDate, handleEndDateChange] = useState(new Date());

    const allFieldsValid = () => {
        return !!name && eventType !== '(Select Event Type)';
    }
    const [disableButton, setDisableButton] = useState(allFieldsValid())
    //eslint-disable-next-line
    useEffect(() => setDisableButton(!allFieldsValid()), [allFieldsValid()])
    
    const handleChange = (event) => {
        setType(event.target.value);
    };

    const handleClose = () => {
        onClosing(false);
    };
    
    const submitEvent = () => {
        adminFetch('/admin/events/new', '/home', {}, 'POST', {
            name,
            eventType,
            startDate: startDate.getTime(),
            endDate: endDate.getTime()
        })
        .then(resp => {
            if (resp.ok) {
                addToast('Event has been saved for the current game', { appearance: 'success', autoDismiss: 'true'});
                handleClose();
            } else {
                addToast('Something Went Wrong. This event was not properly saved', { appearance: 'error', autoDismiss: 'true'});
            }
        })
    }

    return (
        <Modal
            open={visible}
            onClose={handleClose}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description">
            <div style={modalStyle} className={classes.paper}>   
                <FormControl variant="filled" className={classes.formControl}>
                    <InputLabel id="demo-simple-select-outlined-label">Event Type</InputLabel>
                    <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        inputProps={{ style: { background: 'white', fontFamily: '"Roboto", sans-serif' } }}
                        value={eventType}
                        onChange={handleChange}>
                        <MenuItem value="(Select Event Type)">(Select Event Type)</MenuItem>
                        <MenuItem value="RULES_MEETING">Rules Meeting</MenuItem>
                        <MenuItem value="MISSION">Mission</MenuItem>
                        <MenuItem value="QUEST">Quest</MenuItem>
                    </Select>
                </FormControl>
                <br />
                <TextField className={classes.input}
                    variant="outlined"
                    required
                    value={name}
                    error={!name}
                    onChange={e => setName(e.target.value)}
                    inputProps={{ style: { background: 'white', fontFamily: '"Roboto", sans-serif' } }}
                    placeholder="Event Name"/>
                <br />
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DesktopDateTimePicker label="Start Date and Time" value={startDate} className={classes.date} onChange={(newValue) => {handleStartDateChange(newValue);}} renderInput={(params) => <TextField {...params} />}/>
                        <DesktopDateTimePicker label="End Date and Time" value={endDate} className={classes.date} onChange={(newValue) => {handleEndDateChange(newValue);}} renderInput={(params) => <TextField {...params} />}/>
                    </LocalizationProvider>
                </MuiPickersUtilsProvider>
                <br />
                <Button disabled={disableButton} variant="contained" color="primary" className={classes.submit} onClick={() => submitEvent()}>Create Event</Button>
            </div>
        </Modal>
    );
}

export default EventCreationModal