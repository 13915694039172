import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Box } from '@material-ui/core';
import NotificationCard from './notificationCard';
import { formatRelative } from 'date-fns';

const useStyles = makeStyles(theme => ({
    pannel: {
        width: '95%',
        marginTop: '20px',
        margin: 'auto',
        background: theme.palette.primary.main,
        textAlign: 'center',
        overflow: 'auto'
    },
    boxTitle: {
        color: 'white',
        padding: '10px',
    },
}));

function AlertsPannel({ title, alerts, isAlive}) {
    //alerts are either missions or tags for now
    const isMissions = alerts.length === 0 || !!alerts[0].startDate

    const classes = useStyles();
    return (
        <Box className={classes.pannel}>
            <Typography variant='h6' className={classes.boxTitle}>{title}</Typography>
            { isMissions && alerts.map((obj, index) => {
                if (obj.published) {
                    return <NotificationCard key={index} title={obj.name}
                        content={obj.name + " will be " + obj.location}
                        date={formatRelative(obj.startDate, new Date())}/>
                }
                return <></>
            })}
            { !isMissions && alerts.map((obj, index) => {
                return <NotificationCard key={index} title={obj.name + " was tagged!"}
                    content={isAlive ? obj.humanDeathMessage : obj.zombieDeathMessage}
                    date={formatRelative(obj.timeTagged, new Date())}/>
            })}
        </Box>
    );

}

export default AlertsPannel;
