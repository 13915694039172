import React, { useState } from 'react';
import { makeStyles, Typography, TextField, Button, FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import { useAuth } from '../../../auth';
import { useToasts } from 'react-toast-notifications';

function getModalStyle() {
    return {
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
    };
}

const useStyles = makeStyles(theme => ({
    paper: {
        position: 'absolute',
        minWidth: 600,
        maxWidth: '50%',
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
    input: {
        marginTop: '20px',
        marginBottom: '20px',
        margin: 'auto',
        width: '90%',
        maxWidth: 500,
        background: 'white'
    },
}))

function AddInfraction({ user, callback }) {
    const classes = useStyles();
    const { adminFetch } = useAuth();
    const [modalStyle] = useState(getModalStyle)
    const [level, setLevel] = useState(1);
    const [description, setDescription] = useState('');
    const { addToast } = useToasts();

    const onSubmit = () => {
        adminFetch('/admin/players/infraction/', '/home', {}, 'POST', {
            playerId: user.playerId,
            infractionLevel: level,
            description
        })
        .then(resp => {
            if (resp.ok) {
                addToast("Infraction added successfully", {appearance: 'success', autoDismiss: true})
                callback()
            } else {
                addToast("Failed to add infraction", {appearance: 'error', autoDismiss: true})
            }
        })
    }

    return (
        <div style={modalStyle} className={classes.paper}>
            <Typography variant="h4">Give Infraction to {user.name}</Typography>
            <FormControl className={classes.input}>
                <InputLabel id='levelLabel'>Level</InputLabel>
                <Select
                    labelId='levelLabel'
                    id='level'
                    value={level}
                    onChange={e => setLevel(e.target.value)}>
                    <MenuItem value={1}>1</MenuItem>
                    <MenuItem value={2}>2</MenuItem>
                    <MenuItem value={3}>3</MenuItem>
                </Select>
            </FormControl>
            <TextField
                className={classes.input}
                variant="outlined"
                multiline
                rows={3}
                value={description}
                onChange={e => setDescription(e.target.value)}
                inputProps={{ style: { background: 'white', fontFamily: '"Roboto", sans-serif' } }}
                placeholder="Description"/>
                <br />
                <Button variant="contained" disabled={!description} onClick={onSubmit} color="primary">Submit</Button>
        </div>
    )
}

export default AddInfraction