import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Grid, TextField, Button, Checkbox, FormControlLabel } from '@material-ui/core';


const useStyles = makeStyles(theme => ({
    textBlock: {
        color: 'white',
        width: '80%',
        marginBottom: 10,
    },
    title: {
        color: 'white',
        textAlign: 'center',
        marginBottom: 20,
        maxWidth: '80%',
    },
    field: {
        background: '#FFFFFF',
        borderRadius: 5,
        width: '50%',
        minWidth: '300px',
        maxWidth: '350px',
        margin: 'auto',
        textAlign: 'center',
        marginBottom: '20px',
    },
    submit: {
        marginBottom: '25px',
        fontSize: '20px',
        width: '50%',
        maxWidth: 300,
    },
    box: {
        color: 'white',
    },
    aggrements: {
        marginBottom: 10,
    },
    contactCheck: {
        color: 'white',
        borderRadius: 5,
        width: '50%',
        minWidth: '300px',
        maxWidth: '350px',
        margin: 'auto',
        textAlign: 'center',
        marginBottom: '20px',
    },
}));
function Waiver({ waiverInfo, onSubmit }) {
    const titleText = waiverInfo.under18 ?
        "Because you are under 18 you cannot legally complete this waiver yourself. " + 
        "When you click submit, an email with the following waiver information will be sent to your CWRU email address for you to forward to your parent or legal guardian. " +
        "Please follow the directions in that email to get a parent's signature and complete your waiver submission."
        : "Please fill out the following waiver information.";

    const [signature, setSignature] = useState('');
    const [studentId, setStudentId] = useState(waiverInfo.studentId);
    const [emergencyContactName, setContactName] = useState(waiverInfo.contactName);
    const [emergencyContactNumber, setContactNumber] = useState(waiverInfo.contactPhone);
    const [emergencyContactRelationship, setContactRelation] = useState(waiverInfo.contactRelationship);
    const [checkboxes, setCheckboxes] = useState(waiverInfo.agreementsList.map(() => waiverInfo.under18));
    const [currentContact, setCurrentContact] = useState(false);

    const studentIdInvalid = (id) => !id || !(/^[0-9]{7}$/.test(id) || /^[a-z]{3}[0-9]*$/.test(id))

    const phoneNumberInvalid = (number) => !number || (number.length > 0 && number[0] !== '+' && number.length !== 10)

    const allFormsValid = () => {
        var lowercasePlayerName = waiverInfo.playerName.toLowerCase();
        var lowercaseSignature = signature.toLowerCase();
        return (!!signature || waiverInfo.under18) && !!studentId && !!emergencyContactName && !phoneNumberInvalid(emergencyContactNumber) && !!emergencyContactRelationship
            && (lowercaseSignature === lowercasePlayerName || waiverInfo.under18) && checkboxes.every(item => item) && currentContact
            && !studentIdInvalid(studentId);
    }
    const [disableButton, setDisableButton] = useState(!allFormsValid())
    //eslint-disable-next-line
    useEffect(() => setDisableButton(!allFormsValid()), [allFormsValid()])

    const onSubmitClick = () => {
        setDisableButton(true)
        const data = {
            studentId,
            contactName: emergencyContactName,
            contactPhone: emergencyContactNumber,
            contactRelationship: emergencyContactRelationship,
        }
        if (!waiverInfo.under18) {
            data.signature = waiverInfo.playerName
        }
        onSubmit(data)
    }

    const handleCheckbox = (value, index) => {
        const copy = checkboxes.slice()
        copy[index] = value
        setCheckboxes(copy)

    }
    
    const handleStudentIdChange = (value, setter) => {
        const sisIdRe = /^[0-9\b]+$/;
        const caseIdRe = /^(?:[a-z]{0,3}|[a-z]{3}[0-9]+)\b$/
        if (value === '' || sisIdRe.test(value) || caseIdRe.test(value)) {
            setter(value)
        }
    }

    const handlePhoneNumberChange = (value, setter) => {
        const numRe = /^[0-9\b]+$/;
        const intlRe = /^\+[0-9\b]*$/;
        if (value === '' || numRe.test(value) || intlRe.test(value)) {
            setter(value)
        }
    }

    const classes = useStyles();
    return (
        <Grid container direction='column' justify="center" alignItems="center">
            <Typography variant='h4' className={classes.title}>{titleText}</Typography>
            <Typography vairant='h6' className={classes.textBlock}>{waiverInfo.participationStatement}</Typography>
            {waiverInfo.agreementsList.map((text, index) => {
                return <Grid container className={classes.aggrements} direction='row' justify="center" alignItems="center" key={index}>
                        {!waiverInfo.under18 &&
                            <Checkbox
                                className={classes.box}
                                checked={checkboxes[index]}
                                onChange={e => handleCheckbox(e.target.checked, index)}
                                color="primary"/>}
                        <Typography className={classes.textBlock}>{text}</Typography>
                    </Grid>
            })}
            <Typography vairant='h6' className={classes.textBlock}>{waiverInfo.signatureText}</Typography>
            {!waiverInfo.under18 &&
                <TextField
                    required
                    className={classes.field}
                    label="Signature (Legal First and Last name)"
                    value={signature}
                    error={!signature || signature.toLowerCase() !== waiverInfo.playerName.toLowerCase()}
                    id="signature"
                    onChange={e => setSignature(e.target.value)} 
                    variant="filled"/>
            }
            <TextField
                required
                className={classes.field}
                label="CWRU ID (abc123)"
                value={studentId}
                error={studentIdInvalid(studentId)}
                id="studentID"
                onChange={e => handleStudentIdChange(e.target.value, setStudentId)}
                variant="filled"/>
            <TextField
                required
                className={classes.field}
                label="Emergency Contact Name"
                value={emergencyContactName}
                error={!emergencyContactName}
                id="contactName"
                onChange={e => setContactName(e.target.value)} 
                variant="filled"/>
            <TextField
                required
                className={classes.field}
                label="Emergency Contact Phone Number"
                value={emergencyContactNumber}
                error={phoneNumberInvalid(emergencyContactNumber)}
                id="contactNumber"
                onChange={e => handlePhoneNumberChange(e.target.value, setContactNumber)}
                variant="filled"/>
            <TextField
                required
                className={classes.field}
                label="Emergency Contact Relationship"
                value={emergencyContactRelationship}
                error={!emergencyContactRelationship}
                id="contactRelation"
                onChange={e => setContactRelation(e.target.value)}
                variant="filled"/>
            <FormControlLabel
                className={classes.contactCheck}
                control={<Checkbox
                    className={classes.box}
                    checked={currentContact}
                    onChange={e => setCurrentContact(e.target.checked)}
                    color="primary"/>}
                label="This is my current emergency contact"/>
                
            <Button
                className={classes.submit}
                variant='contained'
                color='secondary'
                onClick={onSubmitClick}
                disabled={disableButton}>
                Submit
            </Button>
        </Grid>
    );
}

export default Waiver
