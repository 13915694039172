import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import Container from "@material-ui/core/Container";

const useStyles = makeStyles(theme => ({
    container: {
        textAlign:'center',
        paddingTop: '10px'
    },
    rules: {
        height: '600px',
        width: '100%',
  },
}));

function Rules() {
    const classes = useStyles();
    return (
        <Container className={classes.container}>
            <iframe className={classes.rules} title="Rules" src="https://docs.google.com/document/d/e/2PACX-1vQm4mMBy6wgz76ocH7wSj-WDgvfXcq1-qxNnQ7RfkfKZX6iAY5KidRDZCepd51uAv5DBamu3uOG_bFg/pub?embedded=true"></iframe>
        </Container>
    );

}

export default Rules;