import React, { useState, useEffect } from 'react';
import {
    TextField,
    Grid,
    Button,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    InputAdornment,
    Tooltip,
    Collapse
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import InfoIcon from '@material-ui/icons/Info';

const useStyles = makeStyles(theme => ({
    title: {
        paddingTop: '20px',
        paddingBottom: '5px',
        textAlign: 'center',
        color: 'white',
    },
    field: {
        background: '#FFFFFF',
        borderRadius: 5,
        width: '50%',
        minWidth: '300px',
        maxWidth: '350px',
        margin: 'auto',
        textAlign: 'center',
        marginBottom: '20px',
    },
    customPronouns: {
        background: '#FFFFFF',
        borderRadius: 5,
        width: '100%',
        margin: 'auto',
        textAlign: 'center',
        marginBottom: '20px',
    },
    collapse: {
        width: '50%',
        minWidth: '300px',
        maxWidth: '350px',
    },
    submit: {
        marginTop: '25px',
        marginBottom: '25px',
        fontSize: '20px',
        width: '50%',
        maxWidth: 300,
    },
    icon: {
        width: '45px',
    },
}));

const tooltipTextPronouns = 'If specified, pronouns will be displayed in public settings such as the leaderboard and squad rosters. If you prefer not to specify your pronouns, then leave this field as "None."'

const tooltipTextPhone = 'If entering a phone number from a country other than the US, make sure to include the country code (i.e. +1) with your number.'

const tooltipTextEmergencyPhone = tooltipTextPhone + ' Please do not use your own phone number for your emergency contact.'

const tooltipTextNickname = 'This is the thing that goes in quotes between your first name and last name. Example: Berry "BGC" Chapman - "BGC" is the nickname. This should not be your preferred first name. See the helper text below to see how your name will display.'

const tooltipTextPreferredFirstName = 'This should not be your nickname. This name replaces your legal first name. This is what the admin team will refer to you by. For example, if your legal first name is William but you go by Will, put Will in this space.'
function EditProfile({ user, onSubmit, enableSubmit }) {
    //Leaving these values undefined gives console errors which is annoying
    user.firstNamePreferred = user.firstNamePreferred || '';
    user.firstNameLegal = user.firstNameLegal || '';
    user.nickname = user.nickname || '';
    user.lastName = user.lastName || '';
    user.pronouns = user.pronouns || '';
    user.phoneNumber = user.phoneNumber || '';
    user.emergencyContactName = user.emergencyContactName || '';
    user.emergencyContactNumber = user.emergencyContactNumber || '';
    user.emergencyContactRelationship = user.emergencyContactRelationship || '';
    
    const defaultPronouns = ['he/him', 'she/her', 'they/them', '']
    const [customPronouns, setCustomPronouns] = useState(user.pronouns)
    const [usesCustomPronouns, setUsesCustomPronouns] = useState(!defaultPronouns.includes(user.pronouns))

    const phoneNumberInvalid = (number) => !number || (number.length > 0 && number[0] !== '+' && number.length !== 10)
    
    const allFormsValid = () => {
        return !!firstNameLegal && !!lastName && !!emergencyContactName
        && !phoneNumberInvalid(emergencyContactNumber) && (!usesCustomPronouns || !!customPronouns)
        && !!emergencyContactRelationship && !phoneNumberInvalid(phoneNumber) && phoneNumber !== emergencyContactNumber
        && (!enableSubmit || enableSubmit());
    }

    const noPreferredName = user.firstNamePreferred === user.firstNameLegal;
    const [firstNamePreferred, setFirstPreferred] = useState(noPreferredName ? '' : user.firstNamePreferred);
    const [firstNameLegal, setFirstLegal] = useState(user.firstNameLegal);
    const [nickname, setNick] = useState(user.nickname);
    const [lastName, setLast] = useState(user.lastName);
    const [pronouns, setPronouns] = useState(usesCustomPronouns ? 'other' : user.pronouns);
    const [phoneNumber, setPhoneNumber] = useState(user.phoneNumber)
    const [emergencyContactName, setContactName] = useState(user.emergencyContactName);
    const [emergencyContactNumber, setContactNumber] = useState(user.emergencyContactNumber);
    const [emergencyContactRelationship, setContactRelation] = useState(user.emergencyContactRelationship);
    const [disableButton, setDisableButton] = useState(!allFormsValid())
    
    //eslint-disable-next-line
    useEffect(() => setDisableButton(!allFormsValid()), [allFormsValid()])

    const onSubmitClick = () => {
        onSubmit({
            firstNamePreferred: !!firstNamePreferred ? firstNamePreferred : firstNameLegal,
            firstNameLegal,
            nickname,
            lastName,
            pronouns: usesCustomPronouns ? customPronouns : pronouns,
            phoneNumber,
            emergencyContactName,
            emergencyContactNumber,
            emergencyContactRelationship,
        })
    }

    const handlePronoun = e => {
        const value = e.target.value
        if (value === 'other') {
            setUsesCustomPronouns(true);
        } else {
            setUsesCustomPronouns(false);
        }
        setPronouns(value)
    }

    const handlePhoneNumberChange = (value, setter) => {
        const numRe = /^[0-9\b]+$/;
        const intlRe = /^\+[0-9\b]*$/;
        if (value === '' || numRe.test(value) || intlRe.test(value)) {
            setter(value)
        }
    }

    const classes = useStyles();
    return (
        <Grid container direction='column' justify="center" alignItems="center">
            <TextField
                className={classes.field}
                label="Preferred First Name"
                value={firstNamePreferred}
                id="firstNamePreferred"
                onChange={e => setFirstPreferred(e.target.value)} 
                InputProps={{
                    endAdornment:
                        <InputAdornment className={classes.icon} position='start'>
                            <Tooltip title={tooltipTextPreferredFirstName} placement="right">
                                <InfoIcon />
                            </Tooltip>
                        </InputAdornment>
                }}
                variant="filled"/>
            <TextField
                required
                className={classes.field}
                label="Legal First Name"
                value={firstNameLegal}
                error={!firstNameLegal}
                id="firstNameLegal"
                onChange={e => setFirstLegal(e.target.value)} 
                variant="filled"/>
            <TextField
                required
                className={classes.field}
                label="Last Name"
                value={lastName}
                error={!lastName}
                id="lastName"
                onChange={e => setLast(e.target.value)} 
                variant="filled"/>
            <FormControl className={classes.field}>
                <InputLabel id='pronounLabel'>Pronouns</InputLabel>
                <Select
                    labelId='pronounLabel'
                    id='pronouns'
                    value={pronouns}
                    onChange={handlePronoun}
                    endAdornment={
                        <InputAdornment className={classes.icon} position='start'>
                            <Tooltip title={tooltipTextPronouns} placement="right">
                                <InfoIcon />
                            </Tooltip>
                        </InputAdornment>
                    }
                >
                    <MenuItem value="">None</MenuItem>
                    <MenuItem value="he/him">he/him</MenuItem>
                    <MenuItem value="she/her">she/her</MenuItem>
                    <MenuItem value="they/them">they/them</MenuItem>
                    <MenuItem value="other">Other</MenuItem>
                </Select>
            </FormControl>
            <Collapse in={usesCustomPronouns} className={classes.collapse}>
                <TextField
                    required
                    className={classes.customPronouns}
                    label="Custom Pronouns"
                    value={customPronouns}
                    error={!customPronouns}
                    id="customPronouns"
                    onChange={e => setCustomPronouns(e.target.value)} 
                    variant="filled"/>
            </Collapse>
            <TextField
                className={classes.field}
                label="Nickname"
                value={nickname}
                id="nickname"
                onChange={e => setNick(e.target.value)}
                InputProps={{
                    endAdornment:
                        <InputAdornment className={classes.icon} position='start'>
                            <Tooltip title={tooltipTextNickname} placement="right">
                                <InfoIcon />
                            </Tooltip>
                        </InputAdornment>
                }}
                helperText={(!!firstNamePreferred ? firstNamePreferred : firstNameLegal) + 
                           (!!nickname ? ' "' + nickname + '" ' : ' ') +
                           lastName}
                variant="filled"/>
            <TextField
                className={classes.field}
                label="Your Phone Number"
                value={phoneNumber}
                error={phoneNumberInvalid(phoneNumber)}
                id="phoneNumber"
                onChange={e => handlePhoneNumberChange(e.target.value, setPhoneNumber)}
                InputProps={{
                    endAdornment:
                        <InputAdornment className={classes.icon} position='start'>
                            <Tooltip title={tooltipTextPhone} placement="right">
                                <InfoIcon />
                            </Tooltip>
                        </InputAdornment>
                }}
                variant="filled"/>
            <TextField
                required
                className={classes.field}
                label="Emergency Contact Name"
                value={emergencyContactName}
                error={!emergencyContactName}
                id="contactName"
                onChange={e => setContactName(e.target.value)} 
                variant="filled"/>
            <TextField
                required
                className={classes.field}
                label="Emergency Contact Phone Number"
                value={emergencyContactNumber}
                error={phoneNumberInvalid(emergencyContactNumber)}
                id="contactNumber"
                onChange={e => handlePhoneNumberChange(e.target.value, setContactNumber)}
                InputProps={{
                    endAdornment:
                        <InputAdornment className={classes.icon} position='start'>
                            <Tooltip title={tooltipTextEmergencyPhone} placement="right">
                                <InfoIcon />
                            </Tooltip>
                        </InputAdornment>
                }}
                variant="filled"/>
            <TextField
                required
                className={classes.field}
                label="Emergency Contact Relationship"
                value={emergencyContactRelationship}
                error={!emergencyContactRelationship}
                id="contactRelation"
                onChange={e => setContactRelation(e.target.value)}
                variant="filled"/>
            <Button
                className={classes.submit}
                variant='contained'
                color='secondary'
                onClick={onSubmitClick}
                disabled={disableButton}>
                Submit
                </Button>
        </Grid>
    )
}

export default EditProfile
