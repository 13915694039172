import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Typography, Box, Grid, Button } from '@material-ui/core'
import MissionCreation from './eventForms/missionEditor'
import QuestCreation from './eventForms/questEditor'
import RulesMeetingCreation from './eventForms/rulesMeetingEditor'
import MissionCard from '../../../missions/pannels/cards/missionCard'
import { useToasts } from 'react-toast-notifications'
import { useAuth } from '../../../auth'

const useStyles = makeStyles(theme => ({
    root: {
        textAlign: 'center',
    },
    input: {
        marginTop: '10px',
        marginBottom: '10px',
        margin: 'auto',
        width: '50%',
        maxWidth: 500,
        background: 'white'
    },
    title: {
        color: 'white',
    },
    close: {
        marginTop: '20px',
        marginLeft: '10px',
        marginBottom: '20px',
    },
    boxTitle: {
        padding: '20px',
        color: 'white',
    },
    pannel: {
        width: '95%',
        margin: '15px',
        background: theme.palette.primary.main,
        textAlign: 'center',
        overflow: 'auto'
    },
    pannel2: {
        width: '95%',
        margin: '15px',
        background: theme.palette.secondary.main,
        textAlign: 'center',
        overflow: 'auto',
    },
    removeAttendancePannel: {
        textAlign: 'center',
        width: '95%',
        marginTop: '10px',
        background: theme.palette.secondary.main,
        overflow: 'auto',
        padding: '10px'
    },
}))

function EventEditor({ event, onComplete}) {
    const { adminFetch } = useAuth()
    const classes = useStyles()
    const { addToast } = useToasts()
    const [normieDetail, setNormieDetail] = useState(null)
    const [adminDetail, setAdminDetail] = useState(null)


    const updateViews = () => {
        adminFetch('/admin/events/preview-event', '/home', {}, 'POST', {eventId: event.id})
        .then(resp => resp.json())
        .then(resp => {
            if (!resp.errorMessage){
                resp.publicView.startDate = new Date(resp.publicView.startDate)
                resp.publicView.endDate = new Date(resp.publicView.endDate)
                resp.adminView.startDate = new Date(resp.adminView.startDate)
                resp.adminView.endDate = new Date(resp.adminView.endDate)
                setNormieDetail(resp.publicView)
                setAdminDetail(resp.adminView)
            } else {
                addToast(resp.errorMessage, {appearance: 'error', autoDismiss: true})
            }
        })
    }


    const closeEditor = () => {
        onComplete(false)
    }


    useEffect(() => {
        updateViews()
    },
    //eslint-disable-next-line
    [])


    return (
        <div>
            {!!event && <>
                <div className={classes.paper}>
                    <Grid container direction="row" className={classes.root}>
                        <Grid item xs={12} sm={12} md={6}>
                            <Typography variant='h3' className={classes.title}>Editing <strong>{event.name}</strong></Typography>
                            <Button variant="contained" color="secondary" className={classes.close} onClick={() => onComplete(false)}>Back to All Events</Button>
                            <div>
                                { event.category === 0 &&
                                    <Box className={classes.pannel}>
                                        <RulesMeetingCreation
                                            event={event}
                                            key={event.id}
                                            onUpdate={updateViews}
                                            onClosing={closeEditor} />
                                    </Box>
                                }
                                { event.category === 1 &&
                                    <Box className={classes.pannel}>
                                        <MissionCreation
                                            event={event}
                                            key={event.id}
                                            onUpdate={updateViews}
                                            onClosing={closeEditor} />
                                    </Box>
                                }
                                { event.category === 2 &&
                                    <Box className={classes.pannel}>
                                        <QuestCreation
                                            event={event}
                                            key={event.id}
                                            onUpdate={updateViews}
                                            onClosing={closeEditor} />
                                    </Box>
                                }
                            </div>
                        </Grid>
                        { (event.category === 1 || event.category === 2) && !!normieDetail && !!adminDetail &&
                            <Grid item xs={12} sm={12} md={6}>
                                <Box className={classes.pannel}>
                                    <Typography variant='h3' className={classes.title}>Normie View</Typography>
                                    <MissionCard detail={normieDetail} />
                                    <Box className={classes.pannel2}>
                                        <Typography variant='h3' className={classes.title}>Admin View</Typography>
                                        <MissionCard detail={adminDetail} />
                                    </Box>
                                </Box>
                            </Grid>
                        }
                    </Grid>
                </div>
            </>}
        </div>
    )
}

export default EventEditor
