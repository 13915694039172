import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(theme => ({
    body: {
        color: 'white',
        paddingLeft: '20px',
    },
}));

function HomeInfo({ data }) {
    const classes = useStyles();
    if (!!data.playerCounts) {
        return (
            <div>
                <Typography variant='h6' className={classes.body}>{"Registed Players: " + data.playerCounts.total}</Typography>
                <Typography variant='h6' className={classes.body}>{"Humans Remaining: " + data.playerCounts.humans}</Typography>
                <Typography variant='h6' className={classes.body}>{"Zombie Count: " + data.playerCounts.zombies}</Typography>
                <br/>
                <Typography variant='h6' className={classes.body}>{"Current Zombie Stun Timer: " + data.stunTimer}</Typography>
                <Typography variant='h6' className={classes.body}>{"Current Ammo Cap: " + data.ammoCap}</Typography>
                <Typography variant='h6' className={classes.body}>{"Zombie Quota: " + data.tagQuota}</Typography>
            </div>
        );
    } else {
        return <div></div>
    }
}

export default HomeInfo;