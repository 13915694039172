import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Box, Typography } from '@material-ui/core/';
import AlertsPannel from './homeComponents/alertsPannel';
import HomeInfo from './homeComponents/homeInfo';
import OffCampusCheckIn from './homeComponents/offCampusCheckIn';

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
    },
    title: {
        paddingTop: '20px',
        paddingLeft: '20px',
        color: 'white'
    },
}));

function LoggedInHome({ data, onUpdate }) {
    const classes = useStyles();

    const isAlive = data.hzAffiliation === 'HUMAN'
    const affiliation = !data.hzAffiliation ? '' : isAlive ? 'Human' : 'Zombie'
    return (
        <Grid container className={classes.root} spacing={0}>
            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <Box>
                    <Typography variant='h4' className={classes.title}>
                        Hello {affiliation} {data.firstNamePreferred}
                    </Typography>
                    {!!(affiliation === 'Human' && data.livesOffCampus && data.needsCheckInToday) &&
                        <OffCampusCheckIn onUpdate={onUpdate}/>
                    }
                    <br/>
                    <HomeInfo data={data} className={classes.body}/>
                </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                {!!data.announcements &&
                    <AlertsPannel title={'Announcements'} alerts={data.announcements} />
                }
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                {!!data.recentTags &&
                    <AlertsPannel title={'Recent Tags'} alerts={data.recentTags} isAlive={isAlive} />
                }
            </Grid>
        </Grid>
    );

}

export default LoggedInHome;