import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Box } from '@material-ui/core';
import MissionCard from './cards/missionCard';

const useStyles = makeStyles(theme => ({
    pannel: {
        width: '95%',
        marginTop: '10px',
        margin: 'auto',
        background: theme.palette.primary.main,
        textAlign: 'center',
        overflow: 'auto'
    },
    boxTitle: {
        color: 'white',
        padding: '10px',
    }
}));

function CurrentMission({ detail }) {
    const classes = useStyles();
    const eventType = !!detail ? detail.category[0] + detail.category.substring(1).toLowerCase() : ""
    return (
        <Box className={classes.pannel}>
            <Typography variant='h6' className={classes.boxTitle}>{detail.endDate.getTime() > Date.now() ? "Upcoming" : "Past"} {eventType}</Typography>
            <MissionCard detail={detail} />
        </Box>
    );

}

export default CurrentMission;