import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Paper, Tab, Tabs } from '@material-ui/core/';
import Individual from './subpages/individual';
import Squads from './subpages/squads';
// import Map from './subpages/map';
// import TagTree from './subpages/tree';

const useStyles = makeStyles(theme => ({
    title: {
        textAlign: 'center',
        color: 'white',
    },
    bar: {
        flexGrow: 1,
        background: theme.palette.background.default,
        width: '70%',
        minWidth: '750px',
        margin: 'auto'
    },
    tab: {
        color: 'white',
        fontWeight: theme.typography.fontWeightMedium,
        fontSize: theme.typography.pxToRem(15),
    },
}));

function Scoreboard(props) {
    const classes = useStyles();
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    return (
        <div>
            <Typography variant='h2' className={classes.title}>Scoreboard</Typography>
            <Paper position='static' className={classes.bar}>
                <Tabs value={value} onChange={handleChange}
                    indicatorColor="primary"
                    textColor="primary"
                    centered>
                    <Tab className={classes.tab} label='Individual Leaderboard'/>
                    <Tab className={classes.tab} label='Squad Leaderboard'/>
                    {/* <Tab className={classes.tab} label='Infection Map'/>
                    <Tab className={classes.tab} label='Tag Tree'/> */}
                </Tabs>
            </Paper>
            { value === 0 && 
                <Individual />
            }
            { value === 1 && 
                <Squads />
            }
            {/* { value === 2 && 
                <Map />
            }
            { value === 3 && 
                <TagTree />
            } */}
        </div>
    );
}

export default Scoreboard