import React from 'react';
import { IconButton, Typography, Card, Grid } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { makeStyles } from '@material-ui/core/styles';
import { useToasts } from 'react-toast-notifications';
import { useAuth } from '../../../auth';

const useStyles = makeStyles(theme => ({
    notification: {
        textAlign: 'center',
        width: '90%',
        margin: 'auto',
        background: theme.palette.secondary.main,
        marginTop: '10px',
        marginBottom: '10px',
    },
    heading: {
        color: 'white',
        paddingTop: '5px',
        paddingLeft: '10px',
        paddingBottom: '5px',
    },
    button: {
        margin: '0px',
        padding: '0px',
    }
}));

function DeathMessageEntry({message, updateDeathMsgs}) {
    const classes = useStyles();
    const { adminFetch } = useAuth();
    const { addToast } = useToasts();

    const handleRemoveDeathMessage = () => {
        adminFetch('/admin/games/removeDeathMessage', '/home', {}, 'POST', { deathMessage: message })
        .then(resp => resp.json())
        .then(resp => {
            if (!resp.errorMessage) {
                addToast('Death Message successfully removed from the game.', {appearance: 'success', autoDismiss: true})
                updateDeathMsgs()
            } else {
                addToast(resp.errorMessage, {appearance: 'error', autoDismiss: true})
            }
        })
    }

    return (
        <div>
            <Card className={classes.notification}>
                <div>
                    <Grid container alignItems='center'>
                        <Grid item xs={11}>
                            <Typography variant='body1' className={classes.heading}><strong>{message}</strong></Typography>
                        </Grid>
                        <Grid item xs={1}>
                            <IconButton className={classes.button} onClick={handleRemoveDeathMessage}>
                                <DeleteIcon />
                            </IconButton>
                        </Grid>
                    </Grid>
                </div>
            </Card>
        </div>
    )
}

export default DeathMessageEntry
