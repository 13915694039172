import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Button } from '@material-ui/core';
import AllEvents from './eventsComponents/allEvents';
import EventCreationModal from './eventsComponents/eventCreationModal';
import EventEditor from './eventsComponents/eventEditor';
import { useAuth } from '../../auth';
import Attendance from './eventsComponents/attendance';

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        textAlign: 'center',
    },
    create: {
        marginTop: '10px',
    }
}));

function MissionManagement(props) {
    const { adminFetch } = useAuth();
    const classes = useStyles();
    const [openEvent, setOpenEvent] = useState(undefined);
    const [creationShowing, setShowing] = useState(false);
    const [basicViews, setBasicViews] = useState([]);
    const [fullViews, setFullViews] = useState([]);
    const [takingAttendance, setTakingAttendance] = useState(false);
    const [editingEvent, setEditingEvent] = useState(false);

    const updateViews = () => {
        adminFetch('/admin/events', '/home')
        .then(resp => resp.json())
        .then(resp => {
            resp.pastEventsBasic.forEach(event => {
                event.startDate = new Date(event.startDate)
                event.endDate = new Date(event.endDate)
                if (event.gracePeriod) {
                    event.gracePeriod = new Date(event.gracePeriod)
                }
            })
            resp.futureEventsBasic.forEach(event => {
                event.startDate = new Date(event.startDate)
                event.endDate = new Date(event.endDate)
                if (event.gracePeriod) {
                    event.gracePeriod = new Date(event.gracePeriod)
                }
            })
            resp.pastEventsDetailed.forEach(event => {
                event.startDate = new Date(event.startDate)
                event.endDate = new Date(event.endDate)
                if (event.gracePeriod) {
                    event.gracePeriod = new Date(event.gracePeriod)
                }
            })
            resp.futureEventsDetailed.forEach(event => {
                event.startDate = new Date(event.startDate)
                event.endDate = new Date(event.endDate)
                if (event.gracePeriod) {
                    event.gracePeriod = new Date(event.gracePeriod)
                }
            })
            setBasicViews(resp.pastEventsBasic.concat(resp.futureEventsBasic))
            setFullViews(resp.pastEventsDetailed.concat(resp.futureEventsDetailed))
        })
    }

    useEffect(() => {
        updateViews()
    },
    //eslint-disable-next-line
    [])

    function handleEditingSelection(eventObj) {
        updateViews();
        setOpenEvent(eventObj);
    }

    function handleEditingClose() {
        setOpenEvent(undefined)
        updateViews();
    }

    function handleModalChange(value) {
        updateViews();
        setShowing(value)
    }

    if (takingAttendance) {
        return <Attendance event={openEvent} onComplete={(isNotDone) => { const tempEvent = openEvent; handleEditingClose(); setTakingAttendance(false); if (isNotDone){setOpenEvent(tempEvent); setTakingAttendance(true);}}} />
    } else if (editingEvent) {
        return <EventEditor event={openEvent} onComplete={(isNotDone) => { const tempEvent = openEvent; handleEditingClose(); setEditingEvent(false); if (isNotDone){setOpenEvent(tempEvent); setEditingEvent(true);}}} />
    } else {
        return (
            <Grid container className={classes.root}>
                <Grid item xs={12} sm={12} md={8} lg={6}>
                    <AllEvents
                        onUpdate={updateViews}
                        onEditingSelect={handleEditingSelection}
                        onEditEvent={() => setEditingEvent(true)}
                        onAttendance={() => setTakingAttendance(true)}
                        basicViews={basicViews}
                        fullViews={fullViews} />
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={6}>
                    <Button className={classes.create} variant="contained" color="secondary" onClick={() => setShowing(true)}>Create A New Event</Button>
                </Grid>
                    <EventCreationModal visible={creationShowing} onClosing={handleModalChange} key={creationShowing}/>
            </Grid>
        );
    }
}

export default MissionManagement