import React from 'react';
import { makeStyles, Box, Card, Typography } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    pannel: {
        width: '95%',
        marginTop: '10px',
        margin: 'auto',
        background: theme.palette.primary.main,
        textAlign: 'center',
        overflow: 'auto'
    },
    notification: {
        textAlign: 'center',
        width: '90%',
        margin: 'auto',
        background: 'white',
        marginTop: '15px',
        marginBottom: '15px',
    },
    redeemed: {
        textAlign: 'center',
        margin: 'auto',
        backgroundColor: 'lightgreen',
        maxWidth: '100px',
        marginTop: '5px',
        marginBottom: '5px',
    },
    unredeemed: {
        textAlign: 'center',
        margin: 'auto',
        maxWidth: '100px',
        background: theme.palette.warning.main,
        marginTop: '5px',
        marginBottom: '5px',
    },
    heading: {
        paddingTop: '5px',
    },
    content: {
        lineHeight: 'normal',
    },
}))

function CacheList({ caches }) {
    const classes = useStyles();

    return (
        <Box className={classes.pannel}>
            { caches.map(function(cache, index) {
                return <Card className={classes.notification} key={cache.id}>
                    <Typography variant='body1' className={classes.heading}><strong>{cache.code}</strong></Typography>
                    <Typography variant='body1' className={classes.content}>{cache.location}</Typography>
                    <Typography variant='body1' className={classes.content}>Point value: {cache.pointValue}</Typography>
                    {cache.redeemed ?
                        <Card className={classes.redeemed}>Redeemed</Card>
                        :
                        <Card className={classes.unredeemed}>Unredeemed</Card>
                    }
                </Card>
            })}
        </Box>
    )
}

export default CacheList