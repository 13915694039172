import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useAuth } from '../../../../auth';
import { useToasts } from 'react-toast-notifications';
import { Typography, TextField, Button } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { DesktopDateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import DateFnsUtils from '@date-io/date-fns';

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        textAlign: 'center',
    },
    title: {
        marginTop: 10,
        color: 'white',
    },
    input: {
        marginTop: '10px',
        marginBottom: '10px',
        margin: 'auto',
        width: '90%',
        maxWidth: 500,
        background: 'white'
    },
    date: {
        marginTop: '20px',
        marginBottom: '20px',
        marginLeft: 10,
        marginRight: 10,
    },
    save: {
        marginTop: 10,
        marginBottom: 20,
        marginLeft: 25,
        marginRight: 25,
        backgroundColor: theme.palette.success.main,
        '&:hover':{
            backgroundColor: theme.palette.success.dark,
        },
    },
    cancel: {
        marginTop: 10,
        marginBottom: 20,
        marginLeft: 25,
        marginRight: 25,
    },
}));

function RulesMeetingCreation(props) {
    const { adminFetch } = useAuth();
    const classes = useStyles();
    const { addToast } = useToasts();

    const [name, setName] = useState(props.event.name ? props.event.name : '');
    const [location, setLocation] = useState(props.event.location ? props.event.location : '');
    const [startDate, handleStartDateChange] = useState(props.event.startDate ? props.event.startDate : new Date());
    const [endDate, handleEndDateChange] = useState(props.event.endDate ? props.event.endDate : new Date());

    const submitEvent = () => {
        const data = {
            name,
            location,
            startDate: startDate.getTime(),
            endDate: endDate.getTime(),
        }
        adminFetch('/admin/events/meeting-update', '/home', {}, 'POST', {updates: data, eventId: props.event.id})
        .then(resp => {
            if (resp.ok) {
                addToast('Event has been saved for the current game', { appearance: 'success', autoDismiss: 'true'})
                props.onUpdate()
            } else {
                addToast('Something Went Wrong. This event was not properly saved', { appearance: 'error', autoDismiss: 'true'})
            }
        })
    }

    return (
        <div>
            <Typography variant='h4' className={classes.title}>Rules Meeting Editor</Typography>
            <TextField className={classes.input}
                variant="outlined"
                multiline
                value={name}
                onChange={e => setName(e.target.value)}
                inputProps={{ style: { background: 'white', fontFamily: '"Roboto", sans-serif' } }}
                placeholder="Name"/>
            <TextField className={classes.input}
                variant="outlined"
                multiline
                value={location}
                onChange={e => setLocation(e.target.value)}
                inputProps={{ style: { background: 'white', fontFamily: '"Roboto", sans-serif' } }}
                placeholder="Location Description"/>
            <br />
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DesktopDateTimePicker label="Start Date and Time" value={startDate} className={classes.date} onChange={(newValue) => {handleStartDateChange(newValue);}} renderInput={(params) => <TextField {...params} />}/>
                    <DesktopDateTimePicker label="End Date and Time" value={endDate} className={classes.date} onChange={(newValue) => {handleEndDateChange(newValue);}} renderInput={(params) => <TextField {...params} />}/>
                </LocalizationProvider>
            </MuiPickersUtilsProvider>
            {/* <br />
            <Button variant="contained" color="default" onClick={() => props.onAttendance()}>Take Attendance</Button> */}
            <br />
            <Button variant="contained" color="secondary" className={classes.cancel} onClick={() =>{props.onClosing()}}>Back to All Events</Button>
            <Button variant="contained" className={classes.save} onClick={() =>{submitEvent()}}>Save Changes</Button>
        </div>
    );
}

export default RulesMeetingCreation