import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {Checkbox, Grid, Button, Box, Typography } from '@material-ui/core';
import { useAuth } from '../../../auth';
import { useToasts } from 'react-toast-notifications';

const useStyles = makeStyles(theme => ({
    title: {
    textAlign: 'center',
    color: 'white',
    },
    heading: {
        color: 'white',
        paddingTop: '5px',
    },
    pannel: {
        width: '70%',
        marginTop: '10px',
        margin: 'auto',
        background: theme.palette.primary.main,
        textAlign: 'center',
        overflow: 'auto'
    },
}));

function OZList({ proposedOZs, confirmedOZCount, removeOZ, onConfirmUpdate, onUpdate }) {
    const classes = useStyles();
    const { adminFetch } = useAuth();
    const { addToast } = useToasts();

    const handleCheckedChange = event => {
        onConfirmUpdate(event.currentTarget.dataset.playerId, event.currentTarget.checked);
    }

    const handleRemoveOZ = event => {
        removeOZ({
            "playerId": event.currentTarget.dataset.playerId
        })
    }

    const handleCommitOZs = () => {
        if (confirmedOZCount !== 5) {
            return;
        }
        adminFetch('/admin/players/choose-ozs', '/home', {}, 'POST', { playerIds: proposedOZs.filter(oz => oz.confirmed).map(oz => oz.playerId) })
        .then(resp => resp.json())
        .then(resp => {
            if (!resp.errorMessage) {
                addToast('OZs successfully created.', { appearance: 'success', autoDismiss: true })
                onUpdate()
            } else {
                addToast(resp.errorMessage, { appearance: 'error', autoDismiss: true })
            }
        })
    }

    return (
        <Box>
            <Grid container direction='row' className={classes.pannel}>
                <Grid item xs={9}>
                    <Typography className={classes.title} variant='h5'>Players Considered for OZ</Typography>
                </Grid>
                <Grid item xs={3}>
                    <Button onClick={handleCommitOZs} disabled={confirmedOZCount !== 5}>Commit OZs</Button>
                </Grid>
                <Grid item xs={12}>
                { proposedOZs.map((oz, i) => {
                        return (
                            <Grid container key={i}>
                                <Grid item xs={1}>
                                    <Checkbox onChange={handleCheckedChange} data-player-id={oz.playerId} inputProps={{ 'data-player-id': oz.playerId }} disabled={confirmedOZCount === 5 && !oz.confirmed} />
                                </Grid>
                                <Grid item xs={8}>
                                    <Typography variant='body1' className={classes.heading}>{oz.name}</Typography>
                                </Grid>
                                <Grid item xs={3}>
                                    <Button onClick={handleRemoveOZ} data-player-id={oz.playerId} disabled={oz.confirmed}>Remove OZ</Button>
                                </Grid>
                            </Grid>
                        )}
                    )}
                </Grid>
            </Grid>
        </Box>
    )
}

export default OZList