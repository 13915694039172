import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import LoginButton from './homeComponents/loginButton';
import HomeInfo from './homeComponents/homeInfo';

const useStyles = makeStyles(theme => ({
    container: {
        textAlign:'center',
    },
    title: {
        color: 'white',
        fontSize: '64px'
    }
}));

function LoggedOutHome({ data }) {
    const classes = useStyles();
    return (
        <div className={classes.container}>
            <Typography className={classes.title}>
                Welcome to CWRU Humans vs Zombies
            </Typography>
            <LoginButton />
            <HomeInfo data={data}/>
        </div>
    );

}

export default LoggedOutHome;