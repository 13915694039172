import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import Constitution from './constitution';

const useStyles = makeStyles(theme => ({
    container:{
        textAlign: 'center',
    },
    exitButton: {
        margin: '25px'
    },
    constitution: {
        marginBottom: '25px'
    }
}));

function BylawsPage() {
    const classes = useStyles();
    return (
        <div className={classes.container}> 
            <Constitution className={classes.constitution}></Constitution>
        </div>
    );

}

export default BylawsPage;