import React, { useEffect, useState, useContext } from 'react';
import { Typography, makeStyles, Button } from '@material-ui/core';
import SquadList from './squadComponents/squadList';
import CreateSquadModal from './squadComponents/createSquadModal';
import { PlayerStatusContext } from '../reuseComponents/playerStatusContext';
import { GameStatusContext } from '../reuseComponents/gameStatusContext';
import { useAuth } from '../auth';

const useStyles = makeStyles(theme => ({
    title: {
        color: 'white',
        marginBottom: 15,
    },
    root: {
        textAlign: 'center',
    }
}))

function Squads() {
    const classes = useStyles();
    const [allSquads, setAllSquads] = useState([])
    const [userSquadId, setUserSquadId] = useState(null)
    const [creating, setCreating] = useState(false)
    const { isRegistered } = useContext(PlayerStatusContext);
    const { gameStatus } = useContext(GameStatusContext)
    const { loggedInFetch } = useAuth();

    const updateSquads = () => {
        loggedInFetch('/squads/list', '/squads') //This eP never returns an auth error
        .then(resp => resp.json())
        .then(resp => {
            setAllSquads(resp.squadList)
            setUserSquadId(resp.playerSquadId)
        })
    }

    useEffect(() => {
        updateSquads()
    },
    //eslint-disable-next-line
    [])

    return (
        <div className={classes.root}>
            <Typography variant='h2' className={classes.title}>Squads</Typography>
            {isRegistered && gameStatus === 'preGame' &&
                <Button
                    variant='contained'
                    color="primary"
                    disabled={!!userSquadId}
                    onClick={() => setCreating(true)}>
                    Create Squad
                </Button>
            }
            <SquadList squads={allSquads} currentSquadId={userSquadId} onUpdate={updateSquads} />
            <CreateSquadModal visible={creating} onUpdate={updateSquads} onClose={() => setCreating(false)} key={creating} />
        </div>
    )
}

export default Squads