import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useAuth } from '../../../../auth';
import { useToasts } from 'react-toast-notifications';
import { Typography, TextField, Button } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { DesktopDateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import DateFnsUtils from '@date-io/date-fns';

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        textAlign: 'center',
    },
    title: {
        marginTop: 10,
        color: 'white',
    },
    input: {
        marginTop: '10px',
        marginBottom: '10px',
        margin: 'auto',
        width: '90%',
        maxWidth: 500,
        background: 'white'
    },
    date: {
        marginTop: '20px',
        marginBottom: '20px',
        marginLeft: 10,
        marginRight: 10,
    },
    save: {
        marginTop: 10,
        marginBottom: 20,
        marginLeft: 25,
        marginRight: 25,
        backgroundColor: theme.palette.success.main,
        '&:hover':{
            backgroundColor: theme.palette.success.dark,
        },
    },
    cancel: {
        marginTop: 10,
        marginBottom: 20,
        marginLeft: 25,
        marginRight: 25,
    },
}));

function QuestCreation(props) {
    const { adminFetch } = useAuth();
    const classes = useStyles();
    const { addToast } = useToasts();

    const [name, setName] = useState(props.event.name ? props.event.name : '');
    const [location, setLocation] = useState(props.event.location ? props.event.location : '');
    const [startDate, handleStartDateChange] = useState(props.event.startDate ? props.event.startDate : new Date());
    const [endDate, handleEndDateChange] = useState(props.event.endDate ? props.event.endDate : new Date());

    const [neededAdmins, setNeededAdmins] = useState(props.event.neededAdmins ? props.event.neededAdmins : '');
    const [neededEquipment, setNeededEquip] = useState(props.event.neededEquipment ? props.event.neededEquipment : '');
    const [adminMapLink, setAdminMap] = useState(props.event.adminMapLink ? props.event.adminMapLink : '');
    const [adminInfo, setAdminInfo] = useState(props.event.adminInfo ? props.event.adminInfo : '');
    const [storyDesc, setStoryDesc] = useState(props.event.storyDesc ? props.event.storyDesc : '');
    const [boundariesDesc, setBoundDesc] = useState(props.event.boundariesDesc ? props.event.boundariesDesc : '');
    const [rulesDesc, setRulesDesc] = useState(props.event.rulesDesc ? props.event.rulesDesc : '');
    const [humanGoals, setHumanGoal] = useState(props.event.humanGoals ? props.event.humanGoals : '');
    const [zombieGoals, setZombieGoal] = useState(props.event.zombieGoals ? props.event.zombieGoals : '');
    const [humanRewards, setHumanRewards] = useState(props.event.humanRewards ? props.event.humanRewards : '');
    const [winningPoints, setWinningPoints] = useState(props.event.winningPoints ? props.event.winningPoints : 0);
    const [questMessage, setQuestMessage] = useState(props.event.questMessage ? props.event.questMessage : '');
    const [pointsJoke, setPointsJoke] = useState(props.event.pointsJoke ? props.event.pointsJoke : '');
    
    const submitEvent = () => {
        const data = {
            name,
            location,
            startDate: startDate.getTime(),
            endDate: endDate.getTime(),
            neededAdmins,
            neededEquipment,
            adminMapLink,
            adminInfo,
            storyDesc,
            boundariesDesc,
            rulesDesc,
            humanGoals,
            zombieGoals,
            humanRewards,
            winningPoints,
            questMessage,
            pointsJoke,
        }
        adminFetch('/admin/events/quest-update', '/home', {}, 'POST', {updates: data, eventId: props.event.id})
        .then(resp => {
            if (resp.ok) {
                addToast('Event has been saved for the current game', { appearance: 'success', autoDismiss: 'true'})
                props.onUpdate()
            } else {
                addToast('Something Went Wrong. This event was not properly saved', { appearance: 'error', autoDismiss: 'true'})
            }
        })
    }
    return (
        <div>
            <Typography variant='h4' className={classes.title}>Quest Editor</Typography>
            <TextField className={classes.input}
                variant="outlined"
                multiline
                value={name}
                onChange={e => setName(e.target.value)}
                inputProps={{ style: { background: 'white', fontFamily: '"Roboto", sans-serif' } }}
                label="Name"/>
            <TextField className={classes.input}
                variant="outlined"
                multiline
                value={location}
                onChange={e => setLocation(e.target.value)}
                inputProps={{ style: { background: 'white', fontFamily: '"Roboto", sans-serif' } }}
                label="Location Description"/>
            <br />
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DesktopDateTimePicker label="Start Date and Time" value={startDate} className={classes.date} onChange={(newValue) => {handleStartDateChange(newValue);}} renderInput={(params) => <TextField {...params} />}/>
                    <DesktopDateTimePicker label="End Date and Time" value={endDate} className={classes.date} onChange={(newValue) => {handleEndDateChange(newValue);}} renderInput={(params) => <TextField {...params} />}/>
                </LocalizationProvider>
            </MuiPickersUtilsProvider>
            {/* <br /> */}
            {/* <Button variant="contained" color="default" onClick={() => props.onAttendance()}>Take Attendance</Button> */}
            <br />

            <TextField className={classes.input}
                variant="outlined"
                multiline
                value={neededAdmins}
                onChange={e => setNeededAdmins(e.target.value)}
                inputProps={{ style: { background: 'white', fontFamily: '"Roboto", sans-serif' } }}
                label="Needed Admins"/>
            <TextField className={classes.input}
                variant="outlined"
                multiline
                value={neededEquipment}
                onChange={e => setNeededEquip(e.target.value)}
                inputProps={{ style: { background: 'white', fontFamily: '"Roboto", sans-serif' } }}
                label="Needed Equipment"/>
            <TextField className={classes.input}
                variant="outlined"
                multiline
                value={adminMapLink}
                onChange={e => setAdminMap(e.target.value)}
                inputProps={{ style: { background: 'white', fontFamily: '"Roboto", sans-serif' } }}
                label="Admin Map Link"/>
            <TextField className={classes.input}
                variant="outlined"
                multiline
                value={adminInfo}
                onChange={e => setAdminInfo(e.target.value)}
                inputProps={{ style: { background: 'white', fontFamily: '"Roboto", sans-serif' } }}
                label="Admin Information"/>
            <TextField className={classes.input}
                variant="outlined"
                multiline
                value={storyDesc}
                onChange={e => setStoryDesc(e.target.value)}
                inputProps={{ style: { background: 'white', fontFamily: '"Roboto", sans-serif' } }}
                label="Story Description"/>
            <TextField className={classes.input}
                variant="outlined"
                multiline
                value={boundariesDesc}
                onChange={e => setBoundDesc(e.target.value)}
                inputProps={{ style: { background: 'white', fontFamily: '"Roboto", sans-serif' } }}
                label="Boundaries"/>
            <TextField className={classes.input}
                variant="outlined"
                multiline
                value={rulesDesc}
                onChange={e => setRulesDesc(e.target.value)}
                inputProps={{ style: { background: 'white', fontFamily: '"Roboto", sans-serif' } }}
                label="Rules"/>
            <TextField className={classes.input}
                variant="outlined"
                multiline
                value={humanGoals}
                onChange={e => setHumanGoal(e.target.value)}
                inputProps={{ style: { background: 'white', fontFamily: '"Roboto", sans-serif' } }}
                label="Human Goals"/>
            <TextField className={classes.input}
                variant="outlined"
                multiline
                value={zombieGoals}
                onChange={e => setZombieGoal(e.target.value)}
                inputProps={{ style: { background: 'white', fontFamily: '"Roboto", sans-serif' } }}
                label="Zombie Goals"/>
            <TextField className={classes.input}
                variant="outlined"
                multiline
                value={humanRewards}
                onChange={e => setHumanRewards(e.target.value)}
                inputProps={{ style: { background: 'white', fontFamily: '"Roboto", sans-serif' } }}
                label="Human Rewards"/>
            <TextField className={classes.input}
                variant="outlined"
                multiline
                value={winningPoints}
                onChange={e => setWinningPoints(e.target.value)}
                inputProps={{ style: { background: 'white', fontFamily: '"Roboto", sans-serif' } }}
                label="Winning Points"/>
            <TextField className={classes.input}
                variant="outlined"
                multiline
                value={questMessage}
                onChange={e => setQuestMessage(e.target.value)}
                inputProps={{ style: { background: 'white', fontFamily: '"Roboto", sans-serif' } }}
                label="Quest Message"/>
            <TextField className={classes.input}
                variant="outlined"
                multiline
                value={pointsJoke}
                onChange={e => setPointsJoke(e.target.value)}
                inputProps={{ style: { background: 'white', fontFamily: '"Roboto", sans-serif' } }}
                label="Points Joke"/>
            <br />
            <Button variant="contained" color="secondary" className={classes.cancel} onClick={() =>{props.onClosing()}}>Back to All Events</Button>
            <Button variant="contained" className={classes.save} onClick={() =>{submitEvent()}}>Save Changes</Button>
        </div>
    );
}

export default QuestCreation