import React, { useEffect, useState, useContext } from 'react';
import Waiver from './registerComponents/waiver';
import PlayerSettings from './registerComponents/playerSettings';
import { useAuth } from '../auth';
import { makeStyles, Typography } from '@material-ui/core';
import { useToasts } from 'react-toast-notifications';
import { PlayerStatusContext } from '../reuseComponents/playerStatusContext';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
    pageTitle: {
        paddingTop: '15px',
        paddingBottom: '15px',
        textAlign: 'center',
        color: 'white',
        minWidth:'320px',
    },
}))

function Registration() {
    const classes = useStyles();
    const { loggedInFetch } = useAuth();
    const [waiverComplete, setWaiverComplete] = useState(false);
    const [waiverInfo, setWaiverInfo] = useState(null);
    const { addToast } = useToasts();
    const { isRegistered, setRegistered } = useContext(PlayerStatusContext);
    const history = useHistory();

    if (isRegistered) {
        history.push('/home')
    }

    useEffect(() => {
        loggedInFetch('/registration/waiver', '/register')
        .then(resp => {
            if (resp.status === 406) { //waiver already completed
                setWaiverComplete(true)
                return null;
            } else {
                return resp.json()
            }
        })
        .then(resp => {
            if (!!resp) {
                setWaiverInfo(resp)
            }
        })
    },
    //eslint-disable-next-line
    [])

    const onWaiverComplete = finishedWaiver => {
        const route = waiverInfo.under18 ? '/registration/minor-waiver' : '/registration/submit-waiver';
        loggedInFetch(route, '/register', {}, 'POST', {waiverData: finishedWaiver})
        .then(resp => resp.json())
        .then(resp => {
            if (!resp.errorMessage) {
                setWaiverComplete(true)
            } else {
                addToast(resp.errorMessage, {appearance: 'error', autoDismiss: true})
            }
        })
    }

    const onPlayerComplete = data => {
        const route = '/registration/register';
        loggedInFetch(route, '/register', {}, 'POST', data)
        .then(resp => resp.json())
        .then(resp => {
            if (!resp.errorMessage) {
                setRegistered(true)
                history.push('/profile')
            } else {
                addToast(resp.errorMessage, {appearance: 'error', autoDismiss: true})
            }
        })
    }

    return (
        <>
            <Typography variant='h2' className={classes.pageTitle}>Game Registration</Typography>
            {!!waiverInfo && !waiverComplete &&
                <Waiver waiverInfo={waiverInfo} onSubmit={onWaiverComplete} />
            }
            {waiverComplete &&
                <PlayerSettings onSubmit={onPlayerComplete}/>
            }
        </>
    )
}

export default Registration