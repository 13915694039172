import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Card } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    notification: {
        textAlign: 'center',
        width: '90%',
        margin: 'auto',
        background: theme.palette.secondary.main,
        marginBottom: '20px',
        paddingBottom: '10px',
    },
    iconGrid: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    avatar: {
        width: '90%',
        paddingTop: '45%',
        paddingBottom: '45%'
    },
    heading: {
        color: 'white',
        paddingTop: '10px',
        paddingLeft: '10px',
    },
    content: {
        color: 'white',
        paddingLeft: '10px',
        lineHeight: 'normal',
        paddingBottom: '10px',
    }
}));

function PlayerCard({ person }) { //person is a user, not a player
    const classes = useStyles();
    return (
        <Card className={classes.notification}>
            {/* <Grid container>
                <Grid item xs={3} className={classes.iconGrid}>
                    <Avatar alt='Profile Picture' className={classes.avatar} />
                </Grid>
                <Grid item xs={9}> */}
                <div>
                    <Typography variant='body1' className={classes.heading}><strong>{person.name}</strong></Typography>
                    <Typography variant='body1' className={classes.heading}><strong>{person.case_id}</strong></Typography>
                </div>
                    {/* <Typography variant='body1' className={classes.content}>{person.email}</Typography>
                    <Typography variant='body1' className={classes.content}>{person.role}</Typography> */}
                {/* </Grid>
            </Grid> */}
        </Card>
    );

}

export default PlayerCard;