import React, { useState } from 'react';
import UserEntry from './userEntry';
import { Box, Modal } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles';
import { useAuth } from '../../../auth';
import { useToasts } from 'react-toast-notifications';
import AddInfraction from './addInfraction';
import ConfirmRemoval from './confirmRemoval';


const useStyles = makeStyles(theme => ({
    tableContainer: {
        marginTop: '15px',
        background: theme.palette.primary.main,
        width: '90%',
        minWidth: '500px',
        margin: 'auto',
    },
    hederCell: {
        color: 'white',
        fontFamily: '"Roboto", sans-serif',
        textTransform: 'uppercase',
        textAlign: 'center',
    },
    bodyRow: {
        background: 'white',
    },
    bodyCell: {
        textAlign: 'center',
        color: 'black',
    }
}));

function UserList({ users, squads, onUpdate, addOZ, removeOZ, nameSearch, caseIdSearch}) {
    const classes = useStyles();
    const { adminFetch } = useAuth();
    const { addToast } = useToasts();
    const [badUser, setBadUser] = useState(null);
    const [toRemove, setToRemove] = useState(null);

    const ozsAssigned = users.some(entry => entry.affiliation === 'OZ')

    const handleRemove = playerId => {
        adminFetch('/admin/players/remove', '/home', {}, 'POST', { playerId })
        .then(resp => {
            if (resp.ok) {
                addToast('Player successfully removed from the game.', {appearance: 'success', autoDismiss: true})
                onUpdate()
            } else {
                addToast('Unable to remove player from the game.', {appearance: 'error', autoDismiss: true})
            }
        })
    }

    const handleInfractionClose = () => {
        setBadUser(null);
        onUpdate();
    }

    const handleRemovalClose = () => {
        setToRemove(null);
        onUpdate();
    }

    return (
        <Box className={classes.tableContainer}>
        {
            users.filter(user => user.name.toLowerCase().includes(nameSearch) && user.caseId.toLowerCase().includes(caseIdSearch))
                .map((user, i) => {
                return (
                    <UserEntry key={i} user={user} squads={squads} onUpdate={onUpdate} createInfraction={setBadUser} confirmRemoval={setToRemove} ozsAssigned={ozsAssigned} selectOZ={addOZ} deselectOZ={removeOZ} />
            )})
        }
        <Modal
            open={!!badUser}
            onClose={handleInfractionClose}>
            <div>
                <AddInfraction
                    user={badUser}
                    callback={handleInfractionClose}/>
            </div>
        </Modal>
        <Modal
            open={!!toRemove}
            onClose={handleRemovalClose}>
            <div>
                <ConfirmRemoval
                    user={toRemove}
                    callback={handleRemovalClose}
                    toRemove={handleRemove}/>
            </div>
        </Modal>
        </Box>
    )
}

export default UserList