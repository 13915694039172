import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography , Box, Card, Button } from '@material-ui/core';
import { useAuth } from '../../../auth';
import { useToasts } from 'react-toast-notifications';
import ResolutionModal from './eventForms/resolutionModal';
import { useState } from 'react';


const useStyles = makeStyles(theme => ({
    title: {
        flexGrow: 1,
        color: 'white',
    },
    pannel: {
        width: '95%',
        marginTop: '10px',
        margin: 'auto',
        background: theme.palette.primary.main,
        textAlign: 'center',
        overflow: 'auto'
    },
    notification: {
        textAlign: 'center',
        width: '90%',
        margin: 'auto',
        background: 'white',
        marginTop: '10px',
        marginBottom: '10px',
        paddingBottom: '10px',
    },
    heading: {
        paddingTop: '5px',
    },
    content: {
        lineHeight: 'normal',
    },
    remove: {
        marginTop: 10,
        marginLeft: 15,
        backgroundColor: theme.palette.warning.main,
        '&:hover':{
            backgroundColor: theme.palette.warning.dark,
        }
    },
    edit: {
        marginTop: 10,
        marginLeft: 15,
    },
}));

function AllEvents({ onEditingSelect, onAttendance, onEditEvent, basicViews, fullViews, onUpdate }) {
    const classes = useStyles();
    const { adminFetch } = useAuth();
    const { addToast } = useToasts();
    const [modalEvent, setModalEvent] = useState(null);
    const [isDelete, setDelete] = useState(false);
    const [eName, setEName] = useState("");

    const handleTakeAttendance = index => {
        onEditingSelect(fullViews[index]);
        onAttendance();
    }

    const handleEditEvent = index => {
        onEditingSelect(fullViews[index]);
        onEditEvent();
    }

    const delet = event => {
        setDelete(true);
        setEName(event.name);
        setModalEvent(event);
    }

    const onResolve = event => {
        let data = {
            eventId: event.id
        }
        if (event.category === 'MISSION') {
            setEName(event.name);
            setDelete(false);
            setModalEvent(event);
        } else {
            setDelete(false);
            sendResolution(data);
        }
    }

    const onDelete = data => {
        adminFetch('/admin/events/delete', '/home', {}, 'POST', data)
        .then(resp => resp.json())
        .then(resp => {
            if (resp == null) {
                setModalEvent(null)
                onUpdate();
            } else {
                addToast(resp.errorMessage, {appearance: 'error', autoDismiss: true})
            }
        })
    }

    const onPublish = (event,isPublishing) => {
        let data = {
            eventId: event.id,
            isPublishing: isPublishing
        }
        adminFetch('/admin/events/publish', '/home', {}, 'POST', data)
        .then(resp => resp.json())
        .then(resp => {
            if(resp == null) {
                onUpdate();
            } else {
                addToast(resp.errorMessage, {appearance: 'error', autoDismiss: true})
            }
        })
    }

    const sendResolution = data => {
        adminFetch('/admin/events/resolve', '/home', {}, 'POST', data)
        .then(resp => resp.json())
        .then(resp => {
            if (resp == null) {
                setModalEvent(null)
                onUpdate();
            } else {
                addToast(resp.errorMessage, {appearance: 'error', autoDismiss: true})
            }
        })
    }

    return (
        <div>
            <Typography variant="h4" className={classes.title}>All Events</Typography>
            <Box className={classes.pannel}>
                { basicViews.map(function(event, index) {
                    return <Card className={classes.notification} key={event.id}>
                        <Typography variant='body1' className={classes.heading}><strong>{event.name}</strong></Typography>
                        <Typography variant='body1' className={classes.content}>{event.category.replace('_', ' ')}</Typography>
                        <Typography variant='body1' className={classes.content}>{!!event.published && event.published ? 'Published - ' : 'Unublished - '}{event.resolved ? 'Resolved' : 'Unresolved'}</Typography>
                        {event.resolved && event.winningTeam === 'HUMANS' &&
                            <Typography variant='body1' className={classes.heading}>Humans win!</Typography>
                        }
                        {event.resolved && event.winningTeam === 'ZOMBIES' &&
                            <Typography variant='body1' className={classes.heading}>Zombies win!</Typography>
                        }
                        {!event.published && !event.resolved && <>
                            <Button variant='contained' color="secondary" className={classes.edit} onClick={() => onPublish(event,true)}>Publish</Button>
                        </>}
                        {event.published && !event.resolved && <>
                            <Button variant='contained' color="secondary" className={classes.edit} onClick={() => onPublish(event,false)}>Unpublish</Button>
                        </>}
                        {!event.resolved && <>
                            <Button variant='contained' disabled={!event.published} color="secondary" className={classes.edit} onClick={() => onResolve(event)}>Resolve</Button>
                            <Button variant='contained' disabled={!event.published} color="secondary" className={classes.edit} onClick={() => handleTakeAttendance(index)}>Take Attendance</Button>
                            <Button variant='contained' color="secondary" className={classes.edit} onClick={() => handleEditEvent(index)}>Edit</Button>
                        </>}
                        {!!event.resolved && <>
                            <Button variant='contained' color="secondary" className={classes.edit} onClick={() => handleTakeAttendance(index)}>View Attendance</Button>
                        </>}
                        <Button variant='contained' className={classes.remove} onClick={() => delet(event)}>Delete</Button>
                    </Card>
                })}
            </Box>
            <ResolutionModal event={modalEvent} isDeleting={isDelete} onSubmit={sendResolution} onClose={() => setModalEvent(null)} onDelete={onDelete} eventName={eName}/>
        </div>
    );
}

export default AllEvents