import React, { useState } from 'react'
import { Typography, Box, TextField, Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles';
import { useAuth } from '../../auth';
import { useToasts } from 'react-toast-notifications';

const useStyles = makeStyles(theme => ({
    title: {
        paddingTop: '20px',
        textAlign: 'center',
        color: 'white',
    },
    inputContainer: {
        background: theme.palette.primary.main,
        width: '50%',
        minWidth: '500px',
        margin: 'auto',
        textAlign: 'center'
    },
    input: {
        marginTop: '25px',
        margin: 'auto',
        width: '90%',
        maxWidth: 500,
    },
    submit: {
        marginTop: '25px',
        marginBottom: '25px',
        fontSize: '20px',
        width: '50%',
        maxWidth: 300,
    }
}));

function CacheReport(props) {
    const classes = useStyles();
    const { addToast } = useToasts();
    const [cacheCode, setCode] = useState('');
    const { loggedInFetch } = useAuth();

    const onSubmit = () => {
        let upperCode = cacheCode.toUpperCase()
        loggedInFetch('/caches/redeem', 'report', {}, 'POST', { cacheCode: upperCode })
        .then(resp => {
            if (resp.status === 200) {
                addToast('Congratulations! Cache Redeemed Successfully.', { appearance: 'success', autoDismiss: 'true'})
                setCode('')
            } else {
                addToast('Unable to Redeem Code', { appearance: 'error', autoDismiss: 'true'})
            }
        })
    }

    return (
        <div>
            <Typography variant='h2' className={classes.title}>Redeem a Cache</Typography>
            <Box className={classes.inputContainer}>
                <TextField className={classes.input}
                    variant="outlined"
                    value={cacheCode}
                    onChange={e => setCode(e.target.value)}
                    inputProps={{ style: {textAlign: 'center', background: 'white', fontFamily: '"Roboto", sans-serif', textTransform: 'uppercase'} }}
                    placeholder="Enter Cache Code"/>
                <br />
                <Button
                    className={classes.submit}
                    variant='contained'
                    color='secondary'
                    onClick={onSubmit}>
                    Submit
                </Button>
            </Box>
        </div>

    );
}

export default CacheReport