import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
  palette: {
    primary: { main: '#39796B' },
    secondary: { main: '#004D40' },
    background: {
      default: '#011B1D',
    }
  }
});

export default theme;