import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import { format } from 'date-fns';
import { PlayerStatusContext } from '../../../reuseComponents/playerStatusContext';


const useStyles = makeStyles(theme => ({
    notification: {
        textAlign: 'center',
        width: '90%',
        margin: 'auto',
        height: '50%',
    },
    heading: {
        color: 'white',
        paddingTop: '5px',
    },
    date: {
        color: 'white',
    },
    description: {
        textAlign: 'left',
        color: 'white',
        lineHeight: 'normal',
        whiteSpace: 'pre-wrap',
    },
    mapHeader: {
        color: 'white',
    },
    //marker default coordinate system puts the marker's top left at the coordinate. This helps
    marker: {
        display: 'inline-flex',
        textAlign: 'center',
        alignItems: 'center',
        justifyContent: 'center',
        transform: 'translate(-50%, -100%)'
    },
    stupidity1: {
        position: 'relative',
        minHeight: 300
    },
    stupidity2: {
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
    },
}));


function MissionCard({ detail }) {
    const classes = useStyles();
    const { isAdmin } = useContext(PlayerStatusContext);

    const eventType = !!detail ? detail.category[0] + detail.category.substring(1).toLowerCase() : ""
    return (
        <>{!!detail &&
            <div className={classes.notification}>
                {!!detail.name && <>
                    <Typography variant='h3' className={classes.heading}>
                        {detail.name}
                    </Typography>
                    <br/>
                </>}
                {!!detail.startDate && !!detail.endDate && <>
                    <Typography variant='h6' className={classes.date}>
                        {eventType} takes place on {format(detail.startDate, 'PPPP') + ' from ' + format(detail.startDate, 'h:mm a') + ' - '+ format(detail.endDate, 'h:mm a')}.
                        {!!detail.gracePeriod && (detail.gracePeriod - detail.startDate) !== 0 && <>
                            <br/>
                            Grace period for humans will begin at {format(detail.gracePeriod, 'h:mm a')}.
                        </>}
                        {!!detail.gracePeriod && (detail.gracePeriod - detail.startDate) === 0 && <>
                            <br/>
                            <strong><u>There is no grace period for this mission.</u></strong>
                        </>}
                    </Typography>
                    <br/>
                </>}
                {!!detail.storyDesc && <>
                    <Typography variant='body1' className={classes.description}>
                        {detail.storyDesc}
                    </Typography>
                    <br/>
                </>}
                {(!!detail.location || !!detail.humanStartLocation || !!detail.zombieStartLocation || !!detail.deadHumansLocation) && <>
                    <Typography variant='h5' className={classes.heading}>
                        {eventType} Location
                    </Typography>
                    <ul>
                        {!!detail.location && <>
                            <li className={classes.description}><Typography>The {eventType} takes place {detail.location}.</Typography></li>
                        </>}
                        {!!detail.humanStartLocation && <>
                            <li className={classes.description}><Typography>Humans will meet <strong>{detail.humanStartLocation}</strong> for check-in.</Typography></li>
                        </>}
                        {!!detail.zombieStartLocation && <>
                        <li className={classes.description}><Typography>Zombies will meet <strong>{detail.zombieStartLocation}</strong> for check-in.</Typography></li>
                        </>}
                        {!!detail.deadHumansLocation && <>
                            <li className={classes.description}><Typography>Dead humans go <strong>{detail.deadHumansLocation}</strong> after being tagged and handing over their rubber band.</Typography></li>
                        </>}
                    </ul>
                </>}
                {(!!detail.boundariesDesc || !!detail.mapLink) && <>
                    <Typography variant='h5' className={classes.heading}>
                        {eventType} Boundaries and Map
                    </Typography>
                    <ul>
                        {!!detail.boundariesDesc && <>
                            {detail.boundariesDesc.split('\n').map((boundary, index) => 
                                {
                                    if (boundary.startsWith("\\t")) {
                                        return (
                                            <ul key={index}>
                                                <li className={classes.description}><Typography>{boundary.replace(/^\W+/, "").substring(1)}</Typography></li>
                                            </ul>
                                        )
                                    } else {
                                        return (
                                            <li className={classes.description} key={index}><Typography>{boundary.replace(/^\W+/, "")}</Typography></li>
                                        )
                                    }
                                }
                            )}
                        </>}
                        {!!detail.mapLink && <>
                            <li className={classes.description}><Typography>Boundaries, check-in locations, and bag drop for the mission can be found <a href={detail.mapLink}>here</a>.</Typography></li>
                        </>}
                    </ul>
                    {!!detail.mapLink && <>
                        <div className={classes.stupidity1}>
                            <div className={classes.stupidity2}>
                                <iframe title="Mission Map" src={detail.mapLink} width="100%" height="100%"></iframe>
                            </div>
                        </div>
                    </>}
                </>}
                {!!detail.rulesDesc && <>
                    <Typography variant='h5' className={classes.heading}>
                        {eventType} Rules
                    </Typography>
                    <ul>
                        {detail.rulesDesc.split('\n').map((ruleLine, index) => 
                            {
                                if (ruleLine.startsWith("\\t\\t")) {
                                    return (
                                        <ul key={index}>
                                            <ul>
                                                <li className={classes.description}><Typography>{ruleLine.replace(/^\W+/, "").substring(3)}</Typography></li>
                                            </ul>
                                        </ul>
                                    )
                                } else if (ruleLine.startsWith("\\t")) {
                                    return (
                                        <ul key={index}>
                                            <li className={classes.description}><Typography>{ruleLine.replace(/^\W+/, "").substring(1)}</Typography></li>
                                        </ul>
                                    )
                                } else {
                                    return (
                                        <li className={classes.description} key={index}><Typography>{ruleLine.replace(/^\W+/, "")}</Typography></li>
                                    )
                                }
                            }
                        )}
                    </ul>
                </>}
                {(!!detail.humanGoals || !!detail.zombieGoals || !!detail.winConditions) && <>
                    <Typography variant='h5' className={classes.heading}>
                        {eventType} Objectives
                    </Typography>
                    {!!detail.humanGoals && <>
                        <Typography variant='h6' className={classes.description}>
                            <u>Human Objectives</u>
                        </Typography>
                        <ul>
                            {detail.humanGoals.split('\n').map((goalLine, index) => 
                                {
                                    if (goalLine.startsWith("\\t\\t")) {
                                        return (
                                            <ul key={index}>
                                                <ul>
                                                    <li className={classes.description}><Typography>{goalLine.replace(/^\W+/, "").substring(3)}</Typography></li>
                                                </ul>
                                            </ul>
                                        )
                                    } else if (goalLine.startsWith("\\t")) {
                                        return (
                                            <ul key={index}>
                                                <li className={classes.description}><Typography>{goalLine.replace(/^\W+/, "").substring(1)}</Typography></li>
                                            </ul>
                                        )
                                    } else {
                                        return (
                                            <li className={classes.description} key={index}><Typography>{goalLine.replace(/^\W+/, "")}</Typography></li>
                                        )
                                    }
                                }
                            )}
                        </ul>
                    </>}
                    {!!detail.zombieGoals && <>
                        <Typography variant='h6' className={classes.description}>
                            <u>Zombie Objectives</u>
                        </Typography>
                        <ul>
                            {detail.zombieGoals.split('\n').map((goalLine, index) => 
                                {
                                    if (goalLine.startsWith("\\t")) {
                                        return (
                                            <ul key={index}>
                                                <li className={classes.description}><Typography>{goalLine.replace(/^\W+/, "").substring(1)}</Typography></li>
                                            </ul>
                                        )
                                    } else {
                                        return (
                                            <li className={classes.description} key={index}><Typography>{goalLine.replace(/^\W+/, "")}</Typography></li>
                                        )
                                    }
                                }
                            )}
                        </ul>
                    </>}
                    {!!detail.winConditions && <>
                        <Typography variant='h6' className={classes.description}>
                            <u>Win Conditions</u>
                        </Typography>
                        <ul>
                            {detail.winConditions.split('\n').map((condition, index) => 
                                {
                                    return (
                                        <li className={classes.description} key={index}><Typography>{condition.replace(/^\W+/, "")}</Typography></li>
                                    )
                                }
                            )}
                        </ul>
                    </>}
                </>}
                {(!!detail.humanSuperRewards || !!detail.humanRewards || !!detail.tieRewards || !!detail.zombieRewards || !!detail.zombieSuperRewards || (!!detail.winningPoints && !!detail.losingPoints) || (!!detail.winningPoints && eventType === "Quest")) && <>
                    <Typography variant='h5' className={classes.heading}>
                        Rewards
                    </Typography>
                    <ul>
                        {!!detail.winningPoints && !!detail.losingPoints && eventType === "Mission" && <>
                                <li className={classes.description}><Typography>Members of the winning team will recieve {detail.winningPoints} points apiece, and members of the losing team will receive {detail.losingPoints} points apiece.{!!detail.tyingPoints && detail.tyingPoints !== 0 ? " If there is a tie, both teams will receive " + detail.tyingPoints + " points." : ""}</Typography></li>
                        </>}
                        {!!detail.humanSuperRewards && eventType === "Mission" && <>
                                {detail.humanSuperRewards.split('\n').map((reward, index) => 
                                    <li className={classes.description} key={index}><Typography>If Humans super win, they get {reward.replace(/^\W+/, "")}</Typography></li>
                                )}
                        </>}
                        {!!detail.humanRewards && eventType === "Mission" && <>
                                {detail.humanRewards.split('\n').map((reward, index) => 
                                    <li className={classes.description} key={index}><Typography>If Humans win, they get {reward.replace(/^\W+/, "")}</Typography></li>
                                )}
                        </>}
                        {!!detail.tieRewards && eventType === "Mission" && <>
                                {detail.tieRewards.split('\n').map((reward, index) => 
                                    <li className={classes.description} key={index}><Typography>If the mission is a tie, {reward.replace(/^\W+/, "")}</Typography></li>
                                )}
                        </>}
                        {!!detail.zombieRewards && eventType === "Mission" && <>
                                {detail.zombieRewards.split('\n').map((reward, index) => 
                                    <li className={classes.description} key={index}><Typography>If Zombies win, they get {reward.replace(/^\W+/, "")}</Typography></li>
                                )}
                        </>}
                        {!!detail.zombieSuperRewards && eventType === "Mission" && <>
                                {detail.zombieSuperRewards.split('\n').map((reward, index) => 
                                    <li className={classes.description} key={index}><Typography>If Zombies super win, they get {reward.replace(/^\W+/, "")}</Typography></li>
                                )}
                        </>}
                        {!!detail.winningPoints && eventType === "Quest" && <>
                                {detail.humanRewards !== "" && <>
                                    <li className={classes.description}><Typography>If enough Humans succeed in the quest, then they get {detail.humanRewards}.</Typography></li>
                                </>}
                                <li className={classes.description}><Typography>Any Human that completes the quest will receive {detail.winningPoints} points.</Typography></li>
                        </>}
                    </ul>
                    <br/>
                </>}
                {!!detail.pointsJoke && <>
                    <Typography variant='body1' className={classes.description}>
                        And remember, the points don't matter, just like {detail.pointsJoke}.
                    </Typography>
                    <br/>
                </>}
                <Typography variant='h5' className={classes.heading}>
                    Brainz,
                    <br/>
                    HvZ Core
                </Typography>
                {isAdmin && <>
                    {!!detail.neededAdmins && <>
                        <Typography variant='h6' className={classes.description}>
                            <u>Needed Admins</u>
                        </Typography>
                        <ul>
                            {detail.neededAdmins.split('\n').map((admin, index) => 
                                {
                                    if (admin.startsWith("\\t")) {
                                        return (
                                            <ul key={index}>
                                                <li className={classes.description}><Typography>{admin.replace(/^\W+/, "").substring(1)}</Typography></li>
                                            </ul>
                                        )
                                    } else {
                                        return (
                                            <li className={classes.description} key={index}><Typography>{admin.replace(/^\W+/, "")}</Typography></li>
                                        )
                                    }
                                }
                            )}
                        </ul>
                    </>}
                    {!!detail.neededEquipment && <>
                        <Typography variant='h6' className={classes.description}>
                            <u>Needed Equipment</u>
                        </Typography>
                        <ul>
                            {detail.neededEquipment.split('\n').map((thing, index) => 
                                {
                                    if (thing.startsWith("\\t")) {
                                        return (
                                            <ul key={index}>
                                                <li className={classes.description}><Typography>{thing.replace(/^\W+/, "").substring(1)}</Typography></li>
                                            </ul>
                                        )
                                    } else {
                                        return (
                                            <li className={classes.description} key={index}><Typography>{thing.replace(/^\W+/, "")}</Typography></li>
                                        )
                                    }
                                }
                            )}
                        </ul>
                    </>}
                    {!!detail.adminInfo && <>
                        <Typography variant='h6' className={classes.description}>
                            <u>Admin Information</u>
                        </Typography>
                        <ul>
                            {detail.adminInfo.split('\n').map((info, index) => 
                                {
                                    if (info.startsWith("\\t")) {
                                        return (
                                            <ul key={index}>
                                                <li className={classes.description}><Typography>{info.replace(/^\W+/, "").substring(1)}</Typography></li>
                                            </ul>
                                        )
                                    } else if (info.startsWith("\\t\\t")) {
                                        return (
                                            <ul key={index}>
                                                <ul>
                                                    <li className={classes.description}><Typography>{info.replace(/^\W+/, "").substring(1)}</Typography></li>
                                                </ul>
                                            </ul>
                                        )
                                    } else {
                                        return (
                                            <li className={classes.description} key={index}><Typography>{info.replace(/^\W+/, "")}</Typography></li>
                                        )
                                    }
                                }
                            )}
                        </ul>
                    </>}
                    {!!detail.adminMapLink && <>
                        <Typography variant='h6' className={classes.description}>
                            <u>Admin Map</u>
                        </Typography>
                        <ul>
                            {!!detail.adminMapLink && <>
                                <li className={classes.description}><Typography>Everything Core knows for the mission can be found <a href={detail.adminMapLink}>here</a></Typography></li>
                            </>}
                        </ul>
                        {!!detail.adminMapLink && <>
                            <div className={classes.stupidity1}>
                                <div className={classes.stupidity2}>
                                    <iframe title="Admin Mission Map" src={detail.adminMapLink} width="100%" height="100%"></iframe>
                                </div>
                            </div>
                        </>}
                        <br/>
                    </>}
                    {!!detail.questMessage && <>
                        <Typography variant='h6' className={classes.description}>
                            <u>Event Reolved Attendance Message</u>
                        </Typography>
                        <br/>
                        <Typography variant='body1' className={classes.description}>XXX Human(s) {detail.questMessage}</Typography>
                    </>}
                </>}
            </div>
        }</>
    );

}

export default MissionCard;
