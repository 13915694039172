import React, { useState } from 'react';
import LoggedOutHome from './loggedOutHome';
import LoggedInHome from './loggedInHome';
import { useAuth } from '../auth';
import { useEffect } from 'react';


function Home(props) {
    const { isLoggedIn, loggedInFetch } = useAuth();
    const [homeData, setHomeData] = useState({});

    const updateHome = () => {
        loggedInFetch('/home', '/home')
        .then(resp => resp.json())
        .then(resp => {
            resp.recentTags.forEach(tag => {
                tag.timeTagged = new Date(tag.timeTagged)
            });
            resp.announcements.forEach(event => {
                event.startDate = new Date(event.startDate)
                event.endDate = new Date(event.endDate)
            })
            setHomeData(resp)
        })
    }

    useEffect(() => {
        updateHome()
    },
    //eslint-disable-next-line
    [isLoggedIn()])

    return (
        <div>
            {!isLoggedIn() ? 
                <LoggedOutHome data={homeData}/> :
                <LoggedInHome data={homeData} onUpdate={updateHome}/>
            }
        </div>
    );

}

export default Home;