import React from 'react';
import { Box, Card, Typography, Button, makeStyles } from '@material-ui/core';
import { format } from 'date-fns';
import { useAuth } from '../../../auth';
import { useToasts } from 'react-toast-notifications';
import { useContext } from 'react';
import { GameStatusContext } from '../../../reuseComponents/gameStatusContext';

const useStyles = makeStyles(theme => ({
    pannel: {
        width: '95%',
        marginTop: '10px',
        margin: 'auto',
        background: theme.palette.primary.main,
        textAlign: 'center',
        maxHeight: '650px',
        overflow: 'auto',
        paddingBottom: 5,
    },
    heading: {
        paddingTop: '5px',
    },
    setActive: {
        backgroundColor: theme.palette.warning.main,
        '&:hover':{
            backgroundColor: theme.palette.warning.dark,
        }
    },
    notification: {
        textAlign: 'center',
        width: '90%',
        margin: 'auto',
        background: 'white',
        marginTop: '15px',
        marginBottom: '15px',
        paddingBottom: 5,
    },
}))

function GamesList({ games, onSuccess }) {
    const classes = useStyles();
    const { adminFetch } = useAuth();
    const { addToast } = useToasts();
    const { updateGameStatus } = useContext(GameStatusContext);

    const changeCurrent = (id) => {
        return () => {
            adminFetch('/admin/games/set-new', '/home', {}, 'POST', {gameId: id})
            .then(resp => {
                if (resp.ok) {
                    updateGameStatus()
                    onSuccess()
                } else {
                    addToast('Failed to change current game.', {appearance: 'error', autoDismiss: true})
                }
            })
        }
    }

    return (
        <Box className={classes.pannel}>
            { !!(games.length > 0) &&
                games.map(function(game, index) {
                return <Card className={classes.notification} key={game.id}>
                    <Typography variant='body1' className={classes.heading}><strong>{game.name}</strong></Typography>
                    <Typography variant='body1' className={classes.content}>{format(game.startDate, 'MMMM d, yyyy')}</Typography>
                    <Typography variant='body1' className={classes.content}>{format(game.endDate, 'MMMM d, yyyy')}</Typography>
                    {game.isCurrentGame ?
                        <Button variant='contained' disabled>Active Game</Button> 
                        :
                        <Button variant='contained' onClick={changeCurrent(game.id)} className={classes.setActive}>Set as Active</Button>
                    }
                </Card>
            })}
            { !(games.length > 0) &&
                <Typography variant='body1' className={classes.content}>No games found, create one so the timer doesn't NaN on you</Typography>
            }
        </Box>
    )
}

export default GamesList