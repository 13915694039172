import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Grid } from '@material-ui/core'
import CacheList from './cacheComponents/cacheList';
import CreateCache from './cacheComponents/createCache';
import { useAuth } from '../../auth';
import { useEffect } from 'react';

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        textAlign: 'center',
    },
    title: {
        flexGrow: 1,
        color: 'white',
    },
}));

function CacheManagement(props) {
    const classes = useStyles();
    const { adminFetch } = useAuth();
    const [caches, setCaches] = useState([]);

    const refreshCaches = () => {
        adminFetch('/admin/caches', '/home')
        .then(resp => resp.json())
        .then(resp => {
            setCaches(resp.cacheList)
        })
    }

    //eslint-disable-next-line
    useEffect(() => refreshCaches(), [])

    return (
        <Grid container className={classes.root}>
            <Grid item xs={12} sm={12} md={6}>
                <Typography variant="h4" className={classes.title}>Create A Cache</Typography> 
                <CreateCache onCreate={refreshCaches} />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
                <Typography variant="h4" className={classes.title}>Active Caches</Typography>
                <CacheList caches={caches} />
            </Grid>
        </Grid>
    );
}

export default CacheManagement