import React, { useState, useEffect }  from 'react'
import { Box, Table, TableBody, TableCell, TableHead, TablePagination, TableRow } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles';
import { backendBaseURL } from '../../App';

const useStyles = makeStyles(theme => ({
    tableContainer: {
        background: theme.palette.primary.main,
        width: '70%',
        minWidth: '500px',
        margin: 'auto',
    },
    hederCell: {
        color: 'white',
        fontFamily: '"Roboto", sans-serif',
        textTransform: 'uppercase',
    },
    bodyRow: {
        background: 'white',
    },
    bodyCell: {
        color: 'black',
    }
}));

function Individual(props) {
    const classes = useStyles();
    const headerTitles = ['Name', 'Score', 'Hours Survived', 'Missions', 'Quests', 'Caches', 'Tags'];
    const [rows, setRows] = useState([])
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    useEffect(() => {
        fetch(new URL('/leaderboards/frontpage', backendBaseURL))
        .then(resp => resp.json())
        .then(resp => {
            setRows(resp.scoreboardProfiles);
            fetch(new URL('/leaderboards/individuals', backendBaseURL))
            .then(resp2 => resp2.json())
            .then(resp2 => {
                setRows(resp.scoreboardProfiles.concat(resp2.scoreboardProfiles));
            })
        })
    },
    //eslint-disable-next-line
    [])

    return (
        <Box className={classes.tableContainer}>
            <Table className={classes.table} size='medium'>
                <TableHead>
                    <TableRow>
                        { headerTitles.map(function(head, i) {
                            return <TableCell key={i} className={classes.hederCell}>{head}</TableCell> 
                        })}
                    </TableRow>
                </TableHead>
                <TableBody>
                    { rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map(function(r, index) {
                        return (
                            <TableRow className={classes.bodyRow} key={index}>
                                <TableCell className={classes.bodyCell}>{r.name}</TableCell>
                                <TableCell className={classes.bodyCell}>{r.display_points}</TableCell>
                                <TableCell className={classes.bodyCell}>{r.hoursAlive}</TableCell>
                                <TableCell className={classes.bodyCell}>{r.missionCount}</TableCell>
                                <TableCell className={classes.bodyCell}>{r.questCount}</TableCell>
                                <TableCell className={classes.bodyCell}>{r.cacheCount}</TableCell>
                                <TableCell className={classes.bodyCell}>{r.isAlive ? "N/A" : r.tagCount}</TableCell>
                            </TableRow>
                    )})}
                    { emptyRows > 0  && rowsPerPage < rows.length && (
                        <TableRow style={{ height: 53 * emptyRows }}>
                            <TableCell colSpan={6} />
                        </TableRow>
                    )}
                </TableBody>
            </Table>
            <TablePagination
                rowsPerPageOptions={[10, 25, 50]}
                component="div" count={rows.length}
                rowsPerPage={rowsPerPage} page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}/>
        </Box>
    );
}

export default Individual