import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';
import MissionCard from './cards/missionCard';

const useStyles = makeStyles(theme => ({
    pannel: {
        width: '95%',
        marginTop: '10px',
        margin: 'auto',
        background: theme.palette.primary.main,
        textAlign: 'center',
        overflow: 'auto',
        padding: '10px'
    },
    boxTitle: {
        color: 'white',
        padding: '10px',
    },
}));

function FutureMissions({ futureDetails }) {
    const classes = useStyles();
    return (
        <div>
        { futureDetails.map(function(futureDetail, index) {
            return <Box className={classes.pannel} key={index}>
                <MissionCard detail={futureDetail} />
            </Box>
        })}
        </div>
    );

}

export default FutureMissions;