import React, { useState, useEffect } from 'react';
import { makeStyles, Box, TextField, Button } from '@material-ui/core';
import { useAuth } from '../../../auth';
import { useToasts } from 'react-toast-notifications';

const useStyles = makeStyles(theme => ({
    pannel: {
        width: '95%',
        marginTop: '10px',
        margin: 'auto',
        background: theme.palette.primary.main,
        textAlign: 'center',
        maxHeight: '600px',
        overflow: 'auto'
    },
    input: {
        marginTop: '20px',
        marginBottom: '20px',
        margin: 'auto',
        width: '90%',
        maxWidth: 500,
        background: 'white'
    },
    create: {
        marginBottom: '20px',
    },
}));

function generateCode() {
    const chars = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789"
    let code = ''
    for (let i = 0; i < 8; i++) {
        code = code.concat(chars.substr(Math.floor(Math.random() * chars.length), 1))
    }
    return code
}

function CreateCache({ onCreate }) {
    const classes = useStyles();
    const { adminFetch } = useAuth();
    const [code, setCode] = useState('');
    const [location, setLocation] = useState('');
    const [points, setPoints] = useState('');
    const { addToast } = useToasts();

    const allFormsValid = () => {
        return !!code && !!location && (!!parseInt(points) || parseInt(points) === 0)
    }
    const [disableButton, setDisableButton] = useState(!allFormsValid())
    //eslint-disable-next-line
    useEffect(() => setDisableButton(!allFormsValid()), [allFormsValid()])

    const clearInputs = () => {
        setCode('')
        setLocation('')
        setPoints('')
    }

    const handleNumberChange = (value, setter) => {
        const REEE = /^-?[0-9\b]+$/
        if (value === '' || value === '-' || REEE.test(value)) {
            setter(value)
        }
    }

    const onSubmit = () => {
        adminFetch('/admin/caches/create', '/home', {}, 'POST', { code: code.toUpperCase(), points: parseInt(points), location })
        .then(resp => {
            if (resp.ok) {
                addToast('Cache added successfullly', {appearance: 'success', autoDismiss: true})
                clearInputs();
                onCreate();
            } else if (resp.status === 406) {
                addToast('Cache code already used', {appearance: 'error', autoDismiss: true})
            } else {
                addToast('Failed to add Cache', {appearance: 'error', autoDismiss: true})
            }
        })
    }

    return (
        <Box className={classes.pannel}>
            <TextField
                className={classes.input}
                variant="outlined"
                value={code}
                onChange={e => setCode(e.target.value)}
                inputProps={{ style: {textAlign: 'center', background: 'white', fontFamily: '"Roboto", sans-serif', textTransform: 'uppercase'} }}
                placeholder="Cache Code"/>
            <br />
            <Button variant='contained' onClick={() => setCode(generateCode())}>Generate Random Cache Code</Button>
            <br />
            <TextField
                className={classes.input}
                variant="outlined"
                multiline
                value={location}
                onChange={e => setLocation(e.target.value)}
                inputProps={{ style: { background: 'white', fontFamily: '"Roboto", sans-serif' } }}
                placeholder="Location Description"/>
            <br />
            <TextField
                className={classes.input}
                variant="outlined"
                value={points}
                onChange={e => handleNumberChange(e.target.value, setPoints)}
                inputProps={{ style: { background: 'white', fontFamily: '"Roboto", sans-serif' } }}
                placeholder="Point Value"/>
            <br />
            <Button variant='contained' disabled={disableButton} className={classes.create} onClick={onSubmit}>Create Cache</Button>
        </Box>
    )
}

export default CreateCache