import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import theme from './theme';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/core/styles';
import * as serviceWorker from './serviceWorker';
import { CookiesProvider } from 'react-cookie';
import { ToastProvider } from 'react-toast-notifications'
import "./index.css";
import { BrowserRouter as Router, } from 'react-router-dom';

ReactDOM.render(
    <CookiesProvider>
        <link rel="stylesheet" href="/index.css"></link>
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <ToastProvider placement="bottom-right" autoDismissTimeout={5000}>
                <Router>
                    <App />
                </Router>
            </ToastProvider>
        </ThemeProvider>
    </CookiesProvider>, 
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
