import React, { useState, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Grid, TextField, Button, Checkbox, FormControlLabel } from '@material-ui/core';
import { PlayerStatusContext } from '../../reuseComponents/playerStatusContext';


const useStyles = makeStyles(theme => ({
    textBlock: {
        color: 'white',
        textAlign: 'center',
        width: '80%',
        marginBottom: 10,
    },
    title: {
        color: 'white',
        textAlign: 'center',
        marginBottom: 20,
        maxWidth: '80%',
    },
    field: {
        background: '#FFFFFF',
        borderRadius: 5,
        width: '50%',
        minWidth: '300px',
        maxWidth: '350px',
        margin: 'auto',
        textAlign: 'center',
        marginBottom: '20px',
    },
    submit: {
        marginBottom: '25px',
        fontSize: '20px',
        width: '50%',
        maxWidth: 300,
    },
    box: {
        color: 'white',
    },
    aggrements: {
        marginBottom: 10,
    },
    contactCheck: {
        color: 'white',
        borderRadius: 5,
        width: '50%',
        minWidth: '300px',
        maxWidth: '350px',
        margin: 'auto',
        textAlign: 'center',
        marginBottom: '20px',
    },
}));
function PlayerSettings({ onSubmit }) {
    const { isAdmin } = useContext(PlayerStatusContext);
    const [customTagCode, setCustomTagCode] = useState('');
    const [OZ, setOZ] = useState(false);
    const [offCampus, setOffCampus] = useState(false);

    const onSubmitClick = () => {
        const data = {
            tagCode: customTagCode,
            wantsOz: OZ,
            livesOffCampus: offCampus,
        }
        onSubmit(data)
    }

    const classes = useStyles();
    return (
        <Grid container direction='column' justifyContent="center" alignItems="center">
            <Typography variant='h4' className={classes.title}>Just a couple more questions! The following are all optional. </Typography>
            {isAdmin &&
            <TextField
                className={classes.field}
                label="Custom Tag Code"
                value={customTagCode}
                id="customTagCode"
                onChange={e => setCustomTagCode(e.target.value)} 
                variant="filled"/>
            }
            {isAdmin && customTagCode.length > 14 && 
                <Typography className={classes.textBlock} variant='body1'>Your custom tag code is too long. You cannot have more than 14 characters. You currently have {customTagCode.length} characters.</Typography>
            }
            {isAdmin && customTagCode.includes(' ') && 
                <Typography className={classes.textBlock} variant='body1'>Tag codes cannot contain a space and your custom tag code currently contains a space. If you can't find it, check the beginning and end of your code.</Typography>
            }
            <FormControlLabel
                className={classes.contactCheck}
                control={<Checkbox
                    className={classes.box}
                    checked={OZ}
                    onChange={e => setOZ(e.target.checked)}
                    color="primary"/>}
                label="Would you like to be an OZ?"/>
            <FormControlLabel
                className={classes.contactCheck}
                control={<Checkbox
                    className={classes.box}
                    checked={offCampus}
                    onChange={e => setOffCampus(e.target.checked)}
                    color="primary"/>}
                label="Do you live off campus?"/>
                
            <Button
                className={classes.submit}
                variant='contained'
                color='secondary'
                disabled={customTagCode.length > 14 || customTagCode.includes(' ')}
                onClick={onSubmitClick}>
                Submit
            </Button>
        </Grid>
    );
}

export default PlayerSettings
