import React, { useContext } from 'react';
import { Typography, Box, Card} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { GameStatusContext } from '../../reuseComponents/gameStatusContext';

const useStyles = makeStyles(theme => ({
    title: {
        paddingTop: '20px',
        paddingBottom: '5px',
        textAlign: 'center',
        color: 'white',
    },
    stats: {
        paddingTop: '0px',
        paddingBottom: '5px',  
        textAlign: 'center',
        color: 'white',
    },
    profileContainer: {
        background: theme.palette.primary.main,
        width: '50%',
        minWidth: '400px',
        maxWidth: '500px',
        margin: 'auto',
        textAlign: 'center',
        paddingBottom: '20px'
    },
    squadContainer: {
        textAlign: 'center',
        width: '90%',
        marginLeft: 'auto',
        marginRight: 'auto',
        background: 'white',
    },
    squadName:{
        paddingTop: '10px',
        paddingBottom: '10px',
        paddingLeft: '10px',
        paddingRight: '10px',
    },
}));

function ViewProfile({ user }) {
    const classes = useStyles();
    const { gameStatus } = useContext(GameStatusContext)
    return (
        <div>
            <Box className={classes.profileContainer}>
                <Typography variant='h6' className={classes.title}>{user.displayName}</Typography>
                {!!user.tagCode && //check if the user is registered for the current game
                <>
                    <Typography variant='h3' className={classes.title}>TAG CODE: {user.tagCode}</Typography>

                    <Typography variant='h6' className={classes.stats}>Points this game: {user.points}</Typography>
                    <Typography variant='h6' className={classes.stats}>Points all time: {user.totalPoints}</Typography>
                
                    <Typography variant='h6' className={classes.title}>Current Squad</Typography>
                    <Card className={classes.squadContainer}>
                        {!user.squadName && <Typography variant='h5' className={classes.squadName}><strong>
                            {gameStatus === 'preGame' ? "No squad yet! Head over to the squads page to join a squad!" : "You are not in any squad."}
                        </strong></Typography>}
                        {!!user.squadName && <Typography variant='h4' className={classes.squadName}><strong>{user.squadName}</strong></Typography>}
                    </Card>
                </>
                }
                {!user.tagCode &&
                    <Typography variant='h6' className={classes.title}>You aren't currently registered for a game.</Typography>
                }
            </Box>
        </div>
    )
}

export default ViewProfile
