import React, { useState, useEffect, useContext } from 'react'
import { makeStyles, Box, Typography, TextField, Button, Grid, Card, IconButton } from '@material-ui/core'
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { DesktopDateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import DateFnsUtils from '@date-io/date-fns';
import { useToasts } from 'react-toast-notifications';
import { useAuth } from '../../../auth';
import { GameStatusContext } from '../../../reuseComponents/gameStatusContext';
import DeathMessageEntry from './deathMessageEntry';

const useStyles = makeStyles(theme => ({
    date: {
        marginTop: 15,
        marginBottom: 15, 
        marginLeft: 10,
        marginRight: 10,
        background: 'white',
    },
    notification: {
        width: '90%',
        background: theme.palette.secondary.main,
        marginTop: '10px',
        marginBottom: '10px',
        margin: 'auto',
    },
    pannel: {
        width: '95%',
        marginTop: '10px',
        margin: 'auto',
        background: theme.palette.primary.main,
        textAlign: 'center',
        maxHeight: '650px',
        overflow: 'auto',
    },
    input: {
        marginTop: '20px',
        marginBottom: '20px',
        margin: 'auto',
        width: '90%',
        maxWidth: 500,
        background: 'white',
    },
    button: {
        margin: '0px',
        padding: '0px',
    },
    label: {
        margin: 'auto',
        textAlign: 'center',
        color: 'white',
    },
    fieldRow: {
        width: '50%',
        margin: 'auto',
    }
}))

function EditGame({ game, onSuccess }) {
    const classes = useStyles();
    const { adminFetch } = useAuth();
    const [name, setGameName] = useState(game.name);
    const [stunTimer, setStunTimer] = useState(game.stunTimer);
    const [tagQuota, setQuota] = useState(game.tagQuota);
    const [ammoCap, setAmmoCap] = useState(game.ammoCap);
    const [startDate, handleStartDateChange] = useState(game.startDate);
    const [endDate, handleEndDateChange] = useState(game.endDate);
    const { addToast } = useToasts();
    const { updateGameStatus } = useContext(GameStatusContext);
    const [humanDeathMessages, setHumanDeathMessages] = useState([]);
    const [zombieDeathMessages, setZombieDeathMessages] = useState([]);
    const [humanDeathMessageToAdd, setHumanDeathMessageToAdd] = useState("");
    const [zombieDeathMessageToAdd, setZombieDeathMessageToAdd] = useState("");

    const allFormsValid = () => {
        return !!name && !!stunTimer && !!tagQuota && !!ammoCap
    }
    const [disableButton, setDisableButton] = useState(allFormsValid())
    
    const updateDeathMessages = () => {
        adminFetch('/admin/games/getDeathMessages', '/home', {})
        .then(resp => resp.json())
        .then(resp => {
            setHumanDeathMessages(resp.humanDeathMessages)
            setZombieDeathMessages(resp.zombieDeathMessages)
        })
    }
    
    useEffect(() => {
        setDisableButton(!allFormsValid())
        updateDeathMessages()
    },
    //eslint-disable-next-line
    [])

    const handleNumberChange = (value, setter) => {
        const numRe = /^[0-9\b]+$/;
        if (value === '' || numRe.test(value)) {
            setter(value)
        }
    }

    const handleAddDeathMessage = () => {
        if (humanDeathMessageToAdd !== "" || zombieDeathMessageToAdd !== "") {
            const message = (humanDeathMessageToAdd !== "") ? humanDeathMessageToAdd : zombieDeathMessageToAdd
            const isHuman = (humanDeathMessageToAdd !== "") ? true : false
            adminFetch('/admin/games/addDeathMessage', '/home', {}, 'POST', { 
                deathMessage: message,
                isHumanDeathMessage: isHuman,
            })
            .then(resp => resp.json())
            .then(resp => {
                if (!resp.errorMessage) {
                    addToast('\''.concat(message, '\' successfully added to ', isHuman ? 'Human' : 'Zombie', ' death message pool.'), {appearance: 'success', autoDismiss: true})
                    setHumanDeathMessageToAdd("")
                    setZombieDeathMessageToAdd("")
                    updateDeathMessages()
                } else {
                    addToast(resp.errorMessage, {appearance: 'error', autoDismiss: true})
                }
            })
        }
    }

    const onSubmit = () => {
        adminFetch('/admin/games/update', '/home', {}, 'POST',
            {gameUpdate: {
                name,
                stunTimer: parseInt(stunTimer),
                tagQuota: parseInt(tagQuota),
                ammoCap: parseInt(ammoCap),
                startDate: startDate.getTime(),
                endDate: endDate.getTime()
            }})
            .then(resp => resp.json())
            .then(resp => {
            if (!resp.errorMessage) {
                addToast('Game updated successfully.', {appearance: 'success', autoDismiss: true})
                updateGameStatus();
                onSuccess();
            } else {
                addToast(resp.errorMessage, {appearance: 'error', autoDismiss: true})
            }
        })
    }

    return (
        <div>
            <Box className={classes.pannel}>
                <Grid container className={classes.fieldRow}>
                    <Grid item xs={12} md={2} className={classes.label}>
                        <Typography>Game Name:</Typography>
                    </Grid>
                    <Grid item xs={12} md={10}>
                        <TextField className={classes.input}
                            variant="outlined"
                            required
                            error={!name}
                            value={name}
                            onChange={e => setGameName(e.target.value)}
                            inputProps={{ style: { background: 'white', fontFamily: '"Roboto", sans-serif' } }}
                            placeholder="Name"/>
                    </Grid>
                </Grid>
                <Grid container className={classes.fieldRow}>
                    <Grid item xs={12} md={2} className={classes.label}>
                        <Typography>Stun Timer</Typography>
                    </Grid>
                    <Grid item xs={12} md={2} className={classes.label}>
                        <TextField className={classes.input}
                            variant="outlined"
                            required
                            error={!stunTimer}
                            value={stunTimer}
                            onChange={e => handleNumberChange(e.target.value, setStunTimer)}
                            inputProps={{ style: { background: 'white', fontFamily: '"Roboto", sans-serif' } }}
                            placeholder="Stun Timer"/>
                    </Grid>
                    <Grid item xs={12} md={2} className={classes.label}>
                        <Typography>Tag Quota</Typography>
                    </Grid>
                    <Grid item xs={12} md={2} className={classes.label}>
                        <TextField className={classes.input}
                            variant="outlined"
                            required
                            error={!tagQuota}
                            value={tagQuota}
                            onChange={e => handleNumberChange(e.target.value, setQuota)}
                            inputProps={{ style: { background: 'white', fontFamily: '"Roboto", sans-serif' } }}
                            placeholder="Tag Quota"/>
                    </Grid>
                    <Grid item xs={12} md={2} className={classes.label}>
                        <Typography>Ammo Cap</Typography>
                    </Grid>
                    <Grid item xs={12} md={2} className={classes.label}>
                        <TextField className={classes.input}
                            variant="outlined"
                            required
                            error={!ammoCap}
                            value={ammoCap}
                            onChange={e => handleNumberChange(e.target.value, setAmmoCap)}
                            inputProps={{ style: { background: 'white', fontFamily: '"Roboto", sans-serif' } }}
                            placeholder="Ammo Cap"/>
                    </Grid>
                </Grid>
                <br />
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DesktopDateTimePicker label="Start Date and Time" value={startDate} className={classes.date} onChange={(newValue) => {handleStartDateChange(newValue);}} renderInput={(params) => <TextField {...params} />}/>
                        <DesktopDateTimePicker label="End Date and Time" value={endDate} className={classes.date} onChange={(newValue) => {handleEndDateChange(newValue);}} renderInput={(params) => <TextField {...params} />}/>
                    </LocalizationProvider>
                </MuiPickersUtilsProvider>
                <br />
                <Button variant="contained" disabled={disableButton} onClick={onSubmit}>Submit</Button>
            </Box>
            <br />
            {!!humanDeathMessages && !!zombieDeathMessages &&
                <Box className={classes.pannel}>
                    <Grid container>
                        <Grid item xs={12} lg={6}>
                            <Typography variant='h6' className={classes.label}>Human Death Messages</Typography>
                            <Card className={classes.notification}>
                                <div>
                                    <Grid container alignItems='center'>
                                        <Grid item xs={11}>
                                            <TextField className={classes.input}
                                                variant="outlined"
                                                value={humanDeathMessageToAdd}
                                                onChange={e => setHumanDeathMessageToAdd(e.target.value)}
                                                inputProps={{ style: { background: 'white', fontFamily: '"Roboto", sans-serif' } }}
                                                placeholder="[name] died! How sad."/>
                                        </Grid>
                                        <Grid item xs={1}>
                                            <IconButton className={classes.button} onClick={handleAddDeathMessage}>
                                                <AddCircleIcon />
                                            </IconButton>
                                        </Grid>
                                    </Grid>
                                </div>
                            </Card>
                            { humanDeathMessages.map(function(msg, index) {
                                return ( 
                                    <Box key={index}>
                                        <DeathMessageEntry message={msg} updateDeathMsgs={updateDeathMessages}/>
                                    </Box>
                                )
                            })}
                        </Grid>
                        <Grid item xs={12} lg={6}>
                            <Typography variant='h6' className={classes.label}>Zombie Death Messages</Typography>
                            <Card className={classes.notification}>
                                <div>
                                    <Grid container alignItems='center'>
                                        <Grid item xs={11}>
                                            <TextField className={classes.input}
                                                variant="outlined"
                                                value={zombieDeathMessageToAdd}
                                                onChange={e => setZombieDeathMessageToAdd(e.target.value)}
                                                inputProps={{ style: { background: 'white', fontFamily: '"Roboto", sans-serif' } }}
                                                placeholder="[name] joined the team. Huzzah!"/>
                                        </Grid>
                                        <Grid item xs={1}>
                                            <IconButton className={classes.button} onClick={handleAddDeathMessage}>
                                                <AddCircleIcon />
                                            </IconButton>
                                        </Grid>
                                    </Grid>
                                </div>
                            </Card>
                            { zombieDeathMessages.map(function(msg, index) {
                                return ( 
                                    <Box key={index}>
                                        <DeathMessageEntry message={msg} updateDeathMsgs={updateDeathMessages}/>
                                    </Box>
                                )
                            })}
                        </Grid>
                    </Grid>
                </Box>
            }
        </div>
    )
}

export default EditGame