import React, { useState, useEffect, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { GameStatusContext } from './gameStatusContext';

const useStyles = makeStyles(theme => ({
  box: {
    background: theme.palette.primary.main,
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  timer: {
    paddingRight: '5px',
    color: 'red',
    fontSize: '24px',
    display: 'inline-block',
    marginLeft: theme.spacing(2)
  },
  description: {
    paddingLeft: '5px',
    color: 'white',
    fontSize: '24px',
    display: 'inline-block',
  },
  endDescription: {
    paddingLeft: '5px',
    paddingRight: '5px',
    color: 'white',
    fontSize: '24px',
    display: 'inline-block',
  },
}));

function calculateCountdown(endDate) {
  let diff = Math.floor((endDate - new Date()) / 1000);
  const timeLeft = {
    days: 0,
    hours: 0,
    min: 0,
    sec: 0
  };
  if (diff <= 0) return timeLeft;

  // calculate time difference between now and expected date
  if (diff >= 86400) { 
    timeLeft.days = Math.floor(diff / 86400);
    diff -= timeLeft.days * 86400;
  }
  if (diff >= 3600) {
    timeLeft.hours = Math.floor(diff / 3600);
    diff -= timeLeft.hours * 3600;
  }
  if (diff >= 60) {
    timeLeft.min = Math.floor(diff / 60);
    diff -= timeLeft.min * 60;
  }
  timeLeft.sec = diff;
  return timeLeft;
}

function addLeadingZeros(value) {
  value = String(value);
  while (value.length < 2) {
    value = '0' + value;
  }
  return value;
}

function countdownOver(countdown) {
  return countdown.days <= 0 && countdown.hours <= 0 && countdown.min <= 0 && countdown.sec <= 0
}

function Countdown() {
  const { gameStartTime, gameEndTime, gameStatus, updateGameStatus } = useContext(GameStatusContext);
  
  const classes = useStyles();
  const [countdown, setCountdown] = useState({
    days: 0,
    hours: 0,
    min: 0,
    sec: 0
  });

  useEffect(() => {
    if (gameStatus !== 'postGame') {
      const interval = setInterval(() => {
        const newCountdown = calculateCountdown(gameStatus === 'preGame' ? gameStartTime : gameEndTime)
        setCountdown(newCountdown);
        if (countdownOver(newCountdown)) {
          updateGameStatus(gameStatus === 'preGame' ? 'midGame' : 'postGame')
        }
      }, 1000);
      return () => clearInterval(interval);
    }
  // eslint-disable-next-line
  }, [gameStatus, gameEndTime, gameStartTime]);

  if (gameStatus === 'postGame') {
    return <Box className={classes.box}>
             <Typography className={classes.endDescription}>Thanks for playing!</Typography>
           </Box>
  }

  return(
    <Box className={classes.box}>
      <Typography className={classes.description}>{gameStatus === 'preGame' ? 'Game start:' : 'Game end:'}</Typography>
      <Typography className={classes.timer}>{addLeadingZeros(countdown.days)}:{addLeadingZeros(countdown.hours)}:{addLeadingZeros(countdown.min)}:{addLeadingZeros(countdown.sec)}</Typography>
    </Box>
  );
}

export default Countdown;