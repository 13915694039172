import React from "react";
import Button from '@material-ui/core/Button';
import { withAuth } from "../../auth";

function LoginButton(props) {
    return (
        <Button
            variant="contained"
            color="secondary"
            onClick={() => props.auth.forceLogin('home')}>Let's Go</Button>
    )
}

export default withAuth(LoginButton);