import React, { useState, useEffect } from 'react';
import { Typography, IconButton} from '@material-ui/core';
import { Edit, Cancel } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import ViewProfile from './profileComponents/viewProfile';
import EditProfile from './profileComponents/editProfile';
import { useAuth } from '../auth'


const useStyles = makeStyles(theme => ({
    pageTitle: {
        paddingTop: '15px',
        paddingBottom: '15px',
        textAlign: 'center',
        color: 'white',
        minWidth:'320px',
        marginLeft: '45px',
    },
}));

function Profile(props) {
    const defaultUser = {
        id: '',
        firstNameLegal: '',
        firseNamePreferred: '',
        lastName: '',
        nickname: '',
        pronouns: '',
        emergencyContactName: '',
        emergencyContactNumber: '',
        emergencyContactRelationship: '',
        hzAffiliation: '',
        tagCode: '',
        totalPoints: 0,
        points: 0,
        squadName: '',
        squadMembers: [],
    }
    const [user, setUser] = useState(defaultUser);
    const [editing, setEditing] = useState(false);
    const { loggedInFetch } = useAuth();

    const getProfileInfo = () => {
        loggedInFetch('/profile', 'profile')
        .then(resp => resp.json())
        .then(resp => {
            setUser(resp);
            setEditing(false);
        })
    }

    useEffect(getProfileInfo,
    //eslint-disable-next-line
    [])

    const handleClick = (event) => {
        setEditing(!editing);
    };

    const updateUser = newUser => {
        loggedInFetch('/profile/update', 'profile', {}, 'POST', {userInfo: newUser})
        .then(() => {
            getProfileInfo()
        })
    }

    const classes = useStyles();
    return (
        <div>
            <Typography variant='h2' className={classes.pageTitle}>
                {editing? 'Edit Profile' : 'My Profile'}
                <IconButton onClick={handleClick}>
                    {editing ? <Cancel style={{fontSize: 35, color: 'red', marginLeft: '10px'}}/> : <Edit style={{fontSize: 35, color: 'white', MarginLeft: '10px'}}/>}
                </IconButton>
            </Typography>

            {editing ? <EditProfile user={user} onSubmit={updateUser} /> : <ViewProfile user={user} />}
        </div>
    )
}

export default Profile;