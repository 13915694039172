import React, { useContext } from 'react';
import { makeStyles, Box, Card, Grid, Typography, Button } from '@material-ui/core';
import { useAuth } from '../../auth';
import { PlayerStatusContext } from '../../reuseComponents/playerStatusContext';
import { GameStatusContext } from '../../reuseComponents/gameStatusContext';
import { useToasts } from 'react-toast-notifications';

const useStyles = makeStyles(theme => ({
    root: {
        background: theme.palette.primary.main,
        width: '70%',
        margin: 'auto',
        textAlign: 'center',
        marginTop: 15,
        paddingTop: 1,
        paddingBottom: 1,
    },
    notification: {
        textAlign: 'center',
        width: '95%',
        margin: 'auto',
        background: 'white',
        marginTop: '15px',
        marginBottom: '15px',
        paddingBottom: 5,
    },
    title: {
        textAlign: 'center',
        color: 'white',
    },
    button: {
        paddingTop: 5,
    },
    item: {
        width: '50%',
    },
}))

function SquadList({ squads, currentSquadId, onUpdate }) {
    const classes = useStyles();
    const { loggedInFetch } = useAuth();
    const { isRegistered } = useContext(PlayerStatusContext);
    const { gameStatus } = useContext(GameStatusContext)
    const { addToast } = useToasts();

    const handleJoin = squad => {
        return () => {
            loggedInFetch('/squads/join', '/squads', {}, 'POST', { squadId: squad.id })
            .then(resp => {
                if (resp.ok) {
                    onUpdate();
                    addToast('Successfully joined ' + squad.name + '!', {appearance: 'success', autoDismiss: true})
                } else {
                    addToast('Unable to join squad ' + squad.name + '!', {appearance: 'error', autoDismiss: true})
                }
            })
        }
    }

    const handleLeave = squad => {
        return () => {
            loggedInFetch('/squads/leave', '/squads', {}, 'POST')
            .then(resp => {
                if (resp.ok) {
                    onUpdate();
                    addToast('Successfully left ' + squad.name + '.', {appearance: 'success', autoDismiss: true})
                } else {
                    addToast('Unable to leave ' + squad.name + '!', {appearance: 'error', autoDismiss: true})
                }
            })
        }
    }

    if (squads.length === 0) {
        return <Typography variant='h4' className={classes.title}>No squads currently exist</Typography>
    }

    return (
        <Box className={classes.root}>
            {squads.map((squad, i) => {
                return <Card className={classes.notification} key={i}>
                    <Grid container direction='row' justify="space-around" alignItems="center">
                        <Grid item className={classes.item}>
                            <Typography variant='h6'>{squad.name}</Typography>
                        </Grid>
                        <Grid item className={classes.item}>
                            <Grid container direction='column' spacing={1}>
                                {squad.memberNames.map((name, j) => {
                                    return <Grid item key={j}>{name}</Grid>
                                })}
                            </Grid>
                        </Grid>
                        {isRegistered && gameStatus === 'preGame' &&
                            <Grid item className={classes.button}>
                                {squad.id === currentSquadId ? 
                                    <Button variant='contained' color="secondary" onClick={handleLeave(squad)}>Leave</Button>
                                    :
                                    <Button variant='contained' color="secondary" disabled={!!currentSquadId} onClick={handleJoin(squad)}>Join</Button>
                                }
                            </Grid>
                        }
                    </Grid>
                </Card>
            })}
        </Box>
    )
}

export default SquadList