import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import EmailBox from './emailBox';
import PlayerCard from '../reuseComponents/personCard';
import { backendBaseURL } from '../App';

const useStyles = makeStyles(theme => ({
    container: {
        textAlign: 'center',
    },
    title: {
        paddingBottom: '10px',
        color: 'white',
    },
    midTitle: {
        paddingTop: '20px',
        paddingBottom: '10px',
        color: 'white',
    },
}));

function ContactAdmins(props) {
    const classes = useStyles();
    const [coreMembers, setCoreMembers] = useState([])

    useEffect(() => {
        fetch(new URL('/contact-admins', backendBaseURL))
        .then(resp => resp.json())
        .then(resp => setCoreMembers(resp.adminsList))
    },
    //eslint-disable-next-line
    [])

    return (
        <div className={classes.container}>
            <Typography variant='h4' className={classes.title}>Contact Admins</Typography>
            <EmailBox />
            <Typography variant='h4' className={classes.midTitle}>Core Members</Typography>
            <Grid container className={classes.coreContainer}>
                { coreMembers.map(function(member, index) {
                    return (
                        <Grid item key={index} xs={12} sm={6} md={4} lg={3}>
                            <PlayerCard person={member}/>
                        </Grid>
                )})}
            </Grid>
        </div>
    );
}

export default ContactAdmins