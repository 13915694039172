import React, { useState, useEffect } from 'react';
import UserList from './userComponents/userList';
import OZList from './userComponents/ozList';
import { useAuth } from '../../auth';
import { Button, makeStyles, TextField, Table, TableBody, TableRow, TableCell, Box } from '@material-ui/core';
import { useToasts } from 'react-toast-notifications';

const useStyles = makeStyles(theme => ({
    tableContainer: {
        background: theme.palette.primary.main,
        width: '40%',
        minWidth: '200px',
        margin: 'auto',
    },
    container: {
        paddingTop: '10px',
        textAlign: 'center',
    },
    button: {
        margin: '20px',
    },
    input: {
        marginTop: '25px',
        margin: 'auto',
        width: '90%',
        maxWidth: 500,
    },
}))
function UserManagement(props) {
    const classes = useStyles();
    const { adminFetch } = useAuth();
    const [users, setUsers] = useState([]);
    const [ozs, setOZs] = useState([]);
    const [confirmedOZCount, setConfirmedOZCount] = useState(0);
    const [squads, setSquads] = useState([]);
    const { addToast } = useToasts();
    const [nameQuery, setNameQuery] = useState("");
    const [caseIdQuery, setCaseIdQuery] = useState("");
    const ozsChosen = users.some(user => user.affiliation === 'OZ');

    const updateUsers = () => {
        adminFetch('/admin/players', '/home')
        .then(resp => resp.json())
        .then(resp => {
            setUsers(resp.playerList)
            setSquads(resp.squadList)
        })
    }

    useEffect(() => {
        updateUsers()
    },
    //eslint-disable-next-line
    [])

    const onWaiverButton = () => {
        adminFetch('/admin/players/waiver-alert', '/home', {}, 'POST')
        .then(resp => {
            if (resp.ok) {
                addToast("Waiver reminders scheduled.", {appearance: 'success', autoDismiss: true})
            } else {
                addToast("Unable to send waiver reminders.", {appearance: 'error', autoDismiss: true})
            }
        })
    }

    const onRulesButton = () => {
        adminFetch('/admin/players/rules-alert', '/home', {}, 'POST')
        .then(resp => {
            if (resp.ok) {
                addToast("Rules meeting reminders scheduled.", {appearance: 'success', autoDismiss: true})
            } else {
                addToast("Unable to send rules meeting reminders.", {appearance: 'error', autoDismiss: true})
            }
        })
    }
    const handleAddOZ = user => {
        if (ozs.findIndex(oz => oz.playerId === user.playerId) === -1) {
            let list = [...ozs];
            list.push({
                "name": user.name,
                "playerId": user.playerId,
                "confirmed": false
            });
            setOZs(list);
            list = [...users];
            list[list.findIndex(u => u.playerId === user.playerId)].isOz = true;
            setUsers(list);
        }
    }

    const handleRemoveOZ = user => {
        let index = ozs.findIndex(oz => oz.playerId === user.playerId);
        if (index !== -1) {
            let list = [...ozs];
            list.splice(index, 1);
            setOZs(list);
            list = [...users];
            list[list.findIndex(u => u.playerId === user.playerId)].isOz = false;
            setUsers(list);
        }
    }

    const handleCheckedChange = (playerId, status) => {
        let list = [...ozs];
        list[list.findIndex(oz => oz.playerId === playerId)].confirmed = status;
        setOZs(list);
        setConfirmedOZCount(confirmedOZCount + (status ? 1 : -1));
    }
    return (
        <div className={classes.container}>
            <Button variant="contained" onClick={onWaiverButton} color="primary" className={classes.button}>Send Waiver Reminders</Button>
            <Button variant="contained" onClick={onRulesButton} color="primary" className={classes.button}>Send Rules Meeting Reminders</Button>
                <Box className={classes.tableContainer}>
                    <Table className={classes.table}>
                        <TableBody>
                            <TableRow>
                                <TableCell align="center">
                                    <TextField id="outlined-search" type="search" className={classes.input}
                                    variant="outlined"
                                    value={nameQuery}
                                    onChange={e => setNameQuery(e.target.value)}
                                    inputProps={{ style: {textAlign: 'center', background: 'white', fontFamily: '"Roboto", sans-serif'} }}
                                    placeholder="Enter Name"/>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell align="center">
                                    <TextField id="outlined-search" type="search" className={classes.input}
                                        variant="outlined"
                                        value={caseIdQuery}
                                        onChange={e => setCaseIdQuery(e.target.value)}
                                        inputProps={{ style: {textAlign: 'center', background: 'white', fontFamily: '"Roboto", sans-serif'} }}
                                        placeholder="Enter Case ID"/>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </Box>
            {!!Object.keys(ozs).length && !ozsChosen && 
                <OZList proposedOZs={ozs} confirmedOZCount={confirmedOZCount} removeOZ={handleRemoveOZ} onConfirmUpdate={handleCheckedChange} onUpdate={updateUsers} />
            }
            <UserList users={users} squads={squads} onUpdate={updateUsers} addOZ={handleAddOZ} removeOZ={handleRemoveOZ} nameSearch={nameQuery.toLowerCase()} caseIdSearch={caseIdQuery.toLowerCase()}/>
        </div>
    );
}

export default UserManagement