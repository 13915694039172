import React, { useState, useEffect, useContext } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Grid } from '@material-ui/core/';
import CurrentMission from './pannels/currentMission';
import PastMissions from './pannels/pastMissions';
import FutureMissions from './pannels/futureMissions';
import { backendBaseURL } from '../App';
import { useAuth } from '../auth';
import { PlayerStatusContext } from '../reuseComponents/playerStatusContext';

const useStyles = makeStyles(theme => ({
    title: {
        textAlign: 'center',
        color: 'white',
    },
    grid: {
        width: '95%',
        margin: 'auto',
    },
    pannel: {
        width: '95%',
        marginTop: '10px',
        background: theme.palette.secondary.main,
        overflow: 'auto',
        padding: '10px'
    },
    boxTitle: {
        color: 'white',
        padding: '10px',
        textAlign: 'center',
        width: '100%'
    }
}));

function Missions(props) {
    const { loggedInFetch } = useAuth();
    const { isAdmin } = useContext(PlayerStatusContext);
    const classes = useStyles();
    const [summaries, setSummaries] = useState([])
    const [detail, setDetail] = useState(null)
    const [futureDetails, setFutureDetails] = useState(null)

    useEffect(() => {
        loggedInFetch('/missions/list', backendBaseURL)
        .then(resp => resp.json())
        .then(resp => {
            setSummaries(resp.pastEventsBasic.map(event => {
                event.startDate = new Date(event.startDate)
                event.endDate = new Date(event.endDate)
                return event;
            }))
            if (resp.upcomingEventFull && resp.upcomingEventFull.published) {
                resp.upcomingEventFull.startDate = new Date(resp.upcomingEventFull.startDate)
                resp.upcomingEventFull.endDate = new Date(resp.upcomingEventFull.endDate)
                setDetail(resp.upcomingEventFull)
            }
            if (resp.futureEventsDetailed) {
                setFutureDetails(resp.futureEventsDetailed.map(event => {
                    event.startDate = new Date(event.startDate)
                    event.endDate = new Date(event.endDate)
                    return event;
                }))
            }
        })
    },
    //eslint-disable-next-line
    [])

    return (
        <div>
            <Typography variant='h2' className={classes.title}>Events</Typography>
                <Grid container className={classes.grid}>
                    { !!detail &&
                        <Grid item xs={12} sm={12} md={8}>
                            <CurrentMission detail={detail} />
                        </Grid>
                    }
                    <Grid item xs={12} sm={12} md={4}>
                        <PastMissions summaries={summaries} />
                    </Grid>
                    { !!futureDetails && isAdmin &&
                        <Grid className={classes.pannel} item xs={12} sm={12} md={8}>
                            <Typography variant='h6' className={classes.boxTitle}>Future Events</Typography>
                            <FutureMissions futureDetails={futureDetails} />
                        </Grid>
                    }

                </Grid>
        </div>
    );
}

export default Missions;
