import React, { useLayoutEffect, useState } from "react";
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import { Typography, Button, IconButton, Toolbar, AppBar, Menu, MenuItem } from '@material-ui/core/';
import MenuIcon from '@material-ui/icons/Menu';

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
    },
    bar: {
        backgroundColor: theme.palette.secondary.main,
    },
    title: {
        flexGrow: 1,
        color: 'white',
        fontSize: '24px',
    },
}));

function useWindowSize() {
    const [size, setSize] = useState(0);
    useLayoutEffect(() => {
      function updateSize() {
        setSize(window.innerWidth);
      }
      window.addEventListener('resize', updateSize);
      updateSize();
      return () => window.removeEventListener('resize', updateSize);
    }, []);
    return size;
}

function AdminBar(props) {
    const classes = useStyles();
    const width = useWindowSize();

    const [anchorEl, setAnchorEl] = React.useState(null);
    const handleOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = (event) => {
        setAnchorEl(null);
    };
    
    return (
        <div className={classes.root}>
            <AppBar position="static" elevation={0} className={classes.bar}>
                <Toolbar>
                <Typography className={classes.title}>Admin Pages</Typography>
                { width >= 700 && 
                    <div>
                        <Button component={Link} to="/admin/game" color="inherit">Game</Button>
                        <Button component={Link} to="/admin/users" color="inherit">Players</Button>
                        <Button component={Link} to="/admin/missions" href="rules" color="inherit">Events</Button>
                        <Button component={Link} to="/admin/caches" color="inherit">Caches</Button>
                    </div>
                }
                { width < 700 &&
                    <div>
                        <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu" onClick={handleOpen}>
                            <MenuIcon />
                        </IconButton>
                        <Menu
                            id="simple-menu"
                            anchorEl={anchorEl}
                            keepMounted
                            open={Boolean(anchorEl)}
                            onClose={handleClose}>
                            <MenuItem onClick={handleClose} component={Link} to="/admin/game">Game</MenuItem>
                            <MenuItem onClick={handleClose} component={Link} to="/admin/users">Players</MenuItem>
                            <MenuItem onClick={handleClose} component={Link} to='/admin/missions'>Events</MenuItem>
                            <MenuItem onClick={handleClose} component={Link} to="/admin/caches">Caches</MenuItem>
                        </Menu>
                    </div>
                }
                </Toolbar>
            </AppBar>
        </div>
    );
}

export default AdminBar;