import React, { useState, useContext } from 'react';
import { Typography, TextField, Button, makeStyles } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { DesktopDateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import DateFnsUtils from '@date-io/date-fns';
import { useAuth } from '../../../auth';
import { useToasts } from 'react-toast-notifications';
import { GameStatusContext } from '../../../reuseComponents/gameStatusContext';

function getModalStyle() {
    return {
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
    };
}

const useStyles = makeStyles(theme => ({
    paper: {
        position: 'absolute',
        width: 400,
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
    input: {
        marginTop: '20px',
        marginBottom: '20px',
        margin: 'auto',
        width: '90%',
        maxWidth: 500,
        background: 'white'
    },
    date: {
        marginTop: 15,
        marginBottom: 15, 
        marginLeft: 10,
        marginRight: 10,       
    },
}));

function CreateNewGame({ callback }) {
    const classes = useStyles();
    const { adminFetch } = useAuth();
    const [modalStyle] = useState(getModalStyle);
    const [name, setNewGameName] = useState('');
    const [startDate, handleStartDateChange] = useState(new Date());
    const [endDate, handleEndDateChange] = useState(new Date());
    const { addToast } = useToasts();
    const { updateGameStatus } = useContext(GameStatusContext);

    const handleClose = () => {
        callback();
    }

    const onSubmit = () => {
        adminFetch('/admin/games/new', '/home', {}, 'POST', {
            name,
            startDate: startDate.getTime(),
            endDate: endDate.getTime()
        })
        .then(resp => {
            if (resp.ok) {
                updateGameStatus();
                handleClose();
            } else {
                addToast("Failed to create new game.", {appearance: 'error', autoDismiss: true})
            }
        })
    }

    return (
        <div style={modalStyle} className={classes.paper}>  
            <Typography variant="h4">Create A New Game</Typography>
            <TextField className={classes.input}
            variant="outlined"
            multiline
            value={name}
            onChange={e => setNewGameName(e.target.value)}
            inputProps={{ style: { background: 'white', fontFamily: '"Roboto", sans-serif' } }}
            placeholder="Game Name"/>
            <br />
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DesktopDateTimePicker label="Start Date and Time" value={startDate} className={classes.date} onChange={(newValue) => {handleStartDateChange(newValue);}} renderInput={(params) => <TextField {...params} />}/>
                    <DesktopDateTimePicker label="End Date and Time" value={endDate} className={classes.date} onChange={(newValue) => {handleEndDateChange(newValue);}} renderInput={(params) => <TextField {...params} />}/>
                </LocalizationProvider>
            </MuiPickersUtilsProvider>
            <br />
            <Button variant="contained" color="primary" onClick={onSubmit}>Create</Button>
        </div>
    )
}

export default CreateNewGame;