import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import RulesPresentation from "./rulesPresentation";

const useStyles = makeStyles(theme => ({
    container:{
        textAlign: 'center',
    },
    exitButton: {
        margin: '25px'
    },
    rulesPresentation: {
        marginBottom: '25px'
    }
}));

function BylawsPage() {
    const classes = useStyles();
    return (
        <div className={classes.container}> 
            <RulesPresentation className={classes.rulesPresentation}></RulesPresentation>
        </div>
    );

}

export default BylawsPage;