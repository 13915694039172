import React, { useState } from 'react'
import { Typography, Box, TextField, Button} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, DateTimePicker } from '@material-ui/pickers';
import { useAuth } from '../../auth';
import { useToasts } from 'react-toast-notifications'

const useStyles = makeStyles(theme => ({
    title: {
        paddingTop: '20px',
        textAlign: 'center',
        color: 'white',
    },
    inputContainer: {
        background: theme.palette.primary.main,
        width: '50%',
        minWidth: '500px',
        margin: 'auto',
        textAlign: 'center'
    },
    input: {
        marginTop: '25px',
        margin: 'auto',
        width: '90%',
        maxWidth: 500,
    },
    submit: {
        marginTop: '25px',
        marginBottom: '25px',
        fontSize: '20px',
        width: '50%',
        maxWidth: 300,
    }
}));
function TagReport(props) {
    const classes = useStyles();
    const [selectedDate, setDate] = useState(new Date());
    const [tagCode, setCode] = useState('');
    const { addToast } = useToasts();
    const { loggedInFetch } = useAuth();

    const onSubmit = () => {
        loggedInFetch('/tags/report', 'report', {}, 'POST', {tagCode: tagCode, timeTagged: selectedDate.getTime()})
        .then(resp => {
            if (resp.status === 200) {
                addToast('Tag Recorded Successfully', { appearance: 'success', autoDismiss: 'true'})
                setCode('')
            } else {
                addToast('Unable to Record Tag', { appearance: 'error', autoDismiss: 'true'})
            }
        })
    }

    return (
        <MuiPickersUtilsProvider  utils={DateFnsUtils}>
            <Typography variant='h2' className={classes.title}>Report a Tag</Typography>
            <Box className={classes.inputContainer}>
                <TextField className={classes.input}
                    variant="outlined"
                    onChange={e => setCode(e.target.value)}
                    value={tagCode}
                    inputProps={{ style: {textAlign: 'center', background: 'white', fontFamily: '"Roboto", sans-serif', textTransform: 'uppercase'} }}
                    placeholder="Enter Tag Code"/>
                <br />
                <DateTimePicker
                    label="Time of tag"
                    inputVariant="outlined"
                    value={selectedDate}
                    onChange={setDate}
                    disableFuture
                    margin="normal"
                    color='secondary'
                />
                <br />
                <Button 
                    className={classes.submit}
                    variant='contained'
                    color='secondary'
                    onClick={onSubmit}>
                    Submit
                </Button>
            </Box>
        </MuiPickersUtilsProvider>
    );
}

export default TagReport