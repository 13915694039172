import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import Container from "@material-ui/core/Container";

const useStyles = makeStyles(theme => ({
    container: {
        textAlign:'center',
        paddingTop: '10px'
    },
    bylaws: {
        height: '600px',
        width: '100%',
  },
}));

function Bylaws() {
    const classes = useStyles();
    return (
        <Container className={classes.container}>
            <iframe className={classes.bylaws} title="HvZ Bylaws" src="https://docs.google.com/document/d/e/2PACX-1vTwEaCAbN2ghYKbjbu55hH7rI-EnRy4zEFD2C4l5FLt9lA20vcFhbwWAuHwxbyrklSF4xxgO7JUs04x/pub?embedded=true"></iframe>
        </Container>
    );

}

export default Bylaws;
