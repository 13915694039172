import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useContext } from 'react';
import { PlayerStatusContext } from '../reuseComponents/playerStatusContext';
import EditProfile from './profileComponents/editProfile';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { makeStyles, Typography, Box, TextField } from '@material-ui/core';
import { useEffect } from 'react';
import { useProfileCreate, cookieName } from '../auth';
import { useToasts } from 'react-toast-notifications';
import { useCookies } from 'react-cookie';

const useStyles = makeStyles(theme => ({
    container: {
        textAlign: 'center',
    },
    panel: {
        background: theme.palette.primary.main,
        width: '50%',
        margin: 'auto',
        marginTop: 20,
        paddingTop: 15,
    },
    field: {
        background: '#FFFFFF',
        borderRadius: 5,
        width: '50%',
        minWidth: '300px',
        maxWidth: '350px',
        margin: 'auto',
        textAlign: 'center',
        marginBottom: '20px',
    },
    text: {
        color: 'white',
    }
}))

const today = new Date()

function CreateProfile() {
    const classes = useStyles();
    const history = useHistory();
    const context = useContext(PlayerStatusContext);
    const [savedCookie] = useState(context.newUserCookie);
    const [birthday, setBirthday] = useState(today);
    const { addToast } = useToasts();
    const createProfile = useProfileCreate(savedCookie);
    const [, setCookie] = useCookies();

    useEffect(() => {
        context.setNewUserCookie('');
        if (!savedCookie) {
            history.push('/home')
        }
    },
    //eslint-disable-next-line
    [])

    const onSubmit = partial => {
        partial.birthDate = birthday
        createProfile(partial)
        .then(resp => {
            if (resp.ok) {
                setCookie(cookieName, savedCookie, {path: '/'})
                history.push('/register')
            } else if (resp.status === 406) {
                addToast("Something went wrong, please check that your phone number and emergency contact number are correct and not the same number, then try again", {appearance: 'error', autoDismiss: true})
            } else {
                addToast("Something went wrong, please try again", {appearance: 'error', autoDismiss: true})
            }
        })
    }

    function isBirthdateValid(date) {
        return date !== null && date.getTime() < new Date(today.getFullYear() - 14, today.getMonth(), today.getDate())
    }

    return (
        <div className={classes.container}>
            <Typography variant='h2' className={classes.text}>Welcome!</Typography>
            <Typography variant='h5' className={classes.text}>
                This page will set up your profile.
                You need to complete this setup before you can use the rest of the website.
                For picking your Birthdate (if on mobile) you can click on the year and change the year so that you don't need to put those cookie clicker skills to use.
            </Typography>
            <Box className={classes.panel}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DesktopDatePicker label="Birthdate" value={birthday} inputVariant="outlined" className={classes.field} error={!isBirthdateValid(birthday)} onChange={(newValue) => {setBirthday(newValue);}} renderInput={(params) => <TextField {...params} />}/>
                    </LocalizationProvider>
                </MuiPickersUtilsProvider>
                <EditProfile user={{}} onSubmit={onSubmit} enableSubmit={() => isBirthdateValid(birthday)} />
            </Box>
        </div>
    )
}

export default CreateProfile
